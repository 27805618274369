<div class="table-top-section">
	<div class="header-container" *ngIf="firstOption && secondOption">
		<div class="title-container">
			<div
				*ngIf="icon && icon !== ''"
				class="icon-container"
				[ngClass]="{ bank: icon === 'bank-figma', card: icon === 'card' }"
			>
				<app-icon class="icon" name="{{ icon }}" [lineWidth]="'1.5px'" [size]="'25'"></app-icon>
			</div>
			<span class="title">{{ title }}</span>
		</div>

		<app-toggle-button
			*ngIf="showButton"
			[startWith]="kpi === secondOption ? 1 : 0"
			[leftOptionText]="firstOption"
			[rightOptionText]="secondOption"
			(toggled)="showCustomerTable($event)"
		></app-toggle-button>
	</div>
</div>
