<div *ngIf="!isComponentLoading && selectedPayCycle">
	<div id="container-dates" fxLayout="row">
		<div class="years-transactions" fxFlex="15" fxLayout="row" fxLayoutAlign="center center">
			<span class="year">{{ selectedYear.getFullYear() }}</span>
			<app-icon
				*ngIf="payCycleYearPageable.hasPreviousYear"
				(click)="previousYear()"
				id="previousYearButton"
				class="cursor-point"
				[name]="'arrow-left-1'"
				[color]="'var(--color-mat-primary)'"
				[size]="'15'"
				[lineWidth]="'3.5px'"
			></app-icon>
			<app-icon
				*ngIf="payCycleYearPageable.hasNextYear"
				(click)="nextYear()"
				id="nextYearButton"
				class="cursor-point"
				[name]="'arrow-right-1'"
				[color]="'var(--color-mat-primary)'"
				[size]="'15'"
				[lineWidth]="'3.5px'"
			></app-icon>
		</div>
		<div class="month-transactions" fxLayout="row" fxLayoutAlign="center center">
			<app-icon
				*ngIf="hasPreviousPayCycle()"
				(click)="previousPayCylce()"
				id="previousMonthButton"
				class="cursor-point"
				[name]="'arrow-left-1'"
				[color]="'var(--color-mat-primary)'"
				[size]="'15'"
				[lineWidth]="'3.5px'"
			></app-icon>
			<span
				class="month"
				style="box-sizing: border-box; padding-left: 20px; padding-right: 20px"
				*ngIf="selectedPayCycle"
			>
				{{ selectedPayCycle!.name }}
			</span>
			<app-icon
				*ngIf="hasNextPayCycle()"
				(click)="nextPayCycle()"
				class="cursor-point"
				id="nextMonthButton"
				[name]="'arrow-right-1'"
				[color]="'var(--color-mat-primary)'"
				[size]="'15'"
				[lineWidth]="'3.5px'"
			></app-icon>
		</div>
	</div>
	<div id="contanier-transaction" class="contanier-transaction">
		<ng-container *ngIf="payouts; else noPayouts">
			<ng-container *ngIf="payouts.length > 0; else noPayouts">
				<div *ngFor="let payout of payouts">
					<app-pay-elective-transaction-list [payout]="payout"></app-pay-elective-transaction-list>
				</div>
			</ng-container>
		</ng-container>

		<ng-template #noPayouts>
			<no-items-layout message="No payouts have been uploaded."></no-items-layout>
		</ng-template>
	</div>
</div>
<div *ngIf="!isComponentLoading && noPayCyclesForPayGroup" id="contanier-transaction" class="contanier-transaction">
	<div>
		<no-items-layout message="This Pay Group is missing a calendar."> </no-items-layout>
	</div>
</div>
