<div class="info-container" [formGroup]="form">
	<div class="statuses-and-buttons">
		<div class="status-container">
			<div class="status-section" [ngClass]="{ divider: errorTotal() > 0 }">
				<span class="header body-small-bold">Pay Electives Status</span>
				<div class="status-counts">
					<div class="status">
						<span class="dot active"> </span>
						<div class="information-subtitle-text body-xsmall-normal" id="active">
							({{ showActiveCounts() }})
						</div>
					</div>
					<div class="status">
						<span class="dot failed"> </span>
						<div class="information-subtitle-text body-xsmall-normal" id="failed">
							({{ showFailedCounts() }})
						</div>
					</div>
					<div class="status">
						<span class="dot validating"> </span>
						<div class="information-subtitle-text body-xsmall-normal" id="failed">
							({{ showValidatingCounts() }})
						</div>
					</div>
				</div>
			</div>

			<div class="serviceProviderErrors" *ngIf="errorTotal() > 0 && providersErrors.entries()">
				<span class="header body-small-bold">Service Provider Errors</span>
				<div class="provider-counts">
					<div *ngIf="providersErrors" class="status">
						<div *ngFor="let provider of providersErrors.entries(); let i = index">
							<span
								class="information-subtitle-text provider-name body-xsmall-normal"
								id="{{ provider[0] + '_provider_error' }}"
								*ngIf="!(provider[0] | lowercase).includes(selectedProvider | lowercase)"
								>{{ provider[0] }}
								<span class="error-number-span">({{ provider[1].errorCount }})</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="button-container">
			<button class="master-button" id="goToMaster" mat-button (click)="goToFileMangement()">
				<span class="uploadText">
					<app-icon
						[name]="'eye'"
						[color]="'var(--color-upload-button-dark-grey)'"
						[lineWidth]="'2px'"
					></app-icon>
					<span class="text-spacing body-small-bold">MASTER FILE</span>
				</span>
			</button>

			<button *ngIf="canUploadEmployeesFiles" class="upload-btn" mat-button (click)="uploader.click()">
				<span class="uploadText">
					<app-icon
						class="add-master-file"
						[name]="'document-upload'"
						[color]="'var(--color-white)'"
						[lineWidth]="'2px'"
					></app-icon>
					<span class="text-spacing body-small-bold upload-label-color">UPLOAD MASTER FILE</span>
				</span>
			</button>
			<input
				id="payElectiveUpload"
				hidden
				type="file"
				#uploader
				(change)="uploadPayElectiveFile(uploader.files)"
				(click)="uploader.value = ''"
			/>
		</div>
	</div>
	<div class="search-and-status-section">
		<mat-form-field class="search-field-PE" id="master-search-field" appearance="outline">
			<app-icon
				id="PE-search-icon"
				[name]="'search-normal-1'"
				[size]="'25'"
				[lineWidth]="'2px'"
				[color]="'var(--color-search-icon)'"
				matPrefix
			></app-icon>
			<input
				id="searchEmployees"
				matInput
				placeholder="Search by employee"
				class="body-normal"
				formControlName="search"
			/>
		</mat-form-field>

		<input-wrapper id="status" class="filter-wrapper status-wrapper">
			<input-select
				class="filter-input"
				formControlName="status"
				[label]="'Status'"
				[options]="statuses"
				[value]="selectedStatus.value"
			>
			</input-select>
		</input-wrapper>

		<!-- providers -->
		<input-wrapper id="providers" *ngIf="providers$ | async as providers" class="filter-wrapper">
			<input-select
				*ngIf="providers.length > 0"
				formControlName="provider"
				[label]="'Providers'"
				[options]="providers"
				[value]="seletectedProviderValue"
				class="filter-input"
			>
			</input-select>
		</input-wrapper>
	</div>
	<div class="employee-data-section">
		<span class="employeeTotal xs-title">
			<button
				id="refreshButton"
				class="refresh-button"
				(click)="refreshEmployeeListAndStatuses()"
				mat-icon-button
			>
				<app-icon
					id="refresh-icon"
					[name]="'rotate-right'"
					[color]="'var(--color-disabled)'"
					[lineWidth]="'2px'"
					[size]="'20'"
				></app-icon>
			</button>
			Employee list ({{ _totalEmployees }})</span
		>

		<div class="filter-and-reprocessed-button-container">
			<button
				class="filter-applied-button"
				(click)="clearfilters()"
				*ngIf="isFiltersApplied() || filtersAppliedFromStatusDropDown"
			>
				<span class="body-small-bold"
					>Filters applied
					<app-icon [name]="'add'" [size]="'20px'" [lineWidth]="'2px'" [color]="'black'"></app-icon>
				</span>
			</button>

			<button class="filter-accounts-button" mat-button>
				<app-icon
					[name]="'arrow-3'"
					[size]="'25'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filter()"
					id="filter-accounts-icon"
				></app-icon>
			</button>

			<ng-template
				[appUserRolePermissions]
				[action]="'show'"
				[roles]="[
					'SUPER_ADMIN',
					'TS_IMPLEMENTATION_CONSULTANT',
					'TS_IMPLEMENTATION_LEAD',
					'TS_OPERATION_CONSULTANT',
					'TS_OPERATION_LEAD',
					'TS_OCB'
				]"
			>
				<button
					id="reProcess"
					[disabled]="disableReprocessButton()"
					mat-button
					class="reprocessed-button c-button"
					color="blue"
					(click)="reProcessAll()"
				>
					<span class="body-small-bold custom-color-font">REPROCESS All</span>
				</button>
			</ng-template>
		</div>
	</div>

	<div class="filter-options-wrapper" (mouseleave)="closeFilter()">
		<div class="filter-options-dropdown" *ngIf="displayFilter">
			<div class="filter-header body-small-bold">
				<span>FILTER BY</span>
			</div>

			<span class="filter-option">
				<span class="filter-text"
					><app-icon
						class="badge-item badge-no-card-filter"
						[lineWidth]="'1.5'"
						[size]="'18'"
						[name]="'card'"
					></app-icon>
					Missing cards
				</span>

				<app-icon
					*ngIf="showMissingCard"
					id="filter-eye"
					[name]="'eye'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filterBy('hide missingCard')"
				></app-icon>

				<app-icon
					*ngIf="!showMissingCard"
					id="filter-eye"
					[name]="'eye-slash'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filterBy('show missingCard')"
				></app-icon>
			</span>

			<span class="filter-option">
				<span class="filter-text"
					><app-icon
						class="badge-item badge-card-filter"
						[lineWidth]="'1.5'"
						[size]="'18'"
						[name]="'card'"
					></app-icon>
					Uploaded Cards</span
				>
				<app-icon
					*ngIf="showUploadedCard"
					id="filter-eye"
					[name]="'eye'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filterBy('hide uploadedCard')"
				></app-icon>

				<app-icon
					*ngIf="!showUploadedCard"
					id="filter-eye"
					[name]="'eye-slash'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filterBy('show uploadedCard')"
				></app-icon>
			</span>

			<span class="filter-option">
				<span class="filter-text"
					><app-icon
						class="badge-item badge-no-bank-filter"
						[name]="'bank-figma'"
						[lineWidth]="'1.5'"
						[size]="'18'"
					></app-icon>
					Missing Bank Acc.
				</span>
				<app-icon
					*ngIf="showMissingBank"
					id="filter-eye"
					[name]="'eye'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filterBy('hide missingBank')"
				></app-icon>

				<app-icon
					*ngIf="!showMissingBank"
					id="filter-eye"
					[name]="'eye-slash'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filterBy('show missingBank')"
				></app-icon>
			</span>

			<span class="filter-option">
				<span class="filter-text"
					><app-icon
						class="badge-item badge-bank-filter"
						[name]="'bank-figma'"
						[lineWidth]="'1.5'"
						[size]="'18'"
					></app-icon>
					Uploaded Bank Acc.
				</span>
				<app-icon
					*ngIf="showUploadedBank"
					id="filter-eye"
					[name]="'eye'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filterBy('hide uploadedBank')"
				></app-icon>

				<app-icon
					*ngIf="!showUploadedBank"
					id="filter-eye"
					[name]="'eye-slash'"
					[lineWidth]="'1.5px'"
					[color]="'var(--color-filter-button)'"
					(click)="filterBy('show uploadedBank')"
				></app-icon>
			</span>
		</div>
	</div>
</div>
