<div class="header">
	<h3 class="dialog-text" *ngIf="errorType === 'PAY_ELECTIVES'" fxFlex="95">MASTER DATA ERRORS</h3>
	<h3 class="dialog-text" *ngIf="errorType === 'G2N' || errorType === 'Payroll integration'" fxFlex="95">
		G2N DATA ERRORS
	</h3>
	<h3 class="dialog-text" *ngIf="errorType === 'TPP'" fxFlex="95">TPP DATA ERRORS</h3>
	<app-icon
		id="master-close-icon"
		[name]="'close'"
		[size]="'70px'"
		[color]="'white'"
		[lineWidth]="'0.8px'"
		fxFlex="5"
		(click)="closeDialog()"
	></app-icon>
</div>
<div class="history-container">
	<app-file-detail *ngIf="errorType === 'PAY_ELECTIVES'" [taskId]="taskId"></app-file-detail>

	<app-file-errors *ngIf="errorType === 'G2N'" [taskId]="taskId" [payGroup]="payGroup"></app-file-errors>

	<app-file-errors
		*ngIf="errorType === 'Payroll integration' && integrationErrorMessages"
		[integrationErrorMessages]="integrationErrorMessages"
		[taskId]="taskId"
		[errorType]="errorType"
		[payGroup]="payGroup"
	></app-file-errors>

	<app-file-errors
		*ngIf="errorType === 'TPP'"
		[taskId]="taskId"
		[errorType]="errorType"
		[payGroup]="payGroup"
	></app-file-errors>
</div>
