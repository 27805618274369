<div *ngIf="_employee" fxLayout="column" class="pe-container" fxLayoutAlign="center">
	<div class="rank-update-notification-bar" *ngIf="rankUpdateNotificationVisible">
		<span class="rank-update-notification-text" fxFlex="90">
			You've changed the bank account rank. The "value type" field needs to be modified.
		</span>

		<app-icon
			[name]="'close'"
			[size]="'45px'"
			[color]="'#67000A'"
			fxFlex="10"
			(click)="rankUpdateNotificationVisible = false"
		></app-icon>
	</div>

	<app-pay-elective-create-bank-account
		*ngIf="creatingBankAccount"
		[country]="country"
		[bankAccountDetails]="bankAccountDetails"
		[newBankAccount]="newBankAccount"
		[selectedEmployee]="_employee"
		[selectedPayGroupId]="payGroup.id"
		[payMethodsLength]="payMethodsLength"
		[providerName]="provider"
		[paymentMethods]="activePaymentMethodsNET"
		[supportedPayMethodTypes]="payGroup.supportedPayMethodTypes"
	></app-pay-elective-create-bank-account>

	<div
		*ngIf="{
			activeNet: activePaymentMethodsNET | async,
			activeTPP: activePaymentMethodsTPP | async,
			inactive: inactivePaymentMethods | async,
			activeNone: activePaymentMethodsNone | async
		} as data"
	>
		<!-- If there bank accounts -->
		<ng-container
			*ngIf="
				data.activeNet?.length || data.activeTPP?.length || data.inactive?.length || data.activeNone?.length;
				else noPaymentMethods
			"
		>
			<div class="bank-account-list-container" *ngIf="!creatingBankAccount">
				<div class="top-buttons-container" fxLayout="row">
					<div fxLayout="row" fxFlex="60" fxLayoutGap="10px">
						<span class="net-accounts-header-text">
							Active accounts
							<span class="net-accounts-header-sub-text"> ({{ providerText }}) </span>
						</span>

						<div *ngIf="data.inactive?.length">
							<mat-slide-toggle
								class="toggle-default"
								[ngClass]="{ 'toggle-checked': showInactive }"
								(change)="toggle($event)"
								color="primary"
								[checked]="showInactive"
							>
								<div fxLayout="column">
									<span class="toggle-text">Show Inactive<br />accounts</span>
								</div></mat-slide-toggle
							>
						</div>
					</div>

					<button
						*ngIf="canCreateBankAccount"
						id="addBankAccount"
						class="add-button"
						(click)="toggleCreatingBankAccount()"
					>
						<app-icon
							class="add-button-icon"
							[name]="'add'"
							[lineWidth]="'2px'"
							[color]="'var(--color-primary-1000)'"
						></app-icon>
						Add new bank account
					</button>
				</div>

				<div fxLayout="column" class="bank-account-list-header-container" *ngIf="data.activeNet?.length">
					<span class="bank-account-list-header-text"> NETS </span>
					<app-pay-elective-payment-methods-list
						[paymentType]="'Nets'"
						[paymentMethodsNet]="activePaymentMethodsNET"
						[employeeId]="_employee.id!"
						[payGroupId]="_employee.payGroupId"
						[providerName]="provider"
						[supportedPayMethodTypes]="payGroup.supportedPayMethodTypes"
						(toggleCreatingBankAccountEvent$)="toggleCreatingBankAccount()"
						(editBankAccountEvent$)="editBankAccount($event)"
					></app-pay-elective-payment-methods-list>
				</div>

				<div
					fxLayout="column"
					class="bank-account-list-header-container"
					*ngIf="data.activeTPP?.length || data.activeNone?.length"
				>
					<span class="bank-account-list-header-text"> OTHERS </span>
					<app-pay-elective-payment-methods-list
						[paymentType]="'TPPs'"
						[paymentMethodsTPP]="activePaymentMethodsTPP"
						[paymentMethodNoType]="activePaymentMethodsNone"
						(editBankAccountEvent$)="editBankAccount($event)"
						[providerName]="provider"
						[supportedPayMethodTypes]="payGroup.supportedPayMethodTypes"
					>
					</app-pay-elective-payment-methods-list>
				</div>

				<div *ngIf="showInactive" class="show-inactive-accounts">
					<div class="top-buttons-container" *ngIf="showInactive">
						<span class="net-accounts-header-text" fxFlex="20"> Inactive Accounts </span>
					</div>
					<app-pay-elective-inactive-payment-methods-list
						*ngIf="showInactive"
						[providerName]="provider"
						[inactivePaymentMethods]="inactivePaymentMethods"
						(editBankAccountEvent$)="editBankAccount($event)"
					>
					</app-pay-elective-inactive-payment-methods-list>
				</div>
			</div>
		</ng-container>

		<ng-template #noPaymentMethods>
			<no-items-layout
				*ngIf="!creatingBankAccount"
				message="No records yet"
				buttonText="Add new bank account"
				(onClickButton)="toggleCreatingBankAccount()"
				[disabled]="!canCreateBankAccount"
			>
			</no-items-layout>
		</ng-template>

		<!-- If there are not bank accounts -->
	</div>
</div>
