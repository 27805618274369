import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MONTH_NAMES } from "src/app/shared/constants/months";
import {
	Milestone,
	MilestoneListWithPagination,
	MilestoneUpdateDTO,
	PayCycle
} from "src/app/shared/models/pay-cycle.interface";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class MilestonesService {
	constructor(private http: HttpClient) {}

	updateMilestoneObservable(): Observable<any> {
		return this.milestoneUpdated$.asObservable();
	}

	private milestoneUpdated$ = new Subject<boolean>();
	tempMonthName: Record<string, string>[] = MONTH_NAMES;

	createMilestones(milestones: MilestoneUpdateDTO): Observable<any> {
		return this.http.post<PayCycle>(`${environment.apiUri}/v1/calendars/milestones`, milestones).pipe(
			map(res => {
				return res;
			})
		);
	}

	updateMilestones(milestones: MilestoneUpdateDTO): Observable<any> {
		return this.http.put<PayCycle>(`${environment.apiUri}/v1/calendars/milestones`, milestones).pipe(
			map(res => {
				this.milestoneUpdated$.next(true);
				return res;
			})
		);
	}

	getMilestones(payCycleid: string): Observable<Milestone[]> {
		return this.getUnorderedMilestones(payCycleid).pipe(
			map((data: any) => {
				return data.items.sort(
					(a: Milestone, b: Milestone) => new Date(a.date).getTime() - new Date(b.date).getTime()
				);
			})
		);
	}

	getUnorderedMilestones(payCycleid = "", payGroupId = ""): Observable<MilestoneListWithPagination> {
		return this.http
			.get<any>(
				environment.apiUri +
					`/v1/calendars/milestones?pageSize=100&pageNumber=0&payCycleId=${payCycleid}&payGroupId=${payGroupId}`
			)
			.pipe(shareReplay(1));
	}

	getOverlappingMilestones(payCycleId: string): Observable<string[]> {
		return this.getUnorderedMilestones(payCycleId).pipe(
			map((data: any) => {
				const x = data.items;

				let overlappingMilestoneMonths: number[] = [];
				let overlappingMonthNames: string[] = [];

				if (x.length > 0) {
					x.forEach((x: Milestone) => {
						let month = new Date(x.date);
						overlappingMilestoneMonths.push(month.getMonth());
					});

					overlappingMonthNames = overlappingMilestoneMonths.map(x => {
						return this.tempMonthName[x].full;
					});
				}

				return overlappingMonthNames;
			})
		);
	}

	getMilestone(milestoneId: string): Observable<Milestone> {
		return this.http.get<Milestone>(`${environment.apiUri}/v1/calendars/milestones/${milestoneId}`);
	}

	/*getOverlappingMilestones(milestones$: Observable<Milestone[]>): Observable<string[]> {
		return milestones$.pipe(
			map((data: any) => {
				const x = data.items;
				let overlappingMilestoneMonths: number[] = [];
				let overlappingMonthNames: string[] = [];

				if (x.length > 0) {
					x.forEach((x: Milestone) => {
						let month = new Date(x.date);
						overlappingMilestoneMonths.push(month.getMonth());
					});

					overlappingMonthNames = overlappingMilestoneMonths.map(x => {
						return this.tempMonthName[x].full;
					});
				}

				return overlappingMonthNames;
			})
		);
	}*/

	/*getOverlappingMilestones(payCycleId: string): Observable<string[]> {
		return this.http
			.get<any>(
				environment.apiUri + "/v1/calendars/milestones?pageSize=100&pageNumber=0&payCycleId=" + payCycleId
			)
			.pipe(
				map(data => {
					const x = data.items;
					let overlappingMilestoneMonths: number[] = [];
					let overlappingMonthNames: string[] = [];

					if (x.length > 0) {
						x.forEach((x: Milestone) => {
							let month = new Date(x.date);
							overlappingMilestoneMonths.push(month.getMonth());
						});

						overlappingMonthNames = overlappingMilestoneMonths.map(x => {
							return this.tempMonthName[x].full;
						});
					}

					return overlappingMonthNames;
				})
			);
	}*/
}
