import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClientSummaryComponent } from "./container/client-summary.component";
import { SharedModule } from "@shared/shared.module";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { ClientSummaryRoutingModule } from "./client-summary-routing.module";
import { CountryComponent } from "./_components/country/country.component";

@NgModule({
	declarations: [ClientSummaryComponent, CountryComponent],
	imports: [CommonModule, SharedModule, BreadcrumbsModule, LeafletModule, ClientSummaryRoutingModule]
})
export class ClientSummaryModule {}
