import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { SetStatusAction } from "@store/actions/statusNetPayment.action";
import { getGlobalDashboardFilterState, selectStatusNetPayment } from "@store/index";
import { AppState } from "@store/models/state.model";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Location } from "@angular/common";

import { ModalLauncherService } from "@shared/components/wpay-ui/modal/modal-launcher.service";
import {
	cancelBookingDialog,
	providerMessage
} from "@shared/components/wpay-ui/modal/templates/cancel-booking/model/cancel-booking.interface";
import { PayoutsService } from "@shared/services/payouts/payouts.service";
import { Pagination } from "src/app/shared/models/pagination.interface";
import { Milestone, PayCycle } from "src/app/shared/models/pay-cycle.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { MilestoneReport } from "src/app/shared/models/payouts.types";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { InactivateToggleDialogComponent } from "../../../_components/payouts/payout-list-item/inactivate-toggle-dialog/inactivate-toggle-dialog.component";
import { TppPaymentsApiService } from "../../../services/tpp-payments-api.service";
import { Booking, TppPayouts, cancelReport } from "../../models/tpp-payment.interface";
import { ToastService } from "@shared/services/toast/toast.service";

@Component({
	selector: "app-tpp-payments",
	templateUrl: "./tpp-payments.component.html",
	styleUrls: ["./tpp-payments.component.scss"]
})
export class TppPaymentsComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild("filters", { static: false }) filtersComponent!: ElementRef;
	@ViewChild("payoutContainer") payoutContainer!: ElementRef;

	pageSize: number = 10000; //temp
	pageNumber: number = 0;
	totalPages: number = 0;
	totalCount: number = 0;

	//State variabled
	payGroup!: PayGroup;
	payCycle!: PayCycle;
	payCycleId: string = "";
	milestone!: Milestone;
	dataInputId!: string;

	status: string = "";

	milestoneReport!: MilestoneReport;

	pagination!: Pagination;

	bookings!: Booking[];

	reprocessPayoutIdsList: string[] = [];

	bookingHasBeeenProcessed: boolean = true;

	statusFilter: string[] = [];

	isCancelledRun: boolean = false;

	bookingButtonState: string = "inactive";

	payouts: TppPayouts[] = [];

	//Top card info
	selectedKPI: string = "";
	payCycleName = "";
	updating = true;
	title = "Booking date / ";
	subtitle = "";
	milestoneGroup = "NETS";
	flag = "GP";

	//Cancelation variables
	runSelection!: SelectOption[];
	cancelReport: cancelReport[] = [];
	cancelId: string = "reset";
	runName: string = "";
	cancelDate!: Date;
	cancellerName: string = "";
	reason: string = "";

	setFiltersToFixed = false;
	emitScroll: number = 0;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private tppPaymentsApi: TppPaymentsApiService,
		private dialog: MatDialog,
		private modalLauncherService: ModalLauncherService,
		private tppPaymentsApiService: TppPaymentsApiService,
		private payoutService: PayoutsService,
		private menuService: MenuService,
		private store: Store<AppState>,
		private toast: ToastService,
		private readonly _location: Location
	) {}

	ngOnInit(): void {
		this.menuService.setVisible(false);
		if (this.router.url === "/global-dashboard/tpp-payments") this.getSelectedKPI();
		else this.setHistory();
	}

	ngAfterViewInit(): void {
		this.attachScrollListener();
	}

	attachScrollListener(): void {
		const scrollContainer = this.payoutContainer?.nativeElement;
		if (scrollContainer) {
			scrollContainer.addEventListener("scroll", this.onScroll.bind(this));
		}
	}

	onScroll(event: Event): void {
		const scrollContainer = event.target as HTMLElement;
		this.emitScroll = scrollContainer.scrollTop;
	}

	setHistory(): void {
		if (
			history.state?.payCycle &&
			history.state?.payGroup &&
			history.state?.milestone &&
			this.hasDataInputMilestone(history?.state?.milestones)
		) {
			this.payGroup = history.state.payGroup;
			this.payCycle = history.state.payCycle;
			this.milestone = history.state.milestone;
			this.payCycleId = history.state.payCycle.id;
			this.payCycleName = history.state.payCycle.name;

			this.getMilestoneReport(this.milestone?.id ? this.milestone.id : "");

			if (this.payGroup?.data && this.payGroup?.legalEntity?.data && this.milestone?.group) {
				this.setupTopCardInfo();
			}

			this.setupSubscriptions();
		} else {
			this._location.historyGo(-1);
			this.toast.showError("Something went wrong while trying to load the page. Please try again later.");
		}
	}

	hasDataInputMilestone(milestones: Milestone[]): boolean {
		let dataInputMilestone = milestones?.find((milestone: Milestone) => milestone.type === "DATA_INPUT");

		if (dataInputMilestone?.id) {
			this.dataInputId = dataInputMilestone.id;
			return true;
		} else {
			return false;
		}
	}

	setupTopCardInfo() {
		this.title = this.title + this.payGroup.data.name;
		this.subtitle =
			this.payGroup.legalEntity.data.name + " - " + this.payCycleName + " - Group " + this.milestone.group;
		this.flag = this.payGroup.legalEntity.data.country;
		this.milestoneGroup = this.milestone.group;
	}

	getSelectedKPI(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe(state => {
			if (state?.globalDashboard?.kpiSelected !== undefined) {
				this.selectedKPI = state.globalDashboard.kpiSelected;
				if (state.globalDashboard.kpiSelected === "Failed Transactions") {
					this.title = "Failed transactions";
					this.store.dispatch(
						new SetStatusAction({
							statusPayment: ["FAILED,ATTEMPTS_LIMIT_EXCEEDED", "FAILED_TO_BOOK", "PROCESSING,PENDING"]
						})
					);
				}
				this.setHistory();
			} else {
				this.router.navigate(["/global-dashboard"]);
			}
		});
	}

	setupSubscriptions() {
		this.tppPaymentsApiService.reloadPayoutAfterCancelling.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.loadBookings(
				this.dataInputId,
				this.payCycleId,
				"",
				this.pageSize,
				this.pageNumber,
				this.statusFilter,
				true
			);
		});

		this.store.pipe(takeUntil(this.destroy$), select(selectStatusNetPayment)).subscribe(({ statusPayment }) => {
			this.statusFilter = statusPayment ? statusPayment : [];
			this.loadBookings(
				this.dataInputId,
				this.payCycleId,
				"",
				this.pageSize,
				this.pageNumber,
				this.statusFilter,
				false
			);
		});
	}

	getMilestoneReport(milestoneId: string): void {
		this.updating = true;
		if (milestoneId) {
			this.tppPaymentsApiService
				.getMilestoneReport(milestoneId)
				.pipe(takeUntil(this.destroy$))
				.subscribe(res => {
					this.milestoneReport = res;
					this.updating = false;
				});
		} else {
			this.milestoneReport = this.tppPaymentsApiService.DEFAULT_MILESTONE_VALUES;
			this.updating = false;
		}
	}

	resetCardInfo(): void {
		this.getMilestoneReport(this.milestone?.id ? this.milestone.id : "");
	}

	loadBookings(
		milestoneId: string,
		payCycleId: string,
		subServiceId: string,
		pageSize: number,
		pageNumber: number,
		statusFilter: string[],
		fecthInfoCardsData: boolean
	) {
		this.pageSize = pageSize;
		this.pageNumber = pageNumber;

		this.tppPaymentsApi
			.getBookings(milestoneId, payCycleId, subServiceId, pageSize, pageNumber, statusFilter)
			.pipe(takeUntil(this.destroy$))
			.subscribe((bookings: Booking[]) => {
				//Only show add booking if it has a payout with trans info

				this.bookings = [];
				this.payouts = [];

				bookings.forEach(booking => {
					booking.payouts.forEach(payout => {
						if (payout.transactions.length > 0 && !this.bookings.includes(booking)) {
							this.bookings.push(booking);

							this.payouts.push(payout);
						}
					});
				});

				if (bookings.length > 0) {
					this.bookingButtonState = this.payoutService.getBookingState(this.payouts);
				} else {
					this.bookingButtonState = "inactive";
				}

				this.getCancelReport(this.payGroup.id, this.dataInputId);

				if (fecthInfoCardsData) {
					this.updating = true;
					setTimeout(() => {
						this.resetCardInfo();
					}, 6000);
				}
			});

		this.pagination = this.tppPaymentsApi.getPaginition();
	}

	getCancelledBookings(cancelledId: string) {
		this.pageSize;
		this.pageNumber;

		this.bookings = [];
		this.payouts = [];

		this.tppPaymentsApi
			.getCancelledBookings(cancelledId, this.pageSize, this.pageNumber)
			.pipe(takeUntil(this.destroy$))
			.subscribe((bookings: Booking[]) => {
				//Only show add booking if it has a payout with trans info

				bookings.forEach(booking => {
					booking.payouts.forEach(payout => {
						if (payout.transactions.length > 0 && !this.bookings.includes(booking)) {
							this.bookings.push(booking);
							this.payouts.push(payout);
						}
					});
				});
			});
	}

	getCancelReport(paygroupId: string, g2nMilestoneId: string) {
		this.runSelection = [
			{
				text: "Current Run",
				value: "reset"
			}
		];
		this.tppPaymentsApiService
			.getCancelReport(paygroupId, g2nMilestoneId, 1000, 0)
			.pipe(take(1))
			.subscribe(cancelledRuns => {
				if (cancelledRuns.items.length) {
					cancelledRuns.items.sort(
						(a, b) => new Date(a.canceledAt).getTime() - new Date(b.canceledAt).getTime()
					);

					this.cancelReport = cancelledRuns.items;

					let i: number = 0;
					cancelledRuns.items.forEach(cancelledRun => {
						i++;

						this.runSelection.push({
							text: "Cancelled run " + i,
							value: cancelledRun.id
						});
					});
				}
			});
	}

	inactivatePayouts(payoutIds: string[]) {
		this.openDialog(payoutIds);
	}

	pushToReprocessList(payoutIds: string[]) {
		payoutIds.forEach(payoutId => {
			if (!this.reprocessPayoutIdsList.find(id => id === payoutId)) {
				this.reprocessPayoutIdsList.push(payoutId);
				this.bookingButtonState = "reprocess";
			}
		});
	}

	popFromReprocessList(payoutIds: string[]) {
		this.reprocessPayoutIdsList = this.reprocessPayoutIdsList.filter(id => !payoutIds.includes(id));

		if (this.reprocessPayoutIdsList.length === 0) {
			this.bookingButtonState = this.payoutService.getBookingState(this.payouts);
		}
	}

	openDialog(payoutIds: string[]): void {
		const dialogRef = this.dialog.open(InactivateToggleDialogComponent, {
			width: "666px",
			height: "441px",
			panelClass: "dialog-container",
			data: {
				type: "payout"
			}
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				this.tppPaymentsApi
					.inactivateTppPayout(payoutIds)
					.pipe(take(1))
					.subscribe(res => {
						this.loadBookings(
							this.dataInputId,
							this.payCycleId,
							"",
							this.pageSize,
							this.pageNumber,
							this.statusFilter,
							true
						);
					});
			}
		});
	}

	filterByStatus(status: string[]) {
		if (!status) {
			this.statusFilter = [];
		} else {
			this.statusFilter = status;
		}

		if (!this.isCancelledRun) {
			this.loadBookings(
				this.dataInputId,
				this.payCycleId,
				"",
				this.pageSize,
				this.pageNumber,
				this.statusFilter,
				false
			);
		}
	}

	processBooking() {
		this.tppPaymentsApi
			.processBooking(this.milestone.id!)
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.loadBookings(
					this.dataInputId,
					this.payCycleId,
					"",
					this.pageSize,
					this.pageNumber,
					this.statusFilter,
					true
				);
				this.statusFilter = [];
			});
	}

	reprocessBooking() {
		this.tppPaymentsApi
			.reProcessBooking(this.reprocessPayoutIdsList)
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.statusFilter = [];
				this.reprocessPayoutIdsList = [];
				this.loadBookings(
					this.dataInputId,
					this.payCycleId,
					"",
					this.pageSize,
					this.pageNumber,
					this.statusFilter,
					true
				);
			});
	}

	changeRun(cancelId: string) {
		this.cancelId = cancelId;

		if (cancelId !== "reset") {
			this.getCancelledBookings(cancelId);
			this.setupCancelBanner(cancelId);
			this.isCancelledRun = true;
			this.bookingButtonState = "inactive";
		} else {
			this.loadBookings(
				this.dataInputId,
				this.payCycleId,
				"",
				this.pageSize,
				this.pageNumber,
				this.statusFilter,
				false
			);
			this.isCancelledRun = false;
		}
	}

	setupCancelBanner(cancelId: string) {
		let currentReport = this.cancelReport.find(report => report.id === cancelId)!;

		this.runName = this.runSelection.find(run => run.value === cancelId)!.text;
		this.cancelDate = currentReport.canceledAt;
		this.cancellerName = currentReport.canceledBy;
		this.reason = currentReport.reason;
	}

	cancelBooking() {
		let providerMessages: providerMessage[] = [
			{
				provider: "Either Transfermate, Checkout or Ebury",
				message: "After canceling this batch, it is necessary to contact your Service Provider"
			}
		];

		let cancelBooking: cancelBookingDialog = {
			milestoneId: this.dataInputId,
			providerMessages: providerMessages
		};

		this.modalLauncherService.showCancelBookingModal(cancelBooking);
	}

	paginate(pagination: PageEvent) {
		this.pageSize = pagination.pageSize;
		this.pageNumber = pagination.pageIndex;
		this.loadBookings(
			this.dataInputId,
			this.payCycleId,
			"",
			this.pageSize,
			this.pageNumber,
			this.statusFilter,
			false
		);
	}

	reloadTppData(): void {
		this.statusFilter = [];
		this.loadBookings(
			this.dataInputId,
			this.payCycleId,
			"",
			this.pageSize,
			this.pageNumber,
			this.statusFilter,
			true
		);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
