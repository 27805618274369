<div (click)="handlePayGroupClick()" class="information-container" fxLayout="row" fxLayoutAlign="space-between center">
	<!-- Title -->
	<div fxLayout="column" class="title-container">
		<div
			class="information-title-container"
			[matTooltip]="
				accountStatusByPayGroup.externalLegalEntityId +
				'/' +
				accountStatusByPayGroup.payGroupName +
				'/' +
				accountStatusByPayGroup.externalPayGroupId
			"
			[matTooltipPosition]="'above'"
			[matTooltipClass]="'pay-elective-list-item'"
		>
			<span class="information-title legal-entity-flag">
				<img src="assets/svg-country-flags/svg/{{ accountStatusByPayGroup.countryCode.toLowerCase() }}.svg" />
			</span>

			<span class="information-title legal-entity-id">
				{{ accountStatusByPayGroup.externalLegalEntityId }}
			</span>
			<span class="information-title">/</span>
			<span class="information-title paygroup-name">{{ accountStatusByPayGroup.payGroupName }}</span>
			<span class="information-title">/</span>
			<span class="information-title paygroup-id"> {{ accountStatusByPayGroup.externalPayGroupId }}</span>
		</div>
		<div class="detail-container">
			<!-- Dot -->
			<div
				class="dot"
				[ngClass]="{
					warning: accountStatusByPayGroup.status === 'IMPLEMENTATION',
					success: accountStatusByPayGroup.status === 'LIVE',
					danger: accountStatusByPayGroup.status === 'INACTIVE'
				}"
			></div>
			<div class="text-status">{{ accountStatusByPayGroup.status }}</div>
		</div>
	</div>
	<div class="data-container" *ngIf="accountStatusByPayGroup.stats != null">
		<div class="employee-info" fxLayout="column" fxFlex="80" fxLayoutAlign="start start">
			<div fxLayout="column" fxLayoutAlign="start start">
				<div class="employee-title">{{ accountStatusByPayGroup.totalEmployees }} Employees</div>
				<div class="information-subtitle-text">
					{{ accountStatusByPayGroup.totalBeneficiaries }}
					{{ accountStatusByPayGroup.totalBeneficiaries === 1 ? "Beneficiary" : "Beneficiaries" }}
				</div>
			</div>
		</div>
		<div fxLayout="row" fxFlex="20" fxLayoutAlign="center center">
			<div
				[ngClass]="{ disabled: accountStatusByPayGroup.stats.payMethodsErrorCount === 0 }"
				fxLayout="column"
				fxLayoutGap="10px"
			>
				<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
					<div
						*ngIf="
							accountStatusByPayGroup.stats.payMethodsErrorCount != null &&
							accountStatusByPayGroup.stats.payMethodsErrorCount > 0
						"
						class="error-circle"
					>
						<span>+</span>
					</div>
					<app-icon class="icon ben" [size]="'22'" [name]="'2user'"></app-icon>
					<span
						*ngIf="
							accountStatusByPayGroup.stats.payMethodsErrorCount != null &&
							accountStatusByPayGroup.stats.payMethodsErrorCount > 0
						"
						class="counts"
					>
						{{ accountStatusByPayGroup.stats.payMethodsErrorCount }}
					</span>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center">
					<span class="icon-text">Beneficiaries</span>
				</div>
			</div>
		</div>
		<div fxLayout="row" fxFlex="20" fxLayoutAlign="center center">
			<div
				[ngClass]="{ disabled: accountStatusByPayGroup.stats.employeesWithNoCardCount === 0 }"
				fxLayout="column"
				fxLayoutGap="10px"
			>
				<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
					<div
						*ngIf="
							accountStatusByPayGroup.stats.employeesWithNoCardCount != null &&
							accountStatusByPayGroup.stats.employeesWithNoCardCount > 0
						"
						class="warning-circle"
					>
						<span>!</span>
					</div>
					<app-icon class="icon card" [size]="'22'" [name]="'card'"></app-icon>
					<span
						*ngIf="
							accountStatusByPayGroup.stats.employeesWithNoCardCount != null &&
							accountStatusByPayGroup.stats.employeesWithNoCardCount > 0
						"
						class="counts"
					>
						{{ accountStatusByPayGroup.stats.employeesWithNoCardCount }}
					</span>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center">
					<span class="icon-text">Cards</span>
				</div>
			</div>
		</div>
		<div fxLayout="row" fxFlex="20" fxLayoutAlign="center center">
			<div
				[ngClass]="{ disabled: accountStatusByPayGroup.stats.employeesWithNoAccountCount === 0 }"
				fxLayout="column"
				fxLayoutGap="10px"
			>
				<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
					<div
						*ngIf="
							accountStatusByPayGroup.stats.employeesWithNoAccountCount != null &&
							accountStatusByPayGroup.stats.employeesWithNoAccountCount > 0
						"
						class="warning-circle"
					>
						<span>!</span>
					</div>
					<app-icon class="icon" [size]="'22'" [name]="'bank-figma'"></app-icon>
					<span
						*ngIf="
							accountStatusByPayGroup.stats.employeesWithNoAccountCount != null &&
							accountStatusByPayGroup.stats.employeesWithNoAccountCount > 0
						"
						class="counts"
					>
						{{ accountStatusByPayGroup.stats.employeesWithNoAccountCount }}
					</span>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center">
					<span class="icon-text">Bank Accounts</span>
				</div>
			</div>
		</div>
	</div>
</div>
