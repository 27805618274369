import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { IDashboardFiltersFormGroup } from "@modules/global-dashboard/models/global-dashboard-interface";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { TabStateService } from "@shared/services/tab-state/tab-state.service";
import { UpdateDashboardFilterAction } from "@store/actions/globalDashboardFilterSelect.action";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";
import { Observable, Subject } from "rxjs";
import { map, take } from "rxjs/operators";

import { ApiService } from "@modules/pay-groups/services/api.service";
import { CustomerCacheService } from "@shared/services/customer/customer-cache.service";
import { CustomerService } from "@shared/services/customer/customer.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { DropDownData, ExternalIDAndName } from "src/app/shared/models/breadcrumbs.interface";
import { Customer, CustomerPagination } from "src/app/shared/models/customer.interface";
import { LegalEntity, LegalEntityPagination } from "src/app/shared/models/legal-entity.interface";
import { PayGroup } from "src/app/shared/models/pay-groups";
import { getCustomerEntityGroupState, getGlobalDashboardFilterState, getUserDataState } from "src/app/store";
import { UpdateCustomerEntityGroupAction } from "src/app/store/actions/customerEntityGroupSelect.action";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { AppState } from "src/app/store/models/state.model";
import { environment } from "src/environments/environment";
import { PaginationData } from "@store/models/pagination.model";
import { ResetPagination } from "@store/actions/pagination.action";
import { PaginationService } from "@shared/components/pagination/_services/pagination.service";
import { UserRole } from "@shared/constants/roles";

@Injectable({
	providedIn: "root"
})
export class BreadcrumbService {
	constructor(
		private store: Store<AppState>,
		private router: Router,
		private legalEntityService: LegalEntityService,
		private payGroupService: PaygroupsService,
		private payGroupAPIService: ApiService,
		private toast: ToastService,
		private customerService: CustomerService,
		private http: HttpClient,
		private customerCache: CustomerCacheService,
		private tabStateService: TabStateService,
		private permissionsService: PermissionsService,
		private paginationService: PaginationService
	) {
		this.store.pipe(select(getUserDataState)).subscribe({
			next: res => {
				if (res.roles?.includes(UserRole.CLIENT)) {
					this.isClientRole = true;
				}
			}
		});
	}

	dropDownItemSelected$: Subject<boolean> = new Subject<boolean>();
	textIsOverflowing = new Subject<boolean>();
	paygroupUpdated$: Subject<boolean> = new Subject<boolean>();

	fullyTruncateCustomerCrumb = new Subject<boolean>();

	private isClientRole = false;

	getLegalEntityName(id: string): Observable<string> {
		return this.legalEntityService.getLegalEntity(id).pipe(
			take(1),
			map(entity => entity.data.name)
		);
	}

	getPayGroupNameAndExternalId(id: string): Observable<ExternalIDAndName> {
		return this.payGroupService.getPaygroupWithPaygroupId(id).pipe(
			take(1),
			map(entity => ({ name: entity.data.name, externalId: entity.externalId }))
		);
	}

	getCustomerNameAndExternalId(id: string): Observable<ExternalIDAndName> {
		return this.customerService.getCustomer(id).pipe(
			take(1),
			map(entity => ({ name: entity.name, externalId: entity.externalId }))
		);
	}

	getFlag(id: string): Observable<string> {
		return this.payGroupService.getPaygroupWithPaygroupId(id).pipe(
			take(1),
			map(entity => entity.legalEntity.data.country)
		);
	}

	updateCustomerEntityGroupState() {
		this.store.pipe(take(1), select(getCustomerEntityGroupState)).subscribe({
			next: (state: CustomerEntityGroupSelect) => {
				let customerEntity = new UpdateCustomerEntityGroupAction({
					customerId: state.customerId,
					legalEntityId: "",
					payGroupId: "",
					customerName: state.customerName
				});
				this.store.dispatch(customerEntity);
			}
		});
	}

	updateCustomerEntityGroupStatePayGroup(value: string) {
		this.store.pipe(take(1), select(getCustomerEntityGroupState)).subscribe({
			next: (state: CustomerEntityGroupSelect) => {
				let customerEntity = new UpdateCustomerEntityGroupAction({
					customerId: state.customerId,
					legalEntityId: state.legalEntityId,
					payGroupId: value,
					customerName: state.customerName
				});
				this.store.dispatch(customerEntity);

				this.paygroupUpdated$.next(true);
			}
		});
	}

	updateCustomerEntityGroupStatePayGroupFromConversion(value: string) {
		this.store.pipe(take(1), select(getCustomerEntityGroupState)).subscribe({
			next: (state: CustomerEntityGroupSelect) => {
				let customerEntity = new UpdateCustomerEntityGroupAction({
					customerId: state.customerId,
					legalEntityId: "",
					payGroupId: value,
					customerName: state.customerName
				});

				this.store.dispatch(customerEntity);

				this.paygroupUpdated$.next(true);
			}
		});
	}

	updateCustomerEntityGroupStateLegalEntity(value: string): void {
		this.store.pipe(take(1), select(getCustomerEntityGroupState)).subscribe({
			next: (state: CustomerEntityGroupSelect) => {
				let customerEntity = new UpdateCustomerEntityGroupAction({
					customerId: state.customerId,
					legalEntityId: value,
					payGroupId: state.payGroupId,
					customerName: state.customerName
				});

				this.store.dispatch(customerEntity);
				this.dropDownItemSelected$.next(true);
			}
		});
	}

	updateCustomerEntityGroupStateLegalEntityOnNavigation(value: string): void {
		this.store.pipe(take(1), select(getCustomerEntityGroupState)).subscribe({
			next: (state: CustomerEntityGroupSelect) => {
				let customerEntity = new UpdateCustomerEntityGroupAction({
					customerId: state.customerId,
					legalEntityId: value,
					payGroupId: "",
					customerName: state.customerName
				});
				this.store.dispatch(customerEntity);
				this.dropDownItemSelected$.next(true);
			}
		});
	}

	resetCustomerEntityGroupState(): void {
		this.permissionsService.keepCustomerSelection().subscribe(isClient => {
			if (isClient) {
				this.store.pipe(take(1), select(getCustomerEntityGroupState)).subscribe({
					next: (state: CustomerEntityGroupSelect) => {
						this.updateCustomerEntityGroup(state);
					}
				});
			} else {
				this.updateCustomerEntityGroup();
			}
		});
	}

	private updateCustomerEntityGroup(state?: CustomerEntityGroupSelect): void {
		this.tabStateService.setBreadcrumbIdentifier([]);
		const customerEntity = new UpdateCustomerEntityGroupAction({
			customerId: state?.customerId || "",
			legalEntityId: "",
			payGroupId: "",
			customerName: state?.customerName || ""
		});

		this.store.dispatch(customerEntity);

		this.paginationService.resetPaginationState();
	}

	updateBreadCrumbIDRemovePaygroup(): void {
		let storedIdentifier = this.tabStateService.getBreadcrumbIdentifier();
		const index = storedIdentifier.findIndex(res => res.key === "payGroupId");

		storedIdentifier.splice(index, 1);

		this.tabStateService.setBreadcrumbIdentifier(storedIdentifier);
	}

	resetGlobalDashboardKPISelection(): void {
		this.store.pipe(take(1), select(getGlobalDashboardFilterState)).subscribe({
			next: (state: GlobalDashboardFilterSelect) => {
				this.store.dispatch(
					new UpdateDashboardFilterAction({
						globalDashboard: { kpiSelected: "", filters: state.globalDashboard.filters }
					})
				);
			}
		});
	}

	getDropDownData(customerId: string): DropDownData {
		let dropDownData: DropDownData = {
			customers: [],
			legalEntities: [],
			payGroups: []
		};

		dropDownData = this.watchCustomers(dropDownData);

		this.watchLegalEntities(dropDownData, customerId);

		return dropDownData;
	}

	private watchCustomers(dropDownData: DropDownData): DropDownData {
		// All Customers
		this.customerCache
			.getAllCustomers()
			.pipe(
				map(res => {
					return res.map((obj: Customer) => ({
						id: obj.id,
						name: obj.name,
						externalId: obj.externalId
					}));
				})
			)
			.subscribe(res => (dropDownData.customers = res));

		return dropDownData;
	}

	private watchLegalEntities(dropDownData: DropDownData, customerId: string): void {
		this.legalEntityService
			.searchLegalEntities(customerId, 0, -1, "", "")
			.pipe(
				take(1),
				map(res => {
					return res.items;
				}),
				map(entities => {
					return entities.map((obj: LegalEntity) => ({
						id: obj.id,
						name: obj.data.name,
						externalId: obj.data.externalId
					}));
				}),
				map(res => {
					dropDownData.legalEntities = res;
				})
			)
			.subscribe({
				next: state => {
					if (this.isClientRole) {
						this.payGroupService
							.getPayGroupWithCustomerIdForClient(customerId)
							.pipe(
								take(1),
								map(res => {
									return res.items;
								}),
								map(payGroup => {
									return payGroup.map((obj: PayGroup) => ({
										id: obj.id,
										name: obj.data.name,
										externalId: obj.externalId
									}));
								}),
								map(res => {
									dropDownData.payGroups = res;
								})
							)
							.subscribe();
					} else {
						this.payGroupService
							.getPayGroupWithCustomerId(customerId)
							.pipe(
								take(1),
								map(res => {
									return res.items;
								}),
								map(payGroup => {
									return payGroup.map((obj: PayGroup) => ({
										id: obj.id,
										name: obj.data.name,
										externalId: obj.externalId
									}));
								}),
								map(res => {
									dropDownData.payGroups = res;
								})
							)
							.subscribe();
					}
					// Groups
				}
			});
	}

	// *** breadcrumb action ***
	updateStateFromBreadCrumb(
		updateArray: string[],
		id: string,
		key: string,
		idCrumbUpdateArray: { key: string; spawn: string }[],
		selectedCustomerId: string
	) {
		// if key is customerId
		if (key === "customerId") {
			this.feedForwardStateUpdate(id, updateArray, id, key);
		}

		if (key === "payGroupId") {
			this.feedForwardStateUpdate(selectedCustomerId, updateArray, id, key);
		}

		if (key === "legalEntityId") {
			this.feedForwardStateUpdate(selectedCustomerId, updateArray, id, key);
		}
	}

	feedForwardStateUpdate(customerId: string, config: string[], id: string, key: string) {
		const statuses: string = "LIVE, IMPLEMENTATION";
		const PAGE_SIZE = 1000;
		const PAGE_INDEX = 0;

		this.payGroupAPIService.getPayGroups(customerId, "", statuses, "", "", PAGE_SIZE, PAGE_INDEX).subscribe(res => {
			const admissableList = ["/customers/edit", "/legal-entities/select", "/legal-entities/update"];

			if (res.items.length === 0) {
				if (admissableList.includes(this.router.url)) {
					this.dispatchCustomerById(customerId);
					this.dropDownItemSelected$.next(true);
					this.toast.showWarning("This customer has no Pay Groups.");

					if (this.router.url === "/legal-entities/update") {
						this.findLegalEntityByCustomerId(customerId);
					}
				} else {
					this.toast.showError("This customer has no Pay Groups.");
				}
			} else {
				if (config.includes("legalEntityId") && !res.items[0]) {
					this.toast.showError("This customer has no Pay Groups.");
					return;
				}

				this.dispatchCustomer(res.items, customerId);

				if (config.includes("legalEntityId")) {
					this.dispatchLegalEntity(res.items, customerId, id, key);
				}

				if (config.includes("payGroupId")) {
					this.dispatchPaygroup(res.items, customerId, id, key);
				}

				this.dropDownItemSelected$.next(true);
			}
		});
	}

	findLegalEntityByCustomerId(customerId: string): void {
		this.legalEntityService
			.getLegalEntitiesForCustomer(customerId)
			.pipe(take(1))
			.subscribe({
				next: res => {
					if (res.items.length) {
						this.updateCustomerEntityGroupStateLegalEntity(res.items[0].id);
					} else {
						this.toast.showError("This customer has no Legal Entities.");
					}
				}
			});
	}

	findLegalEntityByCustomerIdObservable(customerId: string): Observable<LegalEntityPagination> {
		return this.legalEntityService.getLegalEntitiesForCustomer(customerId);
	}

	findLegalEntityByPayGroupIdObservable(payGroupId: string): Observable<PayGroup> {
		return this.payGroupService.getPaygroupWithPaygroupId(payGroupId);
	}

	convertLegalToPayGroup(): void {
		if (this.tabStateService.getCustomerEntityGroupSelect().legalEntityId !== "") {
			this.payGroupService
				.getPaygroupWithLegalEntityIds([this.tabStateService.getCustomerEntityGroupSelect().legalEntityId])
				.pipe(take(1))
				.subscribe({
					next: res => {
						this.updateCustomerEntityGroupStatePayGroupFromConversion(res.items[0].id);
					}
				});
		}
	}

	dispatchCustomerById(customerId: string): void {
		this.customerService
			.getCustomer(customerId)
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.store.dispatch(
						new UpdateCustomerEntityGroupAction({
							customerId: customerId,
							legalEntityId: "",
							payGroupId: "",
							customerName: res.name
						})
					);

					this.paginationService.resetPaginationState();
				}
			});
	}

	dispatchCustomer(resItems: PayGroup[], customerId: string): void {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerId: customerId,
				legalEntityId: "",
				payGroupId: "",
				customerName: resItems[0].customer.name
			})
		);

		this.paginationService.resetPaginationState();
	}

	dispatchLegalEntity(resItems: PayGroup[], customerId: string, id: string, key: string): void {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				legalEntityId: key === "legalEntityId" ? id : resItems[0].legalEntityId,
				customerId: customerId,
				payGroupId: "",
				customerName: resItems[0].customer.name
			})
		);

		this.paginationService.resetPaginationState();
	}

	dispatchPaygroup(resItems: PayGroup[], customerId: string, id: string, key: string): void {
		this.store.dispatch(
			new UpdateCustomerEntityGroupAction({
				customerName: resItems[0].customer.name,
				customerId: customerId,
				legalEntityId:
					key === "payGroupId"
						? resItems[resItems.findIndex(paygroup => paygroup.id === id)].legalEntityId
						: resItems[0].legalEntityId,
				payGroupId: key === "payGroupId" ? id : resItems[0].id
			})
		);

		this.paginationService.resetPaginationState();
	}

	getCustomersWithPagination(pageSize: number, pageNumber: number): Observable<CustomerPagination> {
		return this.http.get<CustomerPagination>(
			`${environment.apiUri}/v1/customers?pageSize=${pageSize}&pageNumber=${pageNumber}`
		);
	}

	getRouteToUrl(crumbName: string): string {
		// Where the first crumb's click should take you in each section.
		switch (crumbName.toLowerCase()) {
			case "employee data":
				return "/employee-data";
			case "payments":
				return "/payments";
			case "dashboard":
				return "/payments";
			case "tpp service definition":
				return "/service-definition/tpp";
			case "nets service definition":
				return "/service-definition/net";
			case "calendar":
				return "/calendar";
			case "service provider":
				return "/service-provider-setup";
			case "customers":
				return "/customers";
			case "legal entities":
				return "/legal-entities";
			case "pay groups":
				return "/pay-groups";
			case "tpp catalogue":
				return "/tpp-catalogue";
			default:
				return "";
		}
	}
}
