import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject, Subscription, of } from "rxjs";
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";

import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { PayElectiveCommonService } from "@modules/employee-data/services/pay-elective-common.service";
import { FileManagementUploadService } from "@shared/services/file-management/file-management-upload.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { ToastService } from "@shared/services/toast/toast.service";
import { SelectOption } from "@shared/models/select-option.interface";
import { UpdateProvider } from "@store/actions/providerSelect.action";
import { AppState } from "@store/models/state.model";
import {
	EmployeeReportPayGroupData,
	PayElectivePayGroupDataCounts
} from "../../../../../shared/models/employee.interface";
import { PayElectiveBankAccountsService } from "../../../services/pay-elective-bank-accounts.service";
import { AccountFilter } from "../_models/account-filter.interface";

@Component({
	selector: "app-pay-elective-list-search",
	templateUrl: "./pay-elective-list-search.component.html",
	styleUrls: ["./pay-elective-list-search.component.scss"]
})
export class PayElectiveListSearchComponent implements OnInit, OnDestroy {
	@Input() counts!: PayElectivePayGroupDataCounts;

	@Input() set hasMissingCardPreSelected(showUploadedCards: boolean | null) {
		if (showUploadedCards !== null && showUploadedCards === false) {
			this.filterBy("hide uploadedCard");
		}
	}

	@Input() set hasMissingBankPreSelected(showUploadedBank: boolean | null) {
		if (showUploadedBank !== null && showUploadedBank === false) {
			this.filterBy("hide uploadedBank");
		}
	}

	@Input() set employeeReportCounts(value: EmployeeReportPayGroupData) {
		this._employeeReportCounts = value;
	}

	@Input() selectedProvider: string = "";

	@Input() set providersAccountErrors(data: Map<string, { errorCount: number }>) {
		this.providersErrors = data;
	}

	@Input() set totalEmployees(count: number) {
		this._totalEmployees = count;
	}

	@Input() set defaultStatus(value: string) {
		this._defaultStatus = value;
		this.getStatuses(value);
		if (this.form) {
			this.form.get("status")?.patchValue(value, { emitValue: true });
		}
	}

	@Input() set selectedPayGroupId(value: string) {
		this._selectedPayGroupId = value;
	}

	@Input() set _provivers$(providers: Observable<SelectOption[]>) {
		providers
			.pipe(
				map(_providers => {
					if (_providers.length > 0) {
						if (
							!this.form.get("provider")?.value ||
							!_providers.find(provider => provider.value === this.form.get("provider")?.value)
						) {
							this.form.get("provider")?.patchValue(_providers[0]?.value, { emitEvent: false });
							this.seletectedProviderValue = _providers[0]?.value;
						} else {
							this.seletectedProviderValue = this.form.get("provider")?.value;
						}
					} else {
						this.form.get("provider")?.patchValue([], { emitEvent: false });
						this.seletectedProviderValue = "";
					}

					this.storeProvider(this.seletectedProviderValue);
					this.providers$ = of(_providers);
				})
			)
			.subscribe();
	}

	@Output() searchChanges: EventEmitter<string> = new EventEmitter();
	@Output() filterChanges: EventEmitter<AccountFilter> = new EventEmitter();
	@Output() filtersChanged: EventEmitter<string> = new EventEmitter();
	@Output() providerChanged: EventEmitter<string> = new EventEmitter();
	@Output() refreshEmployeeList: EventEmitter<Event> = new EventEmitter();

	selectedPayMethods: string[] = [];
	destroy$: Subject<void> = new Subject();
	selectSubscription: Subscription = new Subscription();
	unSelectSubscription: Subscription = new Subscription();
	providersErrors!: Map<string, { errorCount: number }>;
	_totalEmployees: number = 0;
	_selectedPayGroupId: string = "";
	errorCheck: number = 0;

	displayFilter: boolean = false;

	//Filter eye-cons
	showMissingCard: boolean = true;
	showUploadedCard: boolean = true;
	showMissingBank: boolean = true;
	showUploadedBank: boolean = true;

	//Filters
	form!: FormGroup;
	statuses: SelectOption[] = [];
	providers$!: Observable<SelectOption[]>;
	seletectedProviderValue: string = "";
	selectedStatus!: SelectOption;
	canUploadEmployeesFiles = false;
	filtersApplied = false;

	private _employeeReportCounts!: EmployeeReportPayGroupData;
	private _updateFilesSubscription = new Subscription();
	private _defaultStatus = "all";

	filtersAppliedFromStatusDropDown = false;

	get employeeReportCounts(): EmployeeReportPayGroupData {
		return this._employeeReportCounts;
	}

	constructor(
		private payElectiveBankAccountsService: PayElectiveBankAccountsService,
		private formBuilder: FormBuilder,
		private commonService: PayElectiveCommonService,
		private router: Router,
		private store: Store<AppState>,
		private permissions: PermissionsService,
		private commonFileManagerService: FileManagementUploadService,
		private toastService: ToastService
	) {}

	ngOnInit(): void {
		this.permissions
			.canUploadEmployeesFiles()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.canUploadEmployeesFiles = res;
				this.initForm();
				this.initSubscriptions();
				this.filtersApplied = this.isFiltersApplied();
			});
	}
	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		this.unSelectSubscription && this.unSelectSubscription.unsubscribe();
		this.selectSubscription && this.selectSubscription.unsubscribe();
		this._updateFilesSubscription && this._updateFilesSubscription.unsubscribe();
	}

	getStatuses(statusSelected: string): void {
		this.statuses = this.commonService.getStatusOptions();
		let selectOption = this.statuses.find(status => status.value === statusSelected);
		this.selectedStatus = selectOption ? selectOption : this.statuses[0];
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			search: [""],
			status: [this._defaultStatus],
			provider: [],
			gdpr: []
		});

		if (this.form.get("status")?.value !== "all") {
			this.filtersAppliedFromStatusDropDown = true;
		}

		this.form
			.get("search")
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
			.subscribe(searchValue => {
				this.searchChanges.emit(searchValue);
			});

		this.form
			.get("provider")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(provider => {
				this.providerChanged.emit(provider), this.storeProvider(provider);
			});

		this.form
			.get("status")
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				if (value === "all") {
					this.filtersChanged.emit("");
					this.selectedStatus = this.statuses[0];
					this.filtersAppliedFromStatusDropDown = false;
				} else {
					this.filtersChanged.emit(value);
					this.selectedStatus = this.statuses.filter(status => status.value === value)[0];
					this.filtersAppliedFromStatusDropDown = true;
				}
			});
	}

	isConsultantManager(): Observable<boolean> {
		return this.permissions.isConsultantManager();
	}

	goToFileMangement() {
		this.router.navigate(["employee-data/master-data-history"]);
	}

	uploadPayElectiveFile(files: any) {
		try {
			const file: File = files[0];

			let formData = new FormData();
			formData.append("file", file);
			formData.append("payGroupId", this._selectedPayGroupId);
			formData.append("fileType", "EXCEL");
			formData.append("sheetIndex", "0");
			formData.append("headerIndex", "4");

			this.commonFileManagerService.uploadMasterFile(formData);
		} catch (error) {
			this.toastService.showError("There was an error loading the file. Please try again");
		}
	}

	storeProvider(value: string) {
		this.store.dispatch(
			new UpdateProvider({
				provider: value
			})
		);
	}

	filter() {
		this.displayFilter = !this.displayFilter;
	}

	closeFilter() {
		this.displayFilter = false;
	}

	clearfilters(): void {
		this.showMissingCard = true;
		this.showUploadedCard = true;
		this.showUploadedCard = true;
		this.showMissingCard = true;
		this.showMissingBank = true;
		this.showUploadedBank = true;
		this.showUploadedBank = true;
		this.showMissingBank = true;
		this.sendFilterRequest();
		this.form.get("status")?.setValue("all");
	}

	filterBy(filter: string): void {
		switch (filter) {
			case "show missingCard": {
				this.showMissingCard = true;
				this.sendFilterRequest();
				break;
			}
			case "hide missingCard": {
				this.showMissingCard = false;
				this.showUploadedCard = true;
				this.sendFilterRequest();
				break;
			}

			case "show uploadedCard": {
				this.showUploadedCard = true;
				this.sendFilterRequest();
				break;
			}
			case "hide uploadedCard": {
				this.showUploadedCard = false;
				this.showMissingCard = true;
				this.sendFilterRequest();
				break;
			}

			case "show missingBank": {
				this.showMissingBank = true;
				this.sendFilterRequest();
				break;
			}
			case "hide missingBank": {
				this.showMissingBank = false;
				this.showUploadedBank = true;
				this.sendFilterRequest();
				break;
			}

			case "show uploadedBank": {
				this.showUploadedBank = true;
				this.sendFilterRequest();
				break;
			}
			case "hide uploadedBank": {
				this.showUploadedBank = false;
				this.showMissingBank = true;
				this.sendFilterRequest();
				break;
			}
		}
	}

	isFiltersApplied(): boolean {
		return (
			!this.showMissingCard ||
			!this.showUploadedCard ||
			!this.showUploadedCard ||
			!this.showMissingCard ||
			!this.showMissingBank ||
			!this.showUploadedBank ||
			!this.showUploadedBank ||
			!this.showMissingBank
		);
	}

	sendFilterRequest() {
		//If both showUploadedBank and showMissingBank are true - nothing should be send to the filter | same with cards

		var bankFilter: boolean | null = null;
		var cardFilter: boolean | null = null;

		if (this.showMissingBank && this.showUploadedBank) {
			bankFilter = null; //show both
		} else if (this.showMissingBank && !this.showUploadedBank) {
			bankFilter = false;
		} else if (this.showUploadedBank && !this.showMissingBank) {
			bankFilter = true;
		} else {
			//if both this.showMissingBank && this.showUploadedBank are false
			bankFilter = false; //logic error
		}

		if (this.showMissingCard && this.showUploadedCard) {
			//cardFilter = ""; //show both
		} else if (this.showMissingCard && !this.showUploadedCard) {
			cardFilter = false;
		} else if (this.showUploadedCard && !this.showMissingCard) {
			cardFilter = true;
		} else {
			//if both this.showMissingBank && this.showUploadedBank are false
			cardFilter = false; //logic error
		}

		this.filterChanges.emit({ bankFilter, cardFilter });
	}

	close() {
		this.displayFilter = false;
	}

	reProcessAll() {
		this.payElectiveBankAccountsService.reProcessAll(this._selectedPayGroupId);
	}

	showActiveCounts(): number {
		if (this.employeeReportCounts && Object.keys(this.employeeReportCounts).length === 0) {
			return 0;
		}

		const statusCounts = this.employeeReportCounts?.statusCountsByProvider;
		const selectedProvider = this.selectedProvider;

		if (statusCounts && statusCounts.hasOwnProperty(selectedProvider)) {
			return Number(statusCounts[selectedProvider].ACTIVE ?? 0);
		}

		return 0;
	}
	showFailedCounts() {
		if (this.employeeReportCounts && Object.keys(this.employeeReportCounts).length === 0) {
			return 0;
		}

		const statusCounts = this.employeeReportCounts?.statusCountsByProvider;
		const selectedProvider = this.selectedProvider;

		if (statusCounts && statusCounts.hasOwnProperty(selectedProvider)) {
			return Number(statusCounts[selectedProvider].INACTIVE ?? 0);
		}

		return 0;
	}

	showValidatingCounts() {
		if (this.employeeReportCounts && Object.keys(this.employeeReportCounts).length === 0) {
			return 0;
		}

		const statusCounts = this.employeeReportCounts?.statusCountsByProvider;
		const selectedProvider = this.selectedProvider;

		if (statusCounts && statusCounts.hasOwnProperty(selectedProvider)) {
			return Number(statusCounts[selectedProvider].VALIDATING ?? 0);
		}

		return 0;
	}

	disableReprocessButton(): boolean {
		return this.showFailedCounts() > 0 ? false : true;
	}

	initSubscriptions() {
		this.selectSubscription = this.payElectiveBankAccountsService.selectedAccounts$
			.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				this.selectedPayMethods.push(value);
			});
		this.unSelectSubscription = this.payElectiveBankAccountsService.unSelectAccounts$
			.pipe(takeUntil(this.destroy$))
			.subscribe(value => {
				this.selectedPayMethods = this.selectedPayMethods.filter(id => id !== value);
			});

		this._updateFilesSubscription = this.commonFileManagerService.updateMasterDataFiles$.subscribe(() => {
			this.goToFileMangement();
		});
	}

	errorCount(): number {
		return this.providersErrors.size;
	}

	errorTotal(): number {
		let count: number = 0;
		let provArray = [...this.providersErrors.keys()];
		if (this.errorCount() > 1) {
			if (provArray.includes("CHECKOUT")) {
				count += this.providersErrors.get("CHECKOUT")?.errorCount!;
			}
			if (provArray.includes("EBURY")) {
				count += this.providersErrors.get("EBURY")?.errorCount!;
			}

			if (provArray.includes("TRANSFERMATE")) {
				count += this.providersErrors.get("TRANSFERMATE")?.errorCount!;
			}

			return count;
		} else {
			return count;
		}
	}

	refreshEmployeeListAndStatuses(): void {
		this.refreshEmployeeList.emit();
	}
}
