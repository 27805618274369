import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
	CardDetails,
	FailedPaymentOrdersTableDetailItem,
	FailedTransactionsTableDetailItem,
	KpiTypes
} from "../models/global-dashboard-interface";

@Injectable({
	providedIn: "root"
})
export class DataFormattingService {
	constructor() {}

	getKpiTypeFromString(key: string): KpiTypes {
		return KpiTypes[key as keyof typeof KpiTypes];
	}

	getCardsDetails(kpiString: string, icon: boolean): string {
		const kpi: KpiTypes = this.getKpiTypeFromString(kpiString);

		switch (kpi) {
			case KpiTypes.beneficiaries:
				return icon ? "profile-2user" : "dark";

			case KpiTypes.cards:
				return icon ? "card" : "light";

			case KpiTypes.banks:
				return icon ? "bank-figma" : "light";

			case KpiTypes.transactions:
				return icon ? "wallet-add" : "dark";

			case KpiTypes.dataInputMilestones:
				return icon ? "document-copy" : "dark";
			default:
				return "";
		}
	}

	formatFailedPaymentOrders(
		failedPaymentOrders: FailedPaymentOrdersTableDetailItem[]
	): FailedPaymentOrdersTableDetailItem[] {
		failedPaymentOrders.map(paymentOrders => {
			let totalFailedPaymentOrders: number = 0;
			paymentOrders.payGroups.forEach(paygroup => {
				totalFailedPaymentOrders += paygroup.failedPaymentOrdersCount ? paygroup.failedPaymentOrdersCount : 0;
			});

			paymentOrders.totalfailedPaymentOrdersCount = totalFailedPaymentOrders;
		});

		return failedPaymentOrders;
	}

	formatFailedTransactions(
		failedTransactions: FailedTransactionsTableDetailItem[]
	): FailedTransactionsTableDetailItem[] {
		let sortedFailedTransactionns: FailedTransactionsTableDetailItem[] = [];

		sortedFailedTransactionns = failedTransactions.map(transactions => {
			let paygroupsCount: string[] = [];
			let paymentTypeCount: string[] = [];

			transactions.payGroups.forEach(payGroup => {
				if (!paymentTypeCount.includes(payGroup.milestoneDetails.group!)) {
					paymentTypeCount.push(payGroup.milestoneDetails.group!);
				}

				if (!paygroupsCount.includes(payGroup.payGroupDetails.name)) {
					paygroupsCount.push(payGroup.payGroupDetails.name);
				}
			});

			transactions.paymentCount = paymentTypeCount.length;
			transactions.payGroupCount = paygroupsCount.length;

			return transactions;
		});

		return sortedFailedTransactionns;
	}

	calcPercentage(count: number, totalCount: number): number {
		return Number(((count / totalCount) * 100).toFixed(2));
	}

	getAvailableFiltersLayers(kpi: string): string[] {
		switch (kpi) {
			case "Failed Beneficiaries":
			case "Missing Cards":
			case "Missing Bank Acc.":
			case "Customers":
			case "Paygroups":
				return ["first"];
			case "Failed Transactions":
			case "Failed Payment Orders":
				return ["first", "second"];
			default:
				return ["first", "second", "third"];
		}
	}

	clearFilters(kpiSelected: string, filters: FormGroup): FormGroup {
		let availibleFilterLayers: string[] = this.getAvailableFiltersLayers(kpiSelected);

		if (!availibleFilterLayers.includes("second")) {
			filters.patchValue({
				customers: filters.get("customers")?.value,
				legalEntities: filters.get("legalEntities")?.value,
				paygroups: filters.get("paygroups")?.value,
				paycycles: [],
				groups: [],
				deadline: "",
				hasErrors: false,
				pending: false,
				completed: false,
				bookingDate: false,
				paymentOrders: false,
				fundingDate: false
			});
		} else if (!availibleFilterLayers.includes("third")) {
			filters.patchValue({
				customers: filters.get("customers")?.value,
				legalEntities: filters.get("legalEntities")?.value,
				paygroups: filters.get("paygroups")?.value,
				paycycles: filters.get("paycycles")?.value,
				groups: filters.get("groups")?.value,
				deadline: filters.get("deadline")?.value,
				hasErrors: false,
				pending: false,
				completed: false,
				bookingDate: false,
				paymentOrders: false,
				fundingDate: false
			});
		}

		return filters;
	}

	getKPIDefaultValue(): CardDetails[] {
		return [
			{
				kpiType: {
					[KpiTypes.beneficiaries]: {
						count: 0,
						totalCount: 0,
						percentage: 0
					}
				},
				icon: this.getCardsDetails("beneficiaries", true),
				theme: this.getCardsDetails("beneficiaries", false)
			},
			{
				kpiType: {
					[KpiTypes.cards]: {
						count: 0,
						totalCount: 0,
						percentage: 0
					}
				},
				icon: this.getCardsDetails("cards", true),
				theme: this.getCardsDetails("cards", false)
			},
			{
				kpiType: {
					[KpiTypes.banks]: {
						count: 0,
						totalCount: 0,
						percentage: 0
					}
				},
				icon: this.getCardsDetails("banks", true),
				theme: this.getCardsDetails("banks", false)
			},

			{
				kpiType: {
					[KpiTypes.transactions]: {
						count: 0,
						totalCount: 0,
						percentage: 0
					}
				},
				icon: this.getCardsDetails("transactions", true),
				theme: this.getCardsDetails("transactions", false)
			},
			{
				kpiType: {
					[KpiTypes.dataInputMilestones]: {
						count: 0,
						totalCount: 0,
						percentage: 0
					}
				},
				icon: this.getCardsDetails("dataInputMilestones", true),
				theme: this.getCardsDetails("dataInputMilestones", false)
			}
		];
	}
}
