<div class="list-container">
	<!-- If there are items -->
	<div class="paygroup-items-container">
		<div class="paygroup-items-header" fxLayout="row" fxLayoutAlign="start center">
			<h3>PAYGROUPS LIST</h3>
		</div>
		<div *ngIf="payGroups; else noItems">
			<ng-container *ngFor="let payGroup of payGroups; last as last">
				<div
					class="information-container"
					fxLayout="row"
					fxLayoutAlign="space-between center"
					(click)="selectPayGroup(payGroup)"
				>
					<!-- Title -->
					<div fxLayout="column" class="title-container" fxLayoutAlign="start">
						<div class="information-title">{{ payGroup.data.name }}</div>

						<!-- Subtitle -->
						<div class="subtitle">
							<div class="information-subtitle-text">{{ payGroup.externalId }}</div>
						</div>
					</div>
				</div>
				<mat-divider *ngIf="!last"></mat-divider>
			</ng-container>
		</div>
	</div>
	<ng-template #noItems>
		<no-items-layout message="No items created yet"></no-items-layout>
	</ng-template>
</div>
