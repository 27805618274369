import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { MediaChange } from "ngx-flexible-layout";
import { Observable, Subject, forkJoin } from "rxjs";
import { debounceTime, map, mergeMap, switchMap, take, takeUntil } from "rxjs/operators";

import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { BreadcrumbsMenuRoutingService } from "@shared/components/breadcrumbs/services/breadcrumbs-menu-routing.service";
import { environment } from "@environments/environment";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { IDashboardFiltersFormGroup } from "@modules/global-dashboard/models/global-dashboard-interface";
import { UserState } from "@modules/user-management/_models/user-management-api.interface";
import { CountriesService } from "@shared/services/countries/countries.service";
import { CustomerService } from "@shared/services/customer/customer.service";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { LoaderService } from "@shared/services/loader/loader.service";
import { TabStateService } from "@shared/services/tab-state/tab-state.service";
import { UserRole } from "@shared/constants/roles";
import { Customer } from "@shared/models/customer.interface";
import { LegalEntity } from "@shared/models/legal-entity.interface";
import { Menu } from "@shared/models/menu.interface";
import { SelectOption } from "@shared/models/select-option.interface";
import { getCustomerLogoAndPrimaryColour } from "@shared/utils/customer-logo-path.util";
import { UpdateCustomerEntityGroupAction } from "@store/actions/customerEntityGroupSelect.action";
import { UpdateDashboardFilterAction } from "@store/actions/globalDashboardFilterSelect.action";
import { UpdateClientGlobalFilterEnable, UpdateUserMapPermission } from "@store/actions/userData.action";
import { getGlobalDashboardFilterState, getUserDataState } from "@store/index";
import { GlobalDashboardFilterSelect } from "@store/models/globalDashboardFilterSelection.model";
import { AppState } from "@store/models/state.model";
import { AuthorizationService } from "src/app/core/services/authorization-service";
import { CustomerInfoOnSideNav } from "./_models/dashboard.model";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";

@Component({
	selector: "app-dashboard-page",
	templateUrl: "./dashboard.page.html",
	styleUrls: ["./dashboard.page.scss"]
})
export class DashboardPage implements OnInit, OnDestroy {
	showPageLoadSpinner = false;
	version = "";
	sideNavOpen = true;
	menu!: Menu[];
	roles!: string[];
	bigScreen = false;
	hover = false;
	isMenuOpen = true;
	LOGIN_THRESHOLD_SECONDS = 10; // Adjust this value as needed
	tabId!: string;

	filters!: FormGroup;

	visibility$: Observable<boolean> = new Observable<boolean>();
	media$!: Observable<MediaChange[]>;
	private destroy$: Subject<void> = new Subject<void>();
	customerLogo!: CustomerInfoOnSideNav;
	cloudPayLogo = "../../../assets/logos/CPlogo-white.png";
	cloudPayClientLogo = "../../../assets/logos/CPlogo-client-white.png";
	user!: UserState;
	legalEntityCountryList: SelectOption[] = [];
	loadPage = false;
	isOnClientlandingPage = false;

	constructor(
		public menuService: MenuService,
		private loaderService: LoaderService,
		private router: Router,
		private store: Store<AppState>,
		private tabStateService: TabStateService,
		private breadCrumbsMenuRoutingService: BreadcrumbsMenuRoutingService,
		private customerService: CustomerService,
		private authorizationService: AuthorizationService,
		private legalEntityService: LegalEntityService,
		private countriesService: CountriesService,
		private formBuilder: FormBuilder,
		private paygroupService: PaygroupsService
	) {
		this.init();
	}

	ngOnInit(): void {
		this.onLoaderActiveChangeUpdateSpinnerVisibility();
	}

	init(): void {
		this.authorizationService.login().subscribe(_ => {
			this.authorizationService.isJustLoggedIn().subscribe();
		});

		this.authorizationService.watchWhenSessionExpired();

		this.store.pipe(takeUntil(this.destroy$), select(getUserDataState)).subscribe(user => {
			if (user?.email && this.user?.email !== user?.email) {
				this.user = user;
				this.roles = user.roles!;
				this.saveInLocalStorageWhenJustLoggedIn();

				if (user.roles?.includes(UserRole.CLIENT)) this.setupFiltersForm();
			}
		});

		this.version = environment.appVersion;
	}

	private setupFiltersForm(): void {
		this.filters = this.formBuilder.group({
			customers: new FormControl([]),
			legalEntities: new FormControl([]),
			paygroups: new FormControl([]),
			statuses: new FormControl([]),
			groups: new FormControl([]),
			deadline: new FormControl(""),
			milestoneTypes: new FormControl([])
		}) as IDashboardFiltersFormGroup;
	}

	private saveInLocalStorageWhenJustLoggedIn(): void {
		this.roles.includes(UserRole.CLIENT) ? this.setupInitialState() : this.setMapPermissionAndSetupMenu(false);
	}

	private setupInitialState(): void {
		this.customerService
			.getAllCustomers()
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe({
				next: (res: Customer[]) => {
					this.setupCustomerState(res);
				}
			});
	}

	private setupCustomerState(customers: Customer[]): void {
		if (customers.length) {
			const customer: Customer = customers[0];
			const primaryColor = getCustomerLogoAndPrimaryColour(customer)
				.pipe(take(1), takeUntil(this.destroy$))
				.subscribe({
					next: customerInfo => {
						this.customerLogo = customerInfo;
					}
				});

			let customerEntity = new UpdateCustomerEntityGroupAction({
				customerId: customer.id,
				legalEntityId: "",
				payGroupId: "",
				customerName: customer.name
			});
			this.store.dispatch(customerEntity);
			this.populateGlobalDashboardFiltersStore(customer.id, []);

			this.getMapPermission(customer.id)
				.pipe(takeUntil(this.destroy$))
				.subscribe(canSeeMap => {
					this.setMapPermissionAndSetupMenu(canSeeMap);
				});
		}
	}

	private getMapPermission(customerId: string): Observable<boolean> {
		return forkJoin([
			this.legalEntityService.getLegalEntitiesForCustomer(customerId),
			this.paygroupService.getPayGroupWithCustomerIdForClient(customerId)
		]).pipe(
			take(1),
			switchMap(([entities, paygroups]) => {
				let legalEntities: LegalEntity[] = [];

				entities.items.forEach((entity: any) => {
					paygroups.items.forEach((paygroup: any) => {
						if (entity.data.country === paygroup.legalEntity.data.country) {
							if (entity.id === paygroup.legalEntity.id || legalEntities.length === 0) {
								legalEntities.push(entity);
							}
						}
					});
				});

				legalEntities = [...new Set(legalEntities)];

				return this.fetchListOfCountries(legalEntities).pipe(
					map(listOfCountries => {
						return legalEntities.length > 1 && listOfCountries.length > 1;
					})
				);
			})
		);
	}

	private fetchListOfCountries(legalEntities: LegalEntity[]): Observable<SelectOption[]> {
		return this.countriesService.getCountries().pipe(
			take(1),
			takeUntil(this.destroy$),
			map(countries => {
				this.legalEntityCountryList = [];
				legalEntities.forEach(legalEntity => {
					const existingIndex: number = this.legalEntityCountryList.findIndex(
						(country: SelectOption) => country.value === legalEntity.data.country
					);

					if (existingIndex === -1) {
						this.legalEntityCountryList.push(
							this.countriesService.filterCountryByCode(countries, legalEntity.data.country)
						);

						this.legalEntityCountryList[this.legalEntityCountryList.length - 1].valueArray = [
							legalEntity.id
						];
					} else {
						this.legalEntityCountryList[existingIndex].valueArray?.push(legalEntity.id);
					}
				});

				return this.legalEntityCountryList;
			})
		);
	}

	populateGlobalDashboardFiltersStore(customerId: string, legalEntities: string[], routeToGD?: boolean): void {
		this.store.pipe(take(1), takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe({
			next: (state: GlobalDashboardFilterSelect) => {
				if (state?.globalDashboard) {
					state?.globalDashboard?.filters
						? this.filters.patchValue(state.globalDashboard.filters)
						: this.filters.patchValue({} as IDashboardFiltersFormGroup);

					if (this.filters && customerId) this.filters.get("customers")?.patchValue([customerId]);
					if (this.filters && legalEntities.length)
						this.filters.get("legalEntities")?.patchValue(legalEntities);

					this.store.dispatch(
						new UpdateDashboardFilterAction({
							globalDashboard: {
								kpiSelected: state.globalDashboard.kpiSelected,
								filters: this.filters.getRawValue()
							}
						})
					);

					this.store.dispatch(
						new UpdateClientGlobalFilterEnable({
							userData: { isClientGlobalDashboard: this.filters.get("legalEntities")?.value.length === 0 }
						})
					);

					if (routeToGD) this.router.navigate(["/global-dashboard"]);
				}
			}
		});
	}

	//Route user to stored landing page

	private setMapPermissionAndSetupMenu(canSeeMap: boolean) {
		this.editMenuBasedOnUserRole(canSeeMap);

		this.store.dispatch(
			new UpdateUserMapPermission({
				userData: { canSeeMap }
			})
		);
	}

	private editMenuBasedOnUserRole(canSeeMap: boolean): void {
		this.menuService
			.editMenuBasedOnUserRole(this.roles, canSeeMap)
			.pipe(takeUntil(this.destroy$))
			.subscribe(menu => {
				this.isOnClientlandingPage = this.router.url === "/client-landing";
				this.menu = menu;
				this.breadCrumbsMenuRoutingService.setMenu(menu);
				this.navigateUserToDestination();
			});
	}

	private navigateUserToDestination(): void {
		if (
			this.tabStateService.getActiveRoute() === "" ||
			!this.hasPermissionToViewRoute(this.tabStateService.getActiveRoute())
		) {
			this.tabStateService.setActiveRoute(this.getInitialRoute(this.getFirstAvailibleMenuItem()));
		} else {
			this.menuService.configurateMenu(this.tabStateService.getActiveRoute());
			this.router.navigate([this.tabStateService.getActiveRoute()]);
			this.loadPage = true;
		}
	}

	private getInitialRoute(firstAvailibleMenuItem: Menu): string {
		const initialRoute: string = firstAvailibleMenuItem?.sections![0].navigations[0].url;
		this.menuService.configurateMenu(initialRoute);
		this.router.navigate([initialRoute]);
		this.loadPage = true;
		return initialRoute;
	}

	private getFirstAvailibleMenuItem(): Menu {
		return this.menu.filter(menu => menu.shouldShow === true)[0];
	}

	private hasPermissionToViewRoute(route: string): boolean {
		if (route === "") return false;
		for (const menuItem of this.menu) {
			if (this.hasPermissionInMenuItem(menuItem, route)) {
				return true;
			}
		}

		return false;
	}

	private hasPermissionInMenuItem(menuItem: Menu, route: string): boolean {
		if (menuItem.shouldShow) {
			for (const section of menuItem.sections) {
				for (const navigation of section.navigations) {
					if (route.includes(navigation.url)) {
						return true;
					}
				}
			}
		}

		return false;
	}

	private onLoaderActiveChangeUpdateSpinnerVisibility(): void {
		this.loaderService.visibility$.pipe(debounceTime(200)).subscribe((value: boolean) => {
			this.showPageLoadSpinner = value;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
