<div id="expansionPanelContainer-{{ index }}" class="mg-bt-panel">
	<mat-accordion>
		<mat-expansion-panel
			id="expansionPanel"
			[disabled]="panelDisabled"
			[expanded]="panelOpenState"
			(opened)="panelOpenState = true"
			(closed)="panelOpenState = false"
		>
			<mat-expansion-panel-header id="expansionPanelHeader">
				<mat-panel-title id="expansionPanelTitle">
					<b>{{ subServiceTitle !== "" ? subServiceTitle.toUpperCase() : "Sub Service Name" }} </b>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div [formGroup]="form" class="container-section" id="expansionPanelFormContainer">
				<div id="subServiceNameInputContainer" class="section-style flex flex-row">
					<input-wrapper label="Sub Service Name *" class="flex-1" style="--max-width: 30%">
						<input
							[ngClass]="{
								'field-error-input':
									form.controls['name'].invalid && form.controls['name'].errors?.empty
							}"
							id="SubServiceName"
							type="text"
							placeholder="Type sub service name"
							formControlName="name"
							[disabled]="_isEditService && !canEditService"
						/>
					</input-wrapper>

					<!--<div fxFlex="8" class="arrow-container">
						<div class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
							<div class="arrow-line mg-tp" fxFlex="30"></div>
							<div class="arrow-right"></div>
						</div>
					</div>-->

					<input-wrapper label="{{ subIDLabel }}" class="flex-1" style="--max-width: 30%">
						<input
							[ngClass]="{
								'field-error-input':
									form.controls['externalId'].invalid && form.controls['externalId'].errors?.empty
							}"
							id="SubServiceId"
							type="text"
							placeholder="Type sub service Id"
							formControlName="externalId"
							[disabled]="_isEditService && !canEditService"
						/>
					</input-wrapper>

					<!--<div fxFlex="8" class="arrow-container">
						<div class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
							<div class="arrow-line mg-tp" fxFlex="30"></div>
							<div class="arrow-right"></div>
						</div>
					</div>-->
					<div id="beneficiaryInputContainer" class="flex-1" style="--max-width: 30%">
						<input-wrapper label="{{ beneficiaryLabel }}">
							<input-autocomplete
								id="beneficiaryName"
								formControlName="beneficiaryName"
								[ngClass]="{
									'field-error':
										form.controls['beneficiaryName'].invalid &&
										form.controls['beneficiaryName'].errors?.empty
								}"
								label="Select beneficiary"
								[options]="beneficiarySelectOptions"
								[disabled]="_isEditService && !canEditService"
								#beneficairyAccount
							>
							</input-autocomplete>

							<button
								*ngIf="!_isEditService"
								id="addNewBeneficiaryButton-{{ index }}"
								mat-button
								class="bt-new-beneficiary no-hover-effect"
								(click)="addNewBeneficiary()"
								[disabled]="panelDisabled"
							>
								+&nbsp;&nbsp;&nbsp;Add new beneficiary
							</button>

							<ng-container *ngIf="_isEditService">
								<ng-template
									[appUserRolePermissions]
									[action]="'hide'"
									[roles]="[
										'TS_IMPLEMENTATION_CONSULTANT',
										'TS_IMPLEMENTATION_LEAD',
										'TS_OPERATION_LEAD',
										'TS_VO',
										'TS_OCB',
										'TS_OPERATION_CONSULTANT',
										'CLIENT'
									]"
								>
									<button
										id="addNewBeneficiaryButton-{{ index }}"
										mat-button
										class="bt-new-beneficiary no-hover-effect"
										(click)="addNewBeneficiary()"
										[disabled]="panelDisabled || !canAddBeneficiary"
									>
										+&nbsp;&nbsp;&nbsp;Add new beneficiary
									</button>
								</ng-template>
							</ng-container>
						</input-wrapper>
					</div>
					<!--<div fxFlex="8" *ngIf="forAdhocService" class="arrow-container">
						<div class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
							<div class="arrow-line mg-tp" fxFlex="30"></div>
							<div class="arrow-right"></div>
						</div>
					</div>-->
				</div>
				<div id="beneficiaryInputsContainerRow" class="section-style mg-top flex flex-row">
					<input-wrapper
						label="Payment By *"
						[ngClass]="{ thinner: forAdhocService }"
						class="flex-1"
						style="--max-width: 30%"
					>
						<input-select
							id="paymentOriginator"
							[ngClass]="{
								'field-error':
									form.controls['paymentOriginator'].invalid &&
									form.controls['paymentOriginator'].errors?.empty
							}"
							formControlName="paymentOriginator"
							[label]="'Select payment originator'"
							[options]="tppPaymentOriginator"
							[icon]="'dollar-square'"
							[_disabled]="_isEditService && !canEditService"
						>
						</input-select>
					</input-wrapper>
					<!--<div fxFlex="14" class="arrow-container">
						<div class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
							<div class="arrow-line" fxFlex="50"></div>
							<div class="arrow-right"></div>
						</div>
					</div>-->
					<input-wrapper
						label="Reference Type *"
						class="flex-1"
						style="--max-width: 30%"
						[ngClass]="{ thinner: forAdhocService }"
					>
						<input-select
							id="referenceType"
							[ngClass]="{
								'field-error':
									form.controls['referenceType'].invalid &&
									form.controls['referenceType'].errors?.empty
							}"
							formControlName="referenceType"
							[label]="'Select referenceType'"
							[options]="tppReferenceType"
							[icon]="'document-text'"
							[_disabled]="_isEditService && !canEditService"
						>
						</input-select>
					</input-wrapper>
					<!--<div fxFlex="14" class="arrow-container">
						<div class="arrow-container-child" fxLayout="row" fxLayoutAlign="center none">
							<div class="arrow-line" fxFlex="50"></div>
							<div class="arrow-right"></div>
						</div>
					</div>-->
					<input-wrapper label="Frequency *" class="flex-1" style="--max-width: 30%" *ngIf="!forAdhocService">
						<input-select
							id="frequency"
							[ngClass]="{
								'field-error':
									form.controls['frequency'].invalid && form.controls['frequency'].errors?.empty
							}"
							formControlName="frequency"
							[label]="'Select a Frequency'"
							[options]="tppFrequencyOptions"
							[_disabled]="_isEditService && !canEditService"
						>
						</input-select>
					</input-wrapper>

					<input-wrapper label="" class="flex-1" style="--max-width: 30%" *ngIf="forAdhocService">
					</input-wrapper>
				</div>

				<div
					id="frequencyInputsContainerRow"
					class="payment-info-section flex flex-column"
					*ngIf="forAdhocService && paygroupFrequency"
				>
					<span class="paygroup-freq"
						>Paygroup Frequency: <span class="freq">{{ paygroupFrequency }}</span>
					</span>

					<div fxLayout="row" class="payment-inputs" *ngIf="forAdhocService">
						<input-wrapper label="Frequency *" class="payment-input flex-1" style="--max-width: 30%">
							<input-select
								id="frequency"
								[ngClass]="{
									'field-error':
										form.controls['frequency'].invalid && form.controls['frequency'].errors?.empty
								}"
								formControlName="frequency"
								[label]="'Frequency'"
								[options]="tppFrequencyOptions"
								[_disabled]="_isEditService && !canEditService"
							>
							</input-select>
						</input-wrapper>

						<input-wrapper
							label="Managed In"
							class="payment-input flex-1"
							style="--max-width: 30%"
							*ngIf="forAdhocService"
						>
							<input-autocomplete
								id="managedIn"
								[ngClass]="{
									'field-error':
										form.controls['managed_in'].invalid && form.controls['managed_in'].errors?.empty
								}"
								formControlName="managed_in"
								label="Select run"
								[disabled]="!managedInEnabled || (_isEditService && !canEditService)"
								[options]="managedInSelectOptions"
								[required]="true"
								[defaultValue]="selectedManagedIn"
							>
							</input-autocomplete>
						</input-wrapper>

						<input-wrapper
							label="TPP GROUP *"
							class="group-input-wrapper payment-input flex-1"
							style="--max-width: 30%"
						>
							<div
								class="route-area flex flex-row"
								[ngClass]="{
									'route-area-disabled': panelDisabled || (_isEditService && !canEditService),
									'field-error-group':
										form.controls['group'].invalid && form.controls['group'].errors?.empty
								}"
								(click)="triggerDropDownButtonClick(); $event.stopPropagation()"
							>
								<div class="route-container flex flex-row flex-1" style="--max-width: 90%">
									<span *ngIf="!form.get('group')?.value" class="group-placeholder"
										>Select group</span
									>
									<div
										*ngFor="let tppGroup of selectedTPPGroupOptions; index as i"
										class="{{ containerClass }} flex flex-align-center-center"
									>
										<span class="{{ textClass }}">{{ tppGroup.text }}</span>
									</div>
								</div>
								<button
									class="drop-down-button flex-1"
									style="--max-width: 10%"
									[matMenuTriggerFor]="tppOptionsMenu"
									(menuClosed)="tppOptionsMenuList = false"
									#dropDownButton
									(click)="showList()"
									[disabled]="panelDisabled || (_isEditService && !canEditService)"
								>
									<app-icon
										[name]="'arrow-down'"
										[size]="'15'"
										[lineWidth]="'3px'"
										[ngClass]="{ 'up-arrow': tppOptionsMenuList }"
									></app-icon>
								</button>
							</div>
						</input-wrapper>
					</div>
				</div>

				<div id="deleteSubServiceButtonContainer" class="button-section flex flex-row">
					<ng-template
						[appUserRolePermissions]
						[action]="'show'"
						[roles]="[
							'TS_IMPLEMENTATION_CONSULTANT',
							'TS_IMPLEMENTATION_LEAD',
							'TS_OPERATION_CONSULTANT',
							'TS_OPERATION_LEAD',
							'TS_OCB',
							'TS_VO'
						]"
					>
						<button
							*ngIf="!this.isEditService && forAdhocService"
							[ngClass]="{ disabled: !subService }"
							id="deleteSubServiceButton"
							class="delete sub-buttons"
							[disabled]="!subService"
							(click)="deleteSubService()"
						>
							<span id="deleteSubServiceText" class="text">
								<app-icon *ngIf="forAdhocService" class="icon" name="trash" [size]="'18'"></app-icon>
								Delete subservice</span
							>
						</button>
					</ng-template>

					<ng-template
						[appUserRolePermissions]
						[action]="'hide'"
						[roles]="[
							'TS_IMPLEMENTATION_CONSULTANT',
							'TS_IMPLEMENTATION_LEAD',
							'TS_OPERATION_CONSULTANT',
							'TS_OPERATION_LEAD',
							'TS_OCB',
							'TS_VO',
							'CLIENT'
						]"
					>
						<button
							id="deleteSubServiceButton"
							class="delete sub-buttons"
							[ngClass]="{ disabled: !subService || !canDeleteSubService, catalogue: !forAdhocService }"
							[disabled]="!subService || !canDeleteSubService"
							(click)="deleteSubService()"
						>
							<span id="deleteSubServiceText" class="text">
								<app-icon *ngIf="forAdhocService" class="icon" name="trash" [size]="'18'"></app-icon>
								Delete subservice</span
							>
						</button>
					</ng-template>
					<button
						id="viewBeneficiaryButton"
						[disabled]="btEyeDisabled"
						[ngClass]="{ disabled: btEyeDisabled }"
						class="view sub-buttons"
						(click)="show()"
					>
						<span id="viewBeneficiaryText" class="text">
							<app-icon *ngIf="forAdhocService" class="icon" name="eye" [size]="'18'"></app-icon> View
							beneficiary</span
						>
					</button>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>

<div *ngIf="showEditBeneficiaryForm || showAddBeneficiaryForm">
	<app-tpp-add-beneficiary
		id="benForm-{{ index }}"
		[selectedCountry]="countryCode"
		[canEdit]="showAddBeneficiaryForm ? true : canEditBeneficiary"
		(newBeneficiary)="setNewBeneficiaryInformation($event)"
	></app-tpp-add-beneficiary>
</div>

<mat-menu #tppOptionsMenu class="tpp-options-menu" id="tppGroupOptionsMenu">
	<ng-template matMenuContent>
		<div class="flex flex-column flex-gap" style="--gap: 4px" id="tppGroupOptionsContainer">
			<div class="flex flex-row flex-gap" style="--gap: 4px" id="tppGroupOptionsTopRowContainer">
				<div
					id="group1Container"
					class="group-1-container flex flex-align-center-center"
					(click)="addSelection(tppGroupOptions[0])"
				>
					<span id="group1Text" class="group-1-text">{{ tppGroupOptions[0].text }}</span>
				</div>
				<div
					id="group2Container"
					class="group-2-container flex flex-align-center-center"
					(click)="addSelection(tppGroupOptions[1])"
				>
					<span id="group2Text" class="group-2-text">{{ tppGroupOptions[1].text }}</span>
				</div>
			</div>
			<div class="flex flex-row flex-gap" style="--gap: 4px" id="tppGroupOptionsBottomRowContainer">
				<div
					id="group3Container"
					class="group-3-container flex flex-align-center-center"
					(click)="addSelection(tppGroupOptions[2])"
				>
					<span id="group3Text" class="group-3-text">{{ tppGroupOptions[2].text }}</span>
				</div>
				<div
					id="group4Container"
					class="group-4-container flex flex-align-center-center"
					(click)="addSelection(tppGroupOptions[3])"
				>
					<span id="group4Text" class="group-4-text">{{ tppGroupOptions[3].text }}</span>
				</div>
			</div>
		</div>
	</ng-template>
</mat-menu>
