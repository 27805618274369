import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { formatISO } from "date-fns";
import { Observable, Subject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";

import { ToastService } from "@shared/services/toast/toast.service";
import {
	createEmployeeBankAccountDTO,
	PaymentType,
	PayMethodDetail,
	UpdateEmployeeBankAccountDTO
} from "../types/accounts";
import { PayElectiveBankAccountsApiService } from "./pay-elective-bank-accounts-api.service";
import { AppState } from "@store/models/state.model";
import { select, Store } from "@ngrx/store";
import { getCustomerEntityGroupState } from "../../../store/index";

@Injectable({
	providedIn: "root"
})
export class PayElectiveBankAccountsService implements OnDestroy {
	selectedAccounts$: EventEmitter<string> = new EventEmitter<string>();
	unSelectAccounts$: EventEmitter<string> = new EventEmitter<string>();
	reProcessComplete$: EventEmitter<boolean> = new EventEmitter<boolean>();
	reProcessCompleteProvider$: EventEmitter<string> = new EventEmitter<string>();
	close: EventEmitter<void> = new EventEmitter();
	refresh: EventEmitter<boolean> = new EventEmitter();

	private destroy$: Subject<void> = new Subject<void>();
	private selectedPaygrouId!: string;

	constructor(
		private apiService: PayElectiveBankAccountsApiService,
		private toastService: ToastService,
		private store: Store<AppState>
	) {
		this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState))
			.subscribe(({ payGroupId }) => (this.selectedPaygrouId = payGroupId));
	}

	updateEmployeeBankAccount(bankAccount: UpdateEmployeeBankAccountDTO): Observable<any> {
		return this.apiService.updateEmployeeBankAccount(bankAccount);
	}

	getEmployeePaymentMethods(employeeId: string): Observable<PayMethodDetail[]> {
		return this.apiService.getEmployeePaymentMethods(employeeId).pipe(
			takeUntil(this.destroy$),
			map(response => {
				return response.items;
			})
		);
	}

	deleteEmployeeBankAccount(employeeId: string, paymethodId: string): Observable<any> {
		return this.apiService.deleteEmployeeBankAccount(employeeId, paymethodId).pipe(
			map(response => {
				return response;
			})
		);
	}

	createNewBankAccount(
		newBankAccountForm: FormGroup,
		selectedEmployeeId: string,
		fields: Record<string, AbstractControl>,
		paymentMethodsArray: PayMethodDetail[],
		createToType: string
	): void {
		const formValue = newBankAccountForm.value;

		let allRemainingPaymentMethod;
		const accountObject: createEmployeeBankAccountDTO = {
			country: formValue.country.value,
			employeeId: selectedEmployeeId,
			effectiveFrom: formatISO(new Date(formValue.effectiveFrom), { representation: "date" }),
			effectiveTo: formValue.effectiveTo
				? formatISO(new Date(formValue.effectiveTo), { representation: "date" })
				: "",
			currency: formValue.currency,
			validateProviders: false,
			fields: []
		};

		console.log("create", accountObject);

		accountObject.paymentTypes = [];

		if (formValue.paymentTypes.net) accountObject.paymentTypes.push(PaymentType.NET);
		if (formValue.paymentTypes.tpp) accountObject.paymentTypes.push(PaymentType.TPP);
		if (formValue.paymentTypes.ewa) accountObject.paymentTypes.push(PaymentType.EWA);
		if (formValue.paymentTypes.fallback) accountObject.paymentTypes.push(PaymentType.FALLBACK);

		Object.keys(fields).forEach(key => {
			if (key === "bankName") {
				accountObject.bankName = fields[key].value;
			}
			if (
				key !== "bankName" &&
				fields[key].value !== null &&
				fields[key].value !== undefined &&
				fields[key].value !== ""
			) {
				accountObject.fields!.push({
					key: key,
					value: fields[key].value
				});
			}
		});

		let callAPIService = (): void => {
			this.apiService
				.createEmployeeBankAccount(accountObject)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: _ => {
						this.toastService.showSuccess("Account created");
						this.close.emit();
						this.refresh.emit(true);
					},
					error: error => {
						if (error.error.message === "Employee with more than one fallback pay method not allowed") {
							this.toastService.showError(error.error.message);
						} else {
							this.toastService.showError("Error creating account, please try again");
						}
					}
				});
		};

		if (createToType === "nets") {
			if (!paymentMethodsArray.length) {
				accountObject.ranking = {
					type: "ALL_REMAINING",
					amount: 0,
					rank: 0
				};
				callAPIService();
			} else {
				// if we do have other accounts ,find rank of all remaining and assign to this

				allRemainingPaymentMethod = paymentMethodsArray!.filter(function (obj) {
					return obj.payMethod.ranking?.type === "ALL_REMAINING";
				});

				accountObject.ranking = {
					type: formValue.rankingValueType,
					amount: formValue.rankingAmount,
					rank: allRemainingPaymentMethod[0].payMethod.ranking!.rank
				};

				callAPIService();
			}
		} else {
			accountObject.ranking = null;
			callAPIService();
		}
	}

	updateBankAccount(
		newBankAccountForm: FormGroup,
		bankAccountDetails: PayMethodDetail,
		payMethodsArray: PayMethodDetail[],
		updateTo: string,
		fields?: Record<string, AbstractControl>
	): void {
		const formValue = newBankAccountForm.getRawValue();

		let accountObject: UpdateEmployeeBankAccountDTO;

		if (fields) {
			accountObject = {
				id: bankAccountDetails?.payMethod?.id!, //get correct id
				version: bankAccountDetails?.payMethod.version!,
				paymentTypes: [],
				effectiveFrom: formatISO(new Date(formValue.effectiveFrom), { representation: "date" }),
				effectiveTo: formValue.effectiveTo
					? formatISO(new Date(formValue.effectiveTo), { representation: "date" })
					: "",
				bankAccount: {
					id: bankAccountDetails?.payMethod.accountId!,
					version: bankAccountDetails?.details.version!,
					bankName: "",
					country: formValue.country.value,
					currency: formValue.currency,
					fields: []
				}
			};

			Object.keys(fields).forEach(key => {
				if (key === "bankName") {
					accountObject.bankAccount!.bankName = fields[key].value;
				}
				if (
					key !== "bankName" &&
					fields[key].value !== null &&
					fields[key].value !== undefined &&
					fields[key].value !== ""
				) {
					accountObject.bankAccount!.fields!.push({
						key: key,
						value: fields[key].value
					});
				}
			});
		} else {
			accountObject = {
				id: bankAccountDetails?.payMethod?.id!, //get correct id
				version: bankAccountDetails?.payMethod.version!,
				paymentTypes: [],
				effectiveFrom: formatISO(new Date(formValue.effectiveFrom), { representation: "date" }),
				effectiveTo: formValue.effectiveTo
					? formatISO(new Date(formValue.effectiveTo), { representation: "date" })
					: ""
			};
		}

		if (formValue.paymentTypes.net) accountObject.paymentTypes!.push(PaymentType.NET);
		if (formValue.paymentTypes.tpp) accountObject.paymentTypes!.push(PaymentType.TPP);
		if (formValue.paymentTypes.ewa) accountObject.paymentTypes!.push(PaymentType.EWA);
		if (formValue.paymentTypes.fallback) accountObject.paymentTypes!.push(PaymentType.FALLBACK);

		if (updateTo === "invalid" || updateTo === "tpp" || updateTo === "ewa") {
			console.log("UPDATETO: ", updateTo);
			accountObject.ranking = null;
			// accountObject.ranking = {
			// 	type: formValue.rankingValueType,
			// 	amount: formValue.rankingAmount,
			// 	rank: formValue.rank - 1
			// };

			this.updateAccount(accountObject);
		}

		if (updateTo === "nets") {
			// if we already have rank we use the same rank on update
			if (formValue.rank) {
				accountObject.ranking = {
					type: formValue.rankingValueType,
					amount: formValue.rankingAmount,
					rank: formValue.rank - 1
				};
				this.updateAccount(accountObject);
			} else {
				// if we dont have a rank then this must come from being invalid or tpp
				if (payMethodsArray.length === 0) {
					// if this is our only account then it becomes all remaining
					accountObject.ranking = {
						type: "ALL_REMAINING",
						amount: 0,
						rank: 0
					};
					this.updateAccount(accountObject);
				} else {
					let allRemainingPaymentMethod: PayMethodDetail[];
					// if we do have other accounts ,find rank of all remaining and assign to this
					allRemainingPaymentMethod = payMethodsArray!.filter(function (obj) {
						return obj.payMethod.ranking?.type === "ALL_REMAINING";
					});

					//Update Rank of All Remaining in the back
					//Slot New Account in its previous place
					accountObject.ranking = {
						type: formValue.rankingValueType,
						amount: formValue.rankingAmount,
						rank: allRemainingPaymentMethod[0].payMethod.ranking!.rank
					};
					this.updateAccount(accountObject);
				}
			}
		}
	}

	updateAccount(accountObject: UpdateEmployeeBankAccountDTO): void {
		this.apiService.updateEmployeeBankAccount(accountObject).subscribe({
			next: _ => {
				this.toastService.showSuccess("Account updated successfully");
				this.close.emit();
				this.refresh.emit(true);
			},
			error: error => {
				if (error.error.message === "Employee with more than one fallback pay method not allowed") {
					this.toastService.showError(error.error.message);
				} else {
					this.toastService.showError("Error updating your account, please try again");
				}
			},
			complete: () => this.reProcessAll(this.selectedPaygrouId)
		});
	}

	vallidateFallBackChanges(newBankAccountForm: FormGroup, supportedPayMethodTypes: string[]): boolean {
		let enable: boolean = false;
		const formValue = newBankAccountForm.getRawValue();
		if (formValue.fallback) {
			if (supportedPayMethodTypes.includes("BANK")) enable = true;

			if (supportedPayMethodTypes.includes("CARD") && (formValue.paymentTypes.tpp || formValue.paymentTypes.ewa))
				enable = true;
		} else {
			enable = true;
		}

		return enable;
	}

	reProcessAccounts(payMethodIds: string[], provider: string) {
		this.apiService.reProcessEmployeeBankAccounts(payMethodIds).subscribe(res => {
			this.reProcessComplete$.emit(true);
			this.reProcessCompleteProvider$.next(provider);
		});
	}

	reProcessAll(payGroupId: string) {
		this.apiService
			.reProcessAll(payGroupId)
			.pipe(take(1))
			.subscribe(res => {
				this.toastService.showSuccess("All Failed Bank Accounts submitted for processing");
				this.reProcessComplete$.emit(true);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
