<ng-container *ngIf="popupOpen; else popupClosed">
	<milestone-edition-popup (onSave)="savePopup($event)"></milestone-edition-popup>
</ng-container>

<ng-template #popupClosed>
	<sliding-panel class="sliding-panel-payment-calendar-edition" [exit]="exit">
		<div class="top-section">
			<!-- Header -->
			<div class="header">
				<div class="title-section">
					<div
						class="dot"
						[ngClass]="{
							tpp1: _milestone!.group === 'TPP1',
							tpp2: _milestone!.group === 'TPP2',
							tpp3: _milestone!.group === 'TPP3',
							tpp4: _milestone!.group === 'TPP4',
							net: _milestone!.group === 'NETS'
						}"
					></div>
					<div class="text">
						<span *ngIf="_milestone!.type !== 'NETS'">GROUP</span> {{ _milestone!.group }} -
						{{ _milestone! | milestoneTypes | uppercase }}
					</div>
				</div>

				<div class="cta-container">
					<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
						<div class="reset-button" id="resetButton" (click)="resetAll()">Reset all</div>
					</ng-template>
					<div class="close-button" id="closeButton" (click)="cancel(false)">
						<app-icon
							[name]="'close'"
							[color]="'var(--color-text-default)'"
							[size]="'45'"
							[lineWidth]="'1px'"
						></app-icon>
					</div>
				</div>
			</div>

			<app-legal-entity-timezone-disclaimer
				*ngIf="milestoneDate"
				[milestoneDate]="milestoneDate"
				[payGroup]="payGroup"
			></app-legal-entity-timezone-disclaimer>

			<!-- Form -->
			<div class="form-container">
				<!-- Title -->
				<div class="title-form">Set a date</div>

				<!-- Inputs -->
				<div class="inputs-container o-grid-2 full-width" *ngIf="eventForm" [formGroup]="eventForm">
					<!-- Pay date offset -->
					<input-wrapper readonly [label]="_milestone?.type === 'NETS' ? 'Pay date' : 'Pay date offset'">
						<!--<mat-form-field appearance="outline" *ngIf="milestone?.type === 'NETS'" >	
						<mat-select-trigger matPrefix class="drop-down-trigger">
							<app-icon class="icon-payments" [name]="'calendar_month'" [color]="'var(--color-primary-1000)'"></app-icon>
						</mat-select-trigger>				
						<mat-select>						
							<mat-option *ngFor="let date of payDates" [value]="date">
								{{ date }}
							</mat-option>
						</mat-select>					
					</mat-form-field>-->

						<mat-form-field appearance="outline" (click)="picker.open()">
							<mat-datepicker-toggle matPrefix [for]="picker">
								<app-icon
									matDatepickerToggleIcon
									class="icon-payments"
									[name]="'calendar_month'"
									[color]="'var(--color-primary-1000)'"
								></app-icon>
							</mat-datepicker-toggle>
							<input id="date" formControlName="date" matInput [matDatepicker]="picker" readonly />
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>

						<div input-errors *ngIf="eventForm.controls.date.errors?.required">Event time is required</div>
					</input-wrapper>

					<!-- Event time -->
					<input-wrapper label="Event time">
						<div class="div-time-container" fxLayout="row">
							<app-icon
								class="icon-time"
								[name]="'clock-1'"
								[color]="'var(--color-primary-1000)'"
							></app-icon>

							<input id="time" type="time" formControlName="time" placeholder="Event time" />
						</div>

						<!--<mat-form-field appearance="outline" *ngIf="milestone?.type === 'NETS'" >	
							<mat-select-trigger matPrefix class="drop-down-trigger">
								<app-icon class="icon-payments" [name]="'clock-1'" [color]="'var(--color-primary-1000)'"></app-icon>
							</mat-select-trigger>				
							<mat-select>						
								<mat-option *ngFor="let event of eventTimes" [value]="event">
									{{ event }}
								</mat-option>
							</mat-select>					
						</mat-form-field>
					-->

						<div input-errors *ngIf="eventForm.controls.time.errors?.required">Event time is required</div>
					</input-wrapper>

					<!--	<input-wrapper label="Weekend offset" >										

					<mat-form-field appearance="outline" >	
						<mat-select-trigger matPrefix class="drop-down-trigger">
							<app-icon class="icon-payments" [name]="'calendar_1'" [color]="'var(--color-primary-1000)'"></app-icon>
						</mat-select-trigger>				
						<mat-select>						
							<mat-option *ngFor="let offset of weekendOffets" [value]="offset">
								{{ offset }}
							</mat-option>
						</mat-select>
						<mat-select-trigger matSuffix class="drop-down-trigger-offset">							
							<app-icon id="drop-down-arrow-time"  [color]="'rgba(0, 0, 0, 0.54)'" [lineWidth]="'2.5'" [name]="'arrow-down'" [size]="'16'"></app-icon>
						</mat-select-trigger>
					</mat-form-field>

					<div input-errors *ngIf="eventForm.controls.time.errors?.required">Event time is required</div>
				</input-wrapper>-->
				</div>
			</div>
		</div>

		<!-- CTA -->
		<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
			<div class="cta-container">
				<button
					id="cancelButton"
					class="cancel-button"
					action-buttons
					mat-stroked-button
					(click)="cancel(false)"
				>
					Cancel
				</button>
				<button
					id="saveButton"
					class="save-button"
					action-buttons
					mat-flat-button
					color="dark"
					(click)="save()"
					[disabled]="!eventForm?.valid || !canEdit"
				>
					Save
				</button>
			</div>
		</ng-template>
	</sliding-panel>
</ng-template>
