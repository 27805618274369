<div class="sticky-header">
	<div #sticky class="sticky-header-content">
		<div class="sticky-header-title-container">
			<app-icon
				id="exit-arrow"
				[lineWidth]="'4'"
				name="exit-back-arrow"
				class="exit-arrow"
				[color]="'white'"
				(click)="regress()"
			></app-icon>

			<div class="sticky-header-title-container-column" (click)="regress()">
				<span class="xl-section-name">
					<span class="sub-title-flag">
						<img src="assets/svg-country-flags/svg/{{ flag.toLowerCase() }}.svg" /></span
					>{{ subTitle }}</span
				>
				<span class="xs-header-breadcrumb sticky-header-text">{{ title }}</span>
			</div>
		</div>
		<div class="sticky-header-exit-container">
			<span>
				<app-icon
					id="exit-button"
					name="exit-button"
					[lineWidth]="'4'"
					class="back-btn"
					[color]="'white'"
					(click)="regress()"
				></app-icon>
			</span>
		</div>
	</div>

	<div #static class="info-box" fxLayout="row">
		<div
			class="info-container"
			fxLayout="column"
			fxLayoutGap="15px"
			fxLayout="row"
			[ngStyle]="{ 'min-width': minWidth + 'px', 'max-width': minWidth + 'px' }"
		>
			<div class="headcount-thumb">
				<app-icon
					[name]="'profile-2user'"
					[color]="'var(--color-text-default)'"
					[size]="'35'"
					[lineWidth]="'1.5px'"
				></app-icon>
			</div>

			<div fxLayout="column">
				<span class="title">{{
					expandMilestoneGroupWithName === "NETS" ? "Headcount" : "Subservice Count"
				}}</span>
				<div *ngIf="!updating && milestoneReport" fxLayout="row" class="value-container" fxLayoutGap="5">
					<span class="value" [ngPlural]="milestoneReport.totalHeadCount">
						{{ milestoneReport.totalHeadCount }}
						<ng-template ngPluralCase="=0">{{
							expandMilestoneGroupWithName === "NETS" ? "Employees" : "Subservices"
						}}</ng-template>
						<ng-template ngPluralCase="=1">{{
							expandMilestoneGroupWithName === "NETS" ? "Employee" : "Subservice"
						}}</ng-template>
						<ng-template ngPluralCase="other">{{
							expandMilestoneGroupWithName === "NETS" ? "Employees" : "Subservices"
						}}</ng-template>
					</span>
				</div>
				<span class="value" *ngIf="updating">Calculating...</span>
			</div>
		</div>
		<div
			class="info-container"
			fxLayout="column"
			fxLayoutGap="15px"
			fxLayout="row"
			[ngStyle]="{ 'min-width': minWidth + 'px', 'max-width': minWidth + 'px' }"
		>
			<div class="transactions-thumb">
				<app-icon
					[name]="'money-change'"
					[color]="'var(--color-text-default)'"
					[size]="'35'"
					[lineWidth]="'1.5px'"
				></app-icon>
			</div>
			<div fxLayout="column">
				<span class="title">Transactions</span>
				<div *ngIf="!updating && milestoneReport" fxLayout="row" class="value-container" fxLayoutGap="5">
					<span class="value"
						>{{ milestoneReport.totalTransactionCount }}

						<span class="value-failed" *ngIf="milestoneReport.failedTransactionCount > 0"
							>({{ milestoneReport.failedTransactionCount }} failed)</span
						>
					</span>
				</div>
				<span class="value" *ngIf="updating">Calculating...</span>
			</div>
		</div>
		<div class="info-container" fxLayout="column" fxLayoutGap="15px" fxLayout="row" #payment>
			<div class="payments-thumb">
				<app-icon
					[name]="'receipt-add'"
					[color]="'var(--color-text-default)'"
					[size]="'35'"
					[lineWidth]="'1.5px'"
				></app-icon>
			</div>
			<div fxLayout="column">
				<span class="title">Total Payments</span>
				<div fxLayout="row" fxLayoutGap="5">
					<div *ngIf="!updating && milestoneReport" fxLayout="row" class="value-container" fxLayoutGap="5">
						<span class="value"
							>{{ milestoneReport.totalPayoutAmount.value
							}}{{ milestoneReport.totalPayoutAmount.currency | currencySymbol }}</span
						>&nbsp;
						<span class="value-failed" *ngIf="milestoneReport.totalFailedAmount.value > 0">
							({{ milestoneReport.totalFailedAmount.value
							}}{{ milestoneReport.totalFailedAmount.currency | currencySymbol }})</span
						>
					</div>
					<span class="value" *ngIf="updating">Calculating...</span>
				</div>
			</div>
		</div>
	</div>
</div>
