import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-table-top-section",
	templateUrl: "./table-top-section.component.html",
	styleUrls: ["./table-top-section.component.scss"]
})
export class TableTopSectionComponent {
	@Input() set tableTitle(title: string) {
		this.title = title;
	}

	@Input() set firstOptionName(name: string) {
		this.firstOption = name;
	}

	@Input() set secondOptionName(name: string) {
		this.secondOption = name;
	}

	@Input() set showSlideButton(show: boolean) {
		this.showButton = show;
	}

	@Input() set tableIcon(icon: string) {
		this.icon = icon;
	}

	@Input() set selectedKPI(kpi: string) {
		this.kpi = kpi;
	}

	@Output() switchToCustomerTable: EventEmitter<boolean> = new EventEmitter<boolean>();

	firstOption!: string;
	secondOption!: string;
	kpi!: string;
	title: string = "";
	showButton: boolean = false;
	icon: string = "";

	showCustomerTable(show: number): void {
		this.switchToCustomerTable.emit(Boolean(show));
	}
}
