import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { ToastService } from "@shared/services/toast/toast.service";

@Injectable({
	providedIn: "root"
})
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(public toast: ToastService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 403) {
					this.toast.showError(
						"Your account has not been granted permissions. Please contact the administrator"
					);

					return throwError(
						() =>
							new Error("Your account has not been granted permissions. Please contact the administrator")
					);
				}

				if (error.status === 409) {
					console.log(error);
					this.toast.showError(error.error.message);
					// this.toast.showError(
					// 	"Error when saving. This is being modified by another user. Your changes will be lost"
					// );
				}

				if (error.status === 400 && error.error.code === "TaskNotCompleted") {
					return throwError(() => error);
				}

				if (error.error.message && (error.status !== 422 || 200)) {
					if (
						error.error.message.includes("TPP service definition not found") ||
						error.error.message.includes("'TPPServiceDefinition' is not found by id")
					) {
					} else {
						this.toast.showError(error.error.message);
					}
				}

				return throwError(() => error);
			})
		);
	}
}
