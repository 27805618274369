import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CardDetails } from "@modules/global-dashboard/models/global-dashboard-interface";

@Component({
	selector: "app-kpi-cards",
	templateUrl: "./kpi-cards.component.html",
	styleUrls: ["./kpi-cards.component.scss"]
})
export class KpiCardsComponent {
	@Input() set kpiCardsData(cards: CardDetails[]) {
		this.kpiCards = cards;
	}
	@Input() set selectedKpi(kpi: string) {
		this.kpiSelected = kpi;
	}

	@Input() isOnGlobalDashboard = false;

	showLoadingCards = true;

	@Input() set showLoader(load: boolean) {
		this.showLoadingCards = load;
	}

	@Output() kpiSelectedEvent: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("parent") slider!: ElementRef;

	kpiSelected: string = "";
	mouseDown = false;
	startX: any;
	scrollLeft: any;
	kpiCards!: CardDetails[];

	getKpiCardName(card: CardDetails): string {
		return Object.keys(card.kpiType)[0];
	}

	startDragging(e: any, flag: boolean): void {
		this.mouseDown = flag;
		this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
		this.scrollLeft = this.slider.nativeElement.scrollLeft;
	}
	stopDragging(e: any, flag: boolean): void {
		this.mouseDown = flag;
	}
	moveEvent(e: any): void {
		e.preventDefault();
		if (!this.mouseDown) {
			return;
		}
		const x = e.pageX - this.slider.nativeElement.offsetLeft;
		const scroll = x - this.startX;
		this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
	}
}
