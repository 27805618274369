<div class="header">
	<h3 class="dialog-text" fxFlex="95">HARD AML COMPLIANCE HISTORY LOG</h3>
	<app-icon
		id="master-close-icon"
		[name]="'close'"
		[size]="'70px'"
		[color]="'white'"
		[lineWidth]="'0.8px'"
		fxFlex="5"
		(click)="closeDialog()"
	></app-icon>
</div>
<div class="history-container">
	<app-legal-entity-aml-history [legalInteityId]="legalInteityId"></app-legal-entity-aml-history>
</div>
