<!-- Items -->
<div
	class="parent-container"
	[ngClass]="{ noPaymenthods: !payElective.payMethods!.length, selected: showAccounts }"
	[ngStyle]="{ zIndex: zIndex }"
>
	<div class="header" [ngClass]="{ 'is-active': showAccounts }" (click)="toggle(payElective)">
		<div
			[matTooltip]="payElective.externalId"
			class="employee-id table-row-item xs-title-2-bold"
			[ngClass]="{ 'show-title': showAccounts, 'no-show-title': !showAccounts }"
		>
			{{ payElective.externalId }}
		</div>
		<div
			id="{{ payElective.externalId + '_userName_payElective' }}"
			[matTooltip]="payElective.data.firstName + ' ' + payElective.data.lastName"
			class="employee-name table-row-item xs-title-2-bold"
			[ngClass]="{ bold: showAccounts }"
		>
			{{ payElective.data.firstName }} {{ payElective.data.lastName }}
		</div>
		<div class="badges table-row-item">
			<div
				id="{{ payElective.externalId + '_cards' }}"
				[ngClass]="{ noCard: !checkHasCards(payElective), 'dont-show': showAccounts }"
				class="badge-item badge-card"
			>
				<app-icon class="bank-card-icon" [name]="'card'"></app-icon>
			</div>
			<div
				id="{{ payElective.externalId + '_accountBalance' }}"
				[ngClass]="{ noBank: !checkHasAccounts(payElective), 'dont-show': showAccounts }"
				class="badge-item badge-bank"
			>
				<app-icon class="bank-icon" [name]="'bank-figma'" [lineWidth]="'2'"></app-icon>
			</div>
		</div>
		<div
			[matTooltip]="showStatus()"
			class="status table-row-item"
			id="{{ payElective.externalId + '_beneficiaryStatus' }}"
		>
			<span
				class="dot"
				*ngIf="!showAccounts && !countFailedBeneficaries()"
				[ngClass]="{
					active: showDot()
				}"
			>
			</span>
			<div *ngIf="!showAccounts" class="body-small-bold" [ngClass]="{ hasFailedBen: countFailedBeneficaries() }">
				{{ showStatus() | formatString }}
			</div>
		</div>
		<div class="view-details table-row-item">
			<button
				*ngIf="showAccounts || !checkHasPayMethods(payElective)"
				mat-button
				class="eye-con-button"
				(click)="selectElective()"
			>
				<app-icon id="{{ payElective.externalId + '_eyeIcon' }}" class="eye-con" [name]="'eye'"> </app-icon>
			</button>

			<app-icon
				class="show-account-drop-down"
				id="{{ payElective.externalId + '_KeyboardArrowDown' }}"
				*ngIf="checkHasPayMethods(payElective)"
				[name]="'arrow-down'"
				[size]="'20'"
				[lineWidth]="'3px'"
				[ngClass]="{ 'up-arrow': showAccounts }"
			>
			</app-icon>
		</div>
	</div>
	<div class="content" *ngIf="showAccounts">
		<app-pay-elective-accounts-list
			class="accounts"
			[providerForPayElective]="provider"
			[payElective]="payElective"
			(sendUpdatedPayMethods)="updatePaymethodData($event)"
		></app-pay-elective-accounts-list>
	</div>
</div>
