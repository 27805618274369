<app-breadcrumbs [headerTitle]="'DASHBOARD'" *ngIf="!isFromGlobalDashboard" />

<app-breadcrumbs
	*ngIf="isFromGlobalDashboard && payGroup && headerCrumbs"
	[headerTitleWithFlag]="headerCrumbs"
	[externalCrumbs]="externalCrumbs"
	[addOverviewTitle]="true"
/>

<app-kpi-cards
	*ngIf="isFromGlobalDashboard && payGroup"
	sub-header
	[showLoader]="(this.fetchingDataForFilters | async)!"
	[kpiCardsData]="kpiCards"
></app-kpi-cards>

<app-calendar
	*ngIf="payGroup"
	sub-header
	(onPayCycleChanges)="handlePayCycleChanged($event)"
	(onNoPayCyclesFound)="payGroupHasNoPayCycles($event)"
	[_payGroup]="payGroup"
></app-calendar>
<div class="payment-main-container">
	<div *ngIf="!isCalendarMilestonesLoading">
		<ng-container *ngIf="payCycle && milestones.length > 0 && milestoneGroups.length > 0">
			<div class="event-title" fxLayout="row" fxFlexAlign="space-between center">
				<span>Event details</span>
			</div>
			<ng-container *ngIf="payCycle && milestones && payGroup">
				<app-event-details-container
					*ngFor="let group of milestoneGroups"
					[payGroup]="payGroup"
					[payCycleSelected]="payCycle"
					[milestones]="milestones"
					[group]="group"
					[expandGroupWithName]="expandedMilestoneGroupNameFromHistory"
					[triggerResetPayoutsAsObservable]="triggerResetPayouts$.asObservable()"
					(onMilestoneGroupSelected)="setSelectedMilestoneGroupName($event)"
					(onUpdateMilestone)="updateMilestones($event)"
					(onUpdatePayCycle)="updatePayCycle($event)"
				></app-event-details-container>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="payCycle && !milestones.length">
			<ng-container>
				<div class="event-no-item">
					<div class="event-no-item-content">No Milestones have been created for the selected Period.</div>
				</div>
			</ng-container>
		</ng-container>

		<!-- No existing pay cycle message -->
		<ng-container *ngIf="!payCycle">
			<div class="event-no-item">
				<div class="event-no-item-content">No Pay Cycles have been created for the selected Period.</div>
			</div>
		</ng-container>
	</div>
</div>
