import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AppState } from "@store/models/state.model";
import { getGlobalDashboardFilterState } from "@store/index";
import { SetStatusAction } from "@store/actions/statusNetPayment.action";
import { PayGroup } from "@shared/models/pay-groups";
import { Milestone, PayCycle } from "@shared/models/pay-cycle.interface";
import { ToastService } from "@shared/services/toast/toast.service";
import { MenuService } from "@modules/config/_services/menu/menu.service";

@Component({
	selector: "app-net-payment-orders",
	templateUrl: "./net-payment-orders.component.html",
	styleUrls: ["./net-payment-orders.component.scss"]
})
export class NetPaymentOrdersComponent {
	payGroup!: PayGroup;
	payCycle!: PayCycle;
	payCycleId = "";
	payCycleMilestones!: Milestone[];

	milestone!: Milestone;

	kpiSelected: string = "";
	payCycleName = "";
	title = "NET Payment Orders / ";
	subtitle = "";
	milestoneGroup = "NETS";
	flag = "GP";

	isPaymentOrderMenuOpen = false;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private store: Store<AppState>,
		private router: Router,
		private menuService: MenuService,
		private toast: ToastService,
		private readonly location: Location
	) {}

	ngOnInit(): void {
		this.getSelectedKPI();
		this.menuService.setVisible(false);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getSelectedKPI(): void {
		this.store.pipe(takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe(state => {
			//if coming from dashboard failed transactions
			if (state?.globalDashboard?.kpiSelected !== undefined) {
				this.kpiSelected = state.globalDashboard.kpiSelected;
				if (state.globalDashboard.kpiSelected === "Failed Transactions") {
					this.title = "Failed transactions / ";
					this.store.dispatch(
						new SetStatusAction({
							statusPayment: ["FAILED,ATTEMPTS_LIMIT_EXCEEDED", "FAILED_TO_BOOK", "PROCESSING,PENDING"]
						})
					);
				}
				this.setHistory();
			} else {
				this.router.navigate(["/global-dashboard"]);
			}
		});
	}

	setHistory(): void {
		if (history?.state?.payCycleId && history?.state?.payGroup && history?.state?.milestone) {
			this.payGroup = history.state.payGroup;
			this.payCycle = history.state.payCycle;
			this.payCycleName = history.state.payCycle.name;
			this.payCycleId = history.state.payCycleId;
			this.payCycleMilestones = history.state.milestones;
			this.milestone = history.state.milestone;

			/* retrieves header data */
			if (this.payGroup?.data && this.payGroup?.legalEntity?.data && this.milestone?.group) {
				this.setupTopCardInfo();
			}

			/* calls milestone endpoint */
			/* calls table endpoint, then sets up table subscription */
		} else {
			this.location.historyGo(-1);
			this.toast.showError("Something went wrong while trying to load the page. Please try again later.");
		}
	}

	setupTopCardInfo(): void {
		this.title = this.title + this.payGroup.data.name;
		this.subtitle = `${this.payGroup.legalEntity.data.name} - ${this.payCycleName} - Group ${this.milestone.group}`;
		this.flag = this.payGroup.legalEntity.data.country;
		this.milestoneGroup = this.milestone.group;
	}

	goToFileHistory(): void {
		let route = this.router.url === "/global-dashboard/payment-orders" ? "global-dashboard" : "payments";

		this.router.navigate([`/${route}/file-history`], {
			state: {
				payGroup: this.payGroup,
				payCycleId: this.payCycle.id,
				payCycleExternalId: this.payCycle.externalId,
				payCycle: this.payCycle,
				milestone: this.milestone,
				payrollCycleId: this.payCycle.payrollCycleId
			}
		});
	}

	goBack(): void {
		let route = "payments";

		if (this.router.url.includes("global-dashboard")) route = "global-dashboard";

		if ((this.kpiSelected === "Failed Transactions" || this.kpiSelected === "") && route === "global-dashboard") {
			this.router.navigate([`/${route}`]);
		} else {
			this.router.navigate([`/${route}/dashboard`], {
				state: { expandMilestoneGroupWithName: this.milestoneGroup }
			});
		}
	}

	refreshPaymentOrders(): void {
		throw new Error("Method not implemented.");
	}
}
