<div [formGroup]="form" class="form" fxLayout="column" fxLayoutAlign="space-between">
	<div fxLayout="column wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
		<div fxLayout="row wrap" ngClass="o-grid-3" fxLayoutAlign="start center" fxLayoutGap="16px">
			<input-wrapper label="Employee ID *" class="employee-input-wrapper">
				<input type="text" id="employeeId" placeholder="employeeId" formControlName="externalId" />
			</input-wrapper>
			<input-wrapper label="First Name *" class="employee-input-wrapper">
				<input type="text" id="firstName" placeholder="First Name" formControlName="firstName" />
			</input-wrapper>
			<input-wrapper label="Last Name *" class="employee-input-wrapper">
				<input type="text" id="lastName" placeholder="Last Name" formControlName="lastName" />
			</input-wrapper>
		</div>
		<div fxLayout="row wrap" ngClass="o-grid-3" fxLayoutAlign="start center" fxLayoutGap="16px">
			<input-wrapper label="Entity Reference" class="employee-input-wrapper" id="entity-reference">
				<input
					type="text"
					id="enityReference"
					placeholder="Entity Reference"
					formControlName="enityReference"
				/>
				<span class="add-config" fxLayoutAlign="end"> </span>
			</input-wrapper>

			<input-wrapper label="PayGroup ID" class="employee-input-wrapper">
				<input type="text" id="payRollId" formControlName="payGroupExternalId" />

				<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
					<span
						id="changePayGroup"
						(click)="onChangePayGroup()"
						*ngIf="payGroup.id"
						class="add-config"
						fxLayoutAlign="end"
					>
						<mat-icon class="select-icon">add</mat-icon>
						<span fxLayoutAlign="center">Change PG</span>
					</span>
				</ng-template>
			</input-wrapper>

			<input-wrapper label="Filler" class="employee-input-wrapper filler">
				<input type="text" />
			</input-wrapper>
		</div>
	</div>

	<div class="btn-container">
		<button id="cancel" class="cancel-btn" (click)="cancel()" action-buttons mat-stroked-button>Cancel</button>
		<button
			*ngIf="!isDisabled"
			id="submit"
			class="submit-btn"
			(click)="storePayElective()"
			action-buttons
			mat-flat-button
			[disabled]="!form.valid || form.pristine"
		>
			{{ isEdit ? "Update" : "Save" }}
		</button>
	</div>
</div>
