import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { SelectOption } from "src/app/shared/models/select-option.interface";
import { environment } from "src/environments/environment";
import { Role, User, UserPagination } from "../_models/user-management-api.interface";

@Injectable({
	providedIn: "root"
})
export class UserService {
	private _roles!: string[];
	private rolesList!: Role[];

	constructor(private http: HttpClient) {
		this.getRoles();
	}

	/** Roles **/
	public get roles(): string[] {
		return this._roles;
	}

	getUserById(id: string): Observable<User> {
		return this.http.get<User>(`${environment.apiUri}/v1/users/${id}`);
	}

	getRoles() {
		this.http
			.get<Role[]>(`${environment.apiUri}/v1/roles`)
			.pipe(take(1))
			.subscribe((items: Role[]) => {
				this.rolesList = items;
				this._roles = [];
				items.filter((item: Role) => {
					this._roles.push(item.name);
				});
			});
	}

	setIconColor(roles: SelectOption[]) {
		let colors: string[] = [];
		roles.forEach((role: SelectOption) => {
			switch ("colors-rol-" + role.value) {
				case "colors-rol-CS_ADMIN":
					colors.push("#596157");
					break;
				case "colors-rol-EMPLOYEE":
					colors.push("#A09ABC");
					break;
				case "colors-rol-IMPLEMENTATION_CONSULTANT":
					colors.push("#171738");
					break;
				case "colors-rol-SUPER_ADMIN":
					colors.push("#485C6C");
					break;
				case "colors-rol-TS_ADMIN":
					colors.push("#004AD7");
					break;
				case "colors-rol-TS_IMPLEMENTATION_CONSULTANT":
					colors.push("#B95000");
					break;
				case "colors-rol-TS_OPERATION_MANAGER":
					colors.push("#CE9400");
					break;
				case "colors-rol-TS_OPERATION_CONSULTANT":
					colors.push("#B95000");
					break;
				case "colors-rol-TS_IMPLEMENTATION_LEAD":
					colors.push("#767676");
					break;
				case "colors-rol-TS_OPERATION_LEAD":
					colors.push("#B95000");
					break;
				case "colors-rol-CLIENT":
					colors.push("#766C7F");
					break;
				case "colors-rol-TS_OCB":
					colors.push("#323E34 ");
					break;
				case "colors-rol-TS_VO":
					colors.push("#2A439C");
					break;
			}
		});
		return colors;
	}

	setRolesModelData(rolesOptions: SelectOption[]): string[] {
		let roles: string[] = [];
		this._roles.forEach((role: string) => {
			rolesOptions.forEach((roleOption: SelectOption) => {
				if (role == roleOption.value) roles.push(role);
			});
		});

		return roles;
	}

	/** Users **/
	getUsersList(pageSize: string = "", pageNumber: string = "", name: string = ""): Observable<UserPagination> {
		let params = new HttpParams();

		if (pageSize != "") params = params.append("pageSize", pageSize);
		if (pageNumber != "") params = params.append("pageNumber", pageNumber);
		if (name) params = params.append("queryString", name);

		return this.getUsers(params);
	}

	filterUsers(
		pageSize: string = "",
		pageNumber: string = "",
		roleNames: string | null,
		status: string | null,
		name: string | null
	): Observable<UserPagination> {
		let params = new HttpParams();

		if (pageSize != "") params = params.append("pageSize", pageSize);
		if (pageNumber != "") params = params.append("pageNumber", pageNumber);
		if (roleNames) params = params.append("roleNames", roleNames);
		if (status) params = params.append("active", status);
		if (name) params = params.append("queryString", name);

		return this.getUsers(params);
	}

	getUsers(params: HttpParams): Observable<UserPagination> {
		return this.http.get<UserPagination>(`${environment.apiUri}/v1/users`, { params }).pipe(
			map((resp: UserPagination) => {
				resp.items = resp.items.filter((item: User) => {
					if (item.type == "USER") return true;
					else return false;
				});
				return resp;
			})
		);
	}

	getUsersByEmail(email: string): Observable<UserPagination> {
		let encodedEmail = encodeURIComponent(email);
		return this.http.get<UserPagination>(`${environment.apiUri}/v1/users?queryString=${encodedEmail}`).pipe(
			map((resp: UserPagination) => {
				resp.items = resp.items.filter((item: User) => {
					if (item.type == "USER") return true;
					else return false;
				});
				return resp;
			})
		);
	}

	createNewUser(user: User): Observable<User> {
		user.roleIds = this.getListRoleIDs(user.roles);
		if (!user.phoneNumber) delete user.phoneNumber;

		return this.http.post<User>(`${environment.apiUri}/v1/users`, user);
	}

	getListRoleIDs(rolesList: string[]): string[] {
		let rolesIdList: string[] = [];

		rolesList.forEach((role: string) => {
			this.rolesList.forEach((item: Role) => {
				if (item.name == role) rolesIdList.push(item.id);
			});
		});
		return rolesIdList;
	}

	updateUser(updateUser: User): Observable<User> {
		return this.http.patch<User>(`${environment.apiUri}/v1/users/${updateUser.id}`, updateUser);
	}

	changeUserStatus(user: User): Observable<User> {
		console.log(user.blocked);
		const path = user.blocked ? "inactivate" : "activate";
		return this.http.patch<User>(`${environment.apiUri}/v1/users/${user.id}/${path}`, null);
	}

	getUsersReport(): Observable<Blob> {
		return this.http.get<Blob>(`${environment.apiUri}/v1/users/audit`, {
			observe: "body",
			responseType: "text" as "json"
		});
	}
}
