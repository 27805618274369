<div id="wpay-breadcrumb-modal" class="drop-down-container" style="--z-index: {{ zIndex }}">
	<div *ngIf="idKey === 'mainMenu'">
		<ng-container *ngFor="let main of dropDownDataMain.mainMenu; let last = last">
			<div
				class="item"
				*ngIf="main.name !== 'Configuration'"
				(click)="navigateToMainMenuRoute(main.name, main.id)"
			>
				<span class="item-text">{{ main.name }}</span>
			</div>

			<div class="mat-divider-container" *ngIf="!last">
				<mat-divider *ngIf="!last" style="width: 95%; align-self: center" />
			</div>
		</ng-container>
	</div>

	<div *ngIf="idKey === 'countryMenu'">
		<ng-container *ngFor="let country of clientCountryList; let last = last">
			<div class="item" (click)="selectClientLegalEntity(country.text!, country.valueArray!)">
				<span class="item-text">{{ country.text }}</span>
			</div>
			<div class="mat-divider-container" *ngIf="!last">
				<mat-divider *ngIf="!last" style="width: 95%; align-self: center" />
			</div>
		</ng-container>
	</div>

	<div *ngIf="idKey === 'customerName'">
		<div class="search-container" [formGroup]="inputForm" fxLayout="row" fxLayoutAlign="start start">
			<app-icon matPrefix [name]="'search-normal-1'" [size]="'15'" class="search-icon" fxFlex="5"></app-icon>
			<input
				matInput
				placeholder="Search by customer"
				formControlName="customerSearch"
				class="input"
				fxFlex="95"
			/>
		</div>

		<div *ngIf="!dropDownData.customers?.length" class="item" class="item-text"><span>Loading...</span></div>

		<ng-container *ngFor="let customer of dropDownDataCustomersFilterCopy; let last = last">
			<div
				class="item"
				[matTooltip]="customer.externalId! + ' - ' + customer.name!"
				[matTooltipPosition]="'after'"
				[matTooltipClass]="customer.externalId ? 'tooltip' : 'tool-noDisplay'"
				(click)="changeCustomerEntityGroup('customerId', customer.id)"
			>
				<span class="item-text">{{ customer.externalId + " - " + customer.name }}</span>
			</div>
			<div class="mat-divider-container" *ngIf="!last">
				<mat-divider *ngIf="!last" style="width: 95%" />
			</div>
		</ng-container>
	</div>

	<div *ngIf="idKey === 'legalEntityId'">
		<div class="search-container" [formGroup]="inputForm" fxLayout="row" fxLayoutAlign="start start">
			<app-icon matPrefix [name]="'search-normal-1'" [size]="'15'" class="search-icon" fxFlex="5"></app-icon>
			<input
				matInput
				placeholder="Search by Legal Entity"
				formControlName="legalSearch"
				class="input"
				fxFlex="95"
			/>
		</div>

		<ng-container *ngFor="let legalEntity of dropDownDataLegalEntitiesFilterCopy; let last = last">
			<div class="item" (click)="changeCustomerEntityGroup('legalEntityId', legalEntity.id)">
				<span class="item-text">{{ legalEntity.externalId + " - " + legalEntity.name }}</span>
			</div>
			<div class="mat-divider-container" *ngIf="!last">
				<mat-divider *ngIf="!last" style="width: 95%" />
			</div>
		</ng-container>
	</div>

	<div *ngIf="idKey === 'payGroupId'">
		<div class="search-container" [formGroup]="inputForm" fxLayout="row" fxLayoutAlign="start start">
			<app-icon matPrefix [name]="'search-normal-1'" [size]="'15'" class="search-icon" fxFlex="5"></app-icon>
			<input
				matInput
				placeholder="Search by Pay Group"
				formControlName="payGroupSearch"
				class="input"
				fxFlex="95"
			/>
		</div>

		<ng-container *ngFor="let payGroup of dropDownDataPayGroupsFilterCopy; let last = last">
			<div
				class="item"
				[matTooltip]="payGroup.externalId! + ' - ' + payGroup.name!"
				[matTooltipPosition]="'after'"
				[matTooltipClass]="payGroup.externalId ? 'tooltip' : 'tool-noDisplay'"
				(click)="changeCustomerEntityGroup('payGroupId', payGroup.id)"
			>
				<span class="item-text">{{ payGroup.externalId + " - " + payGroup.name }}</span>
			</div>
			<div class="mat-divider-container" *ngIf="!last">
				<mat-divider *ngIf="!last" style="width: 95%" />
			</div>
		</ng-container>
	</div>

	<div *ngIf="idKey === undefined">
		<div class="search-container" [formGroup]="inputForm" fxLayout="row" fxLayoutAlign="start start">
			<app-icon matPrefix [name]="'search-normal-1'" [size]="'15'" class="search-icon" fxFlex="5"></app-icon>
			<input matInput placeholder="Search" formControlName="externalSearch" class="input" fxFlex="95" />
		</div>
		<ng-container *ngFor="let list of externalDropDownDataFilterCopy; let last = last">
			<div class="item" (click)="externalListItemSelected(list.id)">
				<span class="item-text">
					<span *ngIf="list.externalId">{{ list.externalId }} - </span>
					{{ list.name }}</span
				>
			</div>
			<div class="mat-divider-container" *ngIf="!last">
				<mat-divider *ngIf="!last" style="width: 95%" />
			</div>
		</ng-container>
	</div>
</div>
