import { Location } from "@angular/common";
import {
	AfterViewChecked,
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { PaygroupsService } from "@shared/services/paygroups/paygroups.service";
import { TabStateService } from "@shared/services/tab-state/tab-state.service";
import { UserRole } from "@shared/constants/roles";
import { Observable, Subject, of } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import {
	BreadCrumb,
	DropDownData,
	DropDownDataExpanded,
	ExternalCrumb,
	IDBreadCrumb,
	breadCrumbButtonIcon
} from "src/app/shared/models/breadcrumbs.interface";
import { getCustomerEntityGroupState, getGlobalDashboardFilterState, getUserDataState } from "src/app/store";
import { CustomerEntityGroupSelect } from "src/app/store/models/customerEnitityGroupSelection.model";
import { AppState } from "src/app/store/models/state.model";
import { BreadcrumbService } from "../services/breadcrumb.service";
import { BreadcrumbsMenuRoutingService } from "../services/breadcrumbs-menu-routing.service";
import { PermissionsService } from "@shared/services/permissions/permissions.service";
import { SelectOption } from "@shared/models/select-option.interface";
@Component({
	selector: "app-breadcrumbs",
	templateUrl: "./breadcrumbs.component.html",
	styleUrls: ["./breadcrumbs.component.scss"]
})
export class BreadcrumbsComponent implements OnInit, AfterViewInit, AfterViewChecked {
	@Input() headerTitle: string = "";
	@Input() headerTitleWithFlag!: string[];
	@Input() buttonText: string = "";
	@Input() buttonIconObj!: breadCrumbButtonIcon;
	@Input() showAllCrumbs: boolean = false;
	@Input() disableModals: boolean = false;
	@Input() externalCrumbs: ExternalCrumb[] = [];
	@Input() addOverviewTitle = false;
	@Input() lastCrumbClickable = false;
	@Input() addResponsiveness = false;
	@Input() gdHeaderFlag!: string;
	@Input() showSideNav = true;
	@Input() clientSelectedCountry = "";
	@Input() clientCountryList: SelectOption[] = [];
	@Input() clientCustomerId!: string;

	@Output() onExternalCrumbClicked = new EventEmitter<IDBreadCrumb | ExternalCrumb>();
	@Output() onRoutingCrumbClicked = new EventEmitter<boolean>();
	@Output() onHeaderButtonAction: EventEmitter<null> = new EventEmitter();
	@Output() onHeaderTitleClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	@Output() onDropDownCrumbItemSelected = new EventEmitter<boolean>();
	@Output() onExternalDropDownCrumbItemSelected = new EventEmitter<string>();

	@ViewChild("breadCrumbElementContainer") breadCrumbElementContainer!: ElementRef;
	@ViewChild("breadCrumbElementHeaderTitle") breadCrumbElementHeaderTitle!: ElementRef;
	@ViewChild("breadCrumbElementCustomer") breadCrumbElementCustomer!: ElementRef;
	@ViewChild("breadCrumbElementPaygroup") breadCrumbElementPaygroup!: ElementRef;

	@HostListener("window:resize", ["$event"])
	onResize(event: Event) {
		// Handle the resize event here
		this.adjustTextTruncation();
	}

	breadcrumbs: BreadCrumb[] = [];
	idBreadCrumbs: IDBreadCrumb[] = [];
	mainCrumbIsGrey: boolean = false;
	idCrumbIsGrey: boolean = true;
	loneCrumb: boolean = false;
	normalCrumbCount: number = 0;
	dropDownKey: string = "";
	dropDownData!: DropDownData;
	modalOpen: boolean = false;
	modalOpenPosition!: { x: number; y: number };
	selectedCountry: string = "";
	customerExternalId: string = "";
	selectedExternalCrumbDropDownData!: DropDownDataExpanded[] | undefined;
	legalExternalId!: string;
	payGroupExternalId!: string;
	crumbsTextIsOverFlowing: boolean | undefined = false;
	fullyTruncateCustomerCrumb: boolean | undefined = false;
	disableHeaderRoute: boolean = false;
	displayGlobalDashboardHome = false;
	todaysDate = new Date();
	currentRoute!: string;
	isDashboardCalendarView = false;
	isDashboardPayGroupsListView = false;
	canSeeMap = false;
	dashboardCrumbIsBlue = false;
	isClientGlobalDashboard = false;

	hiddenIDCrumbsRoutes: string[] = [
		"/user-management",
		"/reporting",
		"/service-provider-setup",
		"/penny-test",
		"/tpp-catalogue",
		"/bank-fields",
		"/error-management",
		"/client-landing"
	];

	zIndex = 5;

	destroy$: Subject<void> = new Subject<void>();

	constructor(
		private menuService: MenuService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store<AppState>,
		private breadCrumbService: BreadcrumbService,
		private readonly _location: Location,
		private tabStateService: TabStateService,
		private payGroupsService: PaygroupsService,
		private legalEntityService: LegalEntityService,
		private breadCrumbsMenuRoutingService: BreadcrumbsMenuRoutingService,
		private permissionsService: PermissionsService
	) {}

	ngOnInit() {
		this.currentRoute = this.router.url;
		this.setupSubscriptions();
	}

	/**
	 * For dynamic display of country name selected in breadcrumb modal
	 * @param countryName: Client selected country name
	 */
	modalClientSelectedCountry(countryName: string): void {
		this.clientSelectedCountry = countryName;
	}

	setupSubscriptions(): void {
		this.permissionsService
			.canSeeMapAndIsClient()
			.pipe(takeUntil(this.destroy$))
			.subscribe((res: { isClient: boolean; canSeeMap: boolean }) => {
				this.canSeeMap = res.canSeeMap;
			});

		this.permissionsService
			.enableClientGlobalFilter()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.isClientGlobalDashboard = res.isClientGlobalDashboard!;
			});

		//User Roles
		this.store.pipe(select(getUserDataState)).subscribe({
			next: res => {
				if (res.roles?.includes(UserRole.CLIENT)) {
					this.disableHeaderRoute = true;
				}
			}
		});

		this.menuService.setVisible(this.showSideNav);

		// Generate breadcrumbs on each route update
		this.activatedRoute.params.subscribe({
			next: _ => {
				this.observeTruncation();
				this.getExternalIds();
				this.generateBreadCrumbs();
				this.getDropDownData();
				this.isDashboardCalendarView = this.globalDashboardRouteCheck();
				this.isDashboardPayGroupsListView = this.globalDashboardRouteCheck("/global-dashboard/paygroups");
			}
		});

		// Customer Entity Group State
		this.store
			.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState))
			.subscribe((state: CustomerEntityGroupSelect) => {
				this.getLatestNonEmptyValue(state)
					.pipe(take(1))
					.subscribe({
						next: (latestIdentifier: IDBreadCrumb) => {
							if (latestIdentifier.key === "customerName") {
								this.getDropDownData();
							}

							let storedIdentifier = this.tabStateService.getBreadcrumbIdentifier();
							if (state?.customerId !== "") {
								const customerIndex = storedIdentifier.findIndex(id => id.key === "customerName");

								if (customerIndex > -1 && state?.customerName) {
									storedIdentifier[customerIndex].value = state.customerName;
									storedIdentifier[customerIndex].externalId = "";
								}

								let idCrumbs: IDBreadCrumb[] = storedIdentifier;

								idCrumbs = idCrumbs.filter(crumb => crumb.key !== latestIdentifier.key);

								idCrumbs.push(latestIdentifier);

								let uniqueArray = idCrumbs.filter((obj, index, self) => {
									const currentIndex = self.findIndex((el, i) => el.key === obj.key && i < index);
									return currentIndex === -1;
								});

								function customSort(a: any, b: any) {
									const order = ["customerName", "payGroupId", "legalEntityId"];
									const aIndex = order.indexOf(a.key);
									const bIndex = order.indexOf(b.key);
									return aIndex - bIndex;
								}

								uniqueArray = uniqueArray.sort(customSort);

								this.tabStateService.setBreadcrumbIdentifier(uniqueArray);

								this.normalCrumbCount = uniqueArray.length;

								this.generateIdentifierCrumbs();
							}
						}
					});
			});

		this.store.pipe(takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe(state => {
			if (state && state.globalDashboard) {
				if (this.router.url.includes("global-dashboard")) {
					if (this.canSeeMap) {
						this.displayGlobalDashboardHome = true;

						this.setupDashboardCrumbColor(state.globalDashboard.kpiSelected);
					} else {
						this.displayGlobalDashboardHome =
							state.globalDashboard.kpiSelected === "Customers" ||
							state.globalDashboard.kpiSelected === "Paygroups"
								? true
								: false;
					}
				}
			}
		});
	}

	setupDashboardCrumbColor(kpi: string): void {
		this.dashboardCrumbIsBlue = kpi === "" || kpi === "Customers" || kpi === "Paygroups" ? true : false;
	}

	globalDashboardRouteCheck(url?: string): boolean {
		return url
			? this.currentRoute === "/global-dashboard/dashboard"
			: this.currentRoute === "/global-dashboard/paygroups";
	}

	ngAfterViewInit(): void {
		this.getExternalIds();
		this.adjustTextTruncation();
	}

	ngAfterViewChecked(): void {
		this.adjustTextTruncation();
	}

	adjustTextTruncation() {
		if (
			this.breadCrumbElementContainer &&
			this.breadCrumbElementHeaderTitle &&
			this.breadCrumbElementCustomer &&
			this.breadCrumbElementPaygroup
		) {
			const container = this.breadCrumbElementContainer.nativeElement;
			const text1 = this.breadCrumbElementHeaderTitle.nativeElement;
			const text2 = this.breadCrumbElementCustomer.nativeElement;
			const text3 = this.breadCrumbElementPaygroup.nativeElement;

			let maxiRolesToDisplay = 3;

			if (container) {
				const containerWidth = container.clientWidth;

				this.processContainer(containerWidth, this.buttonText, text3.scrollWidth);
			}

			if (text1.scrollWidth > text1.clientWidth) {
				// Text1 is fully truncated
				text1.classList.add("truncated", "truncate-text1");
			} else {
				// Text1 is not fully truncated
				text1.classList.remove("truncated", "truncate-text1");
			}

			if (text2.scrollWidth > text2.clientWidth) {
				// Text2 is fully truncated
				text2.classList.add("truncated");
			} else {
				// Text2 is not fully truncated
				text2.classList.remove("truncated");
			}

			if (
				text1.scrollWidth === text1.clientWidth &&
				text2.scrollWidth === text2.clientWidth &&
				text3.scrollWidth > text3.clientWidth
			) {
				// Text1 and Text2 are fully truncated, and Text3 can start truncating
				text3.classList.add("truncated");
			} else {
				// Text3 should not be truncated yet
				text3.classList.remove("truncated");
			}
		}
	}

	processContainer(containerWidth: number, buttonText: string, text3: number): void {
		const roleWidth = text3;
		const maxRolesToDisplay = Math.floor(containerWidth / roleWidth);
		let isTextOverflowing = false;
		let shouldFullyTruncate = false;

		if (maxRolesToDisplay < 3) {
			isTextOverflowing = true;
		}

		if (containerWidth > 0 && buttonText.length > 0) {
			shouldFullyTruncate = maxRolesToDisplay < 2;
		} else if (containerWidth > 0 && buttonText.length === 0) {
			isTextOverflowing = true;
		}

		this.breadCrumbService.textIsOverflowing.next(isTextOverflowing);
		this.breadCrumbService.fullyTruncateCustomerCrumb.next(shouldFullyTruncate);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	observeTruncation(): void {
		this.breadCrumbService.textIsOverflowing.pipe(takeUntil(this.destroy$)).subscribe({
			next: value => {
				this.crumbsTextIsOverFlowing = value;
			}
		});

		this.breadCrumbService.fullyTruncateCustomerCrumb.pipe(takeUntil(this.destroy$)).subscribe({
			next: value => {
				this.fullyTruncateCustomerCrumb = value;
			}
		});
	}

	getDropDownData() {
		if (this.tabStateService.getCustomerEntityGroupSelect().customerId) {
			this.dropDownData = this.breadCrumbService.getDropDownData(
				this.tabStateService.getCustomerEntityGroupSelect().customerId
			);
		}
	}

	generateBreadCrumbs() {
		this.breadcrumbs = [];

		let currentRoute: ActivatedRoute | null = this.activatedRoute.root,
			url = "";

		do {
			const childrenRoutes = currentRoute.children;
			currentRoute = null;

			childrenRoutes.forEach(route => {
				if (route.outlet === "primary") {
					const routeSnapshot = route.snapshot;

					url += "/" + routeSnapshot.url.map(segment => segment.path).join("/");

					currentRoute = route;

					if (route.snapshot.data.breadCrumb === undefined) {
						return;
					}

					const routeTo: string = this.breadCrumbService.getRouteToUrl(route.snapshot.data.breadCrumb);

					this.breadcrumbs.push({
						label: route.snapshot.data.breadCrumb,
						url: url.replace(/\/+/g, "/"),
						routeTo: routeTo
					});

					this.breadcrumbs = this.breadcrumbs;
				}
			});
		} while (currentRoute);
	}

	generateIdentifierCrumbs() {
		const objects: IDBreadCrumb[] = this.tabStateService.getBreadcrumbIdentifier();
		this.idBreadCrumbs = objects;

		if (objects.length >= 2 && this.router.url !== objects[objects.length - 1].spawn && !this.showAllCrumbs) {
			this.idBreadCrumbs = objects.slice(0, objects.length - 1);
		}

		this.omitCrumbsByRoute();
	}

	omitCrumbsByRoute(): void {
		const legalEntityOmissionList = [
			"/pay-groups/list",
			"/pay-groups/group",
			"/payments/paygroups",
			"/calendar/paygroup-list",
			"/reporting"
		];
		const paygroupOmissionList = ["/reporting"];
		const customerOmissionList = ["/reporting"];

		if (legalEntityOmissionList.includes(this.router.url)) {
			this.idBreadCrumbs = this.idBreadCrumbs.filter(item => item.key !== "legalEntityId");
		}

		if (paygroupOmissionList.includes(this.router.url)) {
			this.idBreadCrumbs = this.idBreadCrumbs.filter(item => item.key !== "payGroupId");
		}

		if (customerOmissionList.includes(this.router.url)) {
			this.idBreadCrumbs = this.idBreadCrumbs.filter(item => item.key !== "customerName");
		}

		this.tabStateService.setBreadcrumbIdentifier(this.idBreadCrumbs);
	}

	getLatestNonEmptyValue(state: CustomerEntityGroupSelect): Observable<IDBreadCrumb> {
		let latestValue = "";
		let latestKey = "";

		Object.entries(state).forEach(([key, value]) => {
			if (value !== "" && typeof value === "string") {
				latestValue = value;
				latestKey = key;
			}
		});

		const storedData = this.tabStateService.getBreadcrumbIdentifier();
		const data = storedData?.find((data: IDBreadCrumb) => {
			return data.key === latestKey;
		});

		if (latestKey === "legalEntityId") {
			return this.breadCrumbService.getLegalEntityName(latestValue).pipe(
				take(1),
				map(value => ({
					key: latestKey,
					value: value,
					spawn: data && this.isSpawnMatch(data.spawn, this.router.url) ? data.spawn : this.router.url
				}))
			);
		} else if (latestKey === "payGroupId") {
			this.breadCrumbService
				.getFlag(latestValue)
				.pipe(
					take(1),
					map(value => {
						this.selectedCountry = value;
					})
				)
				.subscribe();

			return this.breadCrumbService.getPayGroupNameAndExternalId(latestValue).pipe(
				take(1),
				map(value => ({
					key: latestKey,
					value: value.name,
					spawn: data && this.isSpawnMatch(data.spawn, this.router.url) ? data.spawn : this.router.url,
					externalId: value.externalId
				}))
			);
		} else if (latestKey === "customerName") {
			return this.breadCrumbService.getCustomerNameAndExternalId(state.customerId).pipe(
				take(1),
				map(value => ({
					key: latestKey,
					value: value.name,
					spawn: data && this.isSpawnMatch(data.spawn, this.router.url) ? data.spawn : this.router.url,
					externalId: value.externalId
				}))
			);
		}

		return of({ key: "", value: "", spawn: "" });
	}

	isSpawnMatch(spawn: string, url: string): boolean {
		const spawnParts = spawn.split("/");
		const urlParts = url.split("/");

		return spawnParts[1] === urlParts[1];
	}
	navigate(link: string, key?: string) {
		const validPaths = [
			"/employee-data",
			"/payments",
			"/service-definition/tpp",
			"/service-definition/net",
			"/calendar",
			"/service-provider-setup",
			"/customers",
			"/legal-entities",
			"/paygroups",
			"/tpp-catalogue",
			"/user-management",
			"/reporting",
			"/service-provider-setup",
			"/penny-test",
			"/tpp-catalogue",
			"/bank-fields",
			"/error-management"
		];
		if (validPaths.includes(link)) {
			// Reset the customer entity group state
			this.breadCrumbService.resetCustomerEntityGroupState();
			this.onRoutingCrumbClicked.emit(true);
		}

		if (key) {
			if (this.tabStateService.getBreadcrumbIdentifier().length > 0) {
				const storedIdentifier: IDBreadCrumb[] = this.tabStateService.getBreadcrumbIdentifier();

				const index = storedIdentifier.findIndex(obj => {
					return obj["spawn"] === link;
				});

				if (storedIdentifier[index]) {
					const navigateTo = [storedIdentifier[index]["spawn"]][0];

					if (this.externalCrumbs.length === 0) {
						storedIdentifier.pop();
						this.breadCrumbService.updateCustomerEntityGroupState();
						this.idBreadCrumbs.pop();
						this.tabStateService.setBreadcrumbIdentifier(storedIdentifier);
					}

					this.router.navigate([navigateTo]);
				} else {
					this.router.navigate([this.breadcrumbs[1].url]);
				}
			}
		} else {
			if (this.isNumber(link)) {
				this._location.historyGo(-link);
			} else if (link !== "") {
				this.router.navigate([link]);
			}
		}
	}

	crumbClicked(crumb: IDBreadCrumb | ExternalCrumb, last: boolean): void {
		if ("spawn" in crumb && crumb.spawn) {
			last ? null : this.navigate(crumb.spawn, crumb.key);
		} else if ("url" in crumb && crumb.url) {
			if (crumb.url === "/global-dashboard/paygroups") {
				this.breadCrumbService.updateBreadCrumbIDRemovePaygroup();
				this.breadCrumbService.updateCustomerEntityGroupStatePayGroup("");
				this.breadCrumbService.updateCustomerEntityGroupStateLegalEntity("");

				setTimeout(() => {
					this.navigate(crumb.url!);
				}, 3000);
			} else {
				this.navigate(crumb.url);
			}
		}

		this.onExternalCrumbClicked.emit(crumb);
	}

	isNumber(str: string): boolean {
		if (str.trim() === "") {
			return false;
		}

		return !Number.isNaN(Number(str));
	}

	navigateHome() {
		this.breadCrumbService.resetCustomerEntityGroupState();
		this.breadCrumbService.resetGlobalDashboardKPISelection();
		const menu = this.breadCrumbsMenuRoutingService.getMenu();
		const firstAvailableMenuItem = menu.filter(menuItem => menuItem.shouldShow === true)[0];
		const firstMenuItemURL: string = firstAvailableMenuItem.sections[0].navigations[0].url;
		this.router.navigate([firstMenuItemURL]);
	}

	navigateToGlobalDashboard(): void {
		this.breadCrumbService.resetCustomerEntityGroupState();
		this.breadCrumbService.resetGlobalDashboardKPISelection();
		this.router.navigate(["/global-dashboard"]);
	}

	setDropDownKey(key: string, event: MouseEvent, crumb?: ExternalCrumb) {
		if (
			(key && !this.disableModals) ||
			(!this.disableModals && crumb?.dropDownData) ||
			(!this.disableModals && crumb?.externalKey)
		) {
			if (crumb && crumb.dropDownData) {
				this.selectedExternalCrumbDropDownData = crumb.dropDownData;
			} else {
				this.selectedExternalCrumbDropDownData = undefined;
			}

			this.dropDownKey = crumb?.externalKey ? crumb!.externalKey : key;
			this.modalOpen = true;
			this.modalOpenPosition = { x: event.clientX, y: event.clientY };
		}
	}

	getCrumbArray(): any {
		if (this.showAllCrumbs) {
			return [...this.idBreadCrumbs, ...this.externalCrumbs];
		} else {
			return this.externalCrumbs.length ? this.externalCrumbs : this.idBreadCrumbs;
		}
	}

	getExternalDropDownData(): DropDownDataExpanded[] | undefined {
		return this.selectedExternalCrumbDropDownData ? this.selectedExternalCrumbDropDownData : undefined;
	}

	emitHeaderClicked(emit: boolean): void {
		this.onHeaderTitleClicked.emit(emit);
	}

	closeModal() {
		this.modalOpen = false;
	}

	headerButtonAction(): void {
		this.onHeaderButtonAction.emit();
	}

	dropDownItemSelected(): void {
		this.legalExternalId = "";
		this.payGroupExternalId = "";
		this.selectedCountry = "";
		this.breadCrumbService.dropDownItemSelected$.pipe(takeUntil(this.destroy$)).subscribe(value => {
			if (value) {
				this.onDropDownCrumbItemSelected.emit(true);
				this.getExternalIds();

				this.getCrumbArray();
			}
		});
	}

	externalDropDownItemSelected(id: string): void {
		this.onExternalDropDownCrumbItemSelected.emit(id);
	}

	getExternalIds() {
		this.legalExternalId = "";
		this.payGroupExternalId = "";
		const payGroupId = this.tabStateService.getCustomerEntityGroupSelect().payGroupId;
		const legalEntityId = this.tabStateService.getCustomerEntityGroupSelect().legalEntityId;

		if (payGroupId !== "") {
			this.payGroupsService
				.getPaygroupWithPaygroupId(payGroupId)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: res => {
						this.legalExternalId = res.legalEntity.data.externalId;

						this.payGroupExternalId = res.externalId;

						this.adjustTextTruncation();
					}
				});
		} else if (legalEntityId !== "") {
			const legalEntityId = this.tabStateService.getCustomerEntityGroupSelect().legalEntityId;

			this.legalEntityService
				.getLegalEntityById(legalEntityId)
				.pipe(take(1))
				.subscribe({
					next: res => {
						this.legalExternalId = res.data.externalId;

						this.selectedCountry = res.data.country;

						this.adjustTextTruncation();
					}
				});
		}
	}
}
