<div class="buffer" *ngIf="payCycle"></div>

<div fxLayout="column" class="main-calendar-container" *ngIf="orderedPayCycles.length > 0">
	<!-- Pay cycle selector -->
	<div fxLayout="row" class="calendar-top-section" *ngIf="orderedPayCycles.length">
		<!-- Year selector -->
		<div class="year-select">
			<span class="year" fxLayoutAlign="center center ">{{ selectedYear }}</span>
			<span fxLayout="row" fxLayoutAlign="center center">
				<app-icon
					id="previousYearButton"
					class="indicator year-next"
					(click)="previousYear()"
					[name]="'arrow-left-1'"
					[color]="'var(--color-primary-1000)'"
					[size]="'25'"
					[lineWidth]="'3.5px'"
				></app-icon>
				<app-icon
					id="nextYearButton"
					class="indicator year-next"
					(click)="nextYear()"
					[name]="'arrow-right-1'"
					[color]="'var(--color-primary-1000)'"
					[size]="'25'"
					[lineWidth]="'3.5px'"
				></app-icon>
			</span>
		</div>
		<div class="month-run-container">
			<app-icon
				id="previousMonthIcon"
				fxLayoutAlign="center center"
				class="indicator month-next"
				(click)="previousCycle()"
				[name]="'arrow-left-1'"
				[color]="'var(--color-primary-1000)'"
				[size]="'25'"
				[lineWidth]="'3.5px'"
			></app-icon>
			<div
				*ngIf="!isCalendarRoute && payCycle?.errors?.before"
				class="milestone-errors-counter"
				fxLayoutAlign="center center"
			>
				<span class="milestone-errors-counter-text before">
					{{ payCycle.errors.before }}
				</span>
			</div>
			<div
				fxLayoutAlign="center center"
				class="paycycle-run"
				[matMenuTriggerFor]="cycleNavigationMenu"
				(menuOpened)="switchOrderedPayCycles(payCycle)"
				#triggerMenu="matMenuTrigger"
			>
				<span class="cycle-name">
					{{ payCycle.name }}
				</span>
			</div>

			<div
				*ngIf="!isCalendarRoute && payCycle?.errors?.after"
				class="milestone-errors-counter"
				fxLayoutAlign="center center"
			>
				<span class="milestone-errors-counter-text after">
					{{ payCycle.errors.after }}
				</span>
			</div>
			<app-icon
				fxLayoutAlign="center center"
				id="nextMonthIcon"
				class="indicator month-next"
				(click)="nextCycle()"
				[name]="'arrow-right-1'"
				[color]="'var(--color-primary-1000)'"
				[size]="'25'"
				[lineWidth]="'3.5px'"
			></app-icon>
		</div>
		<div class="edit-offcycle-container">
			<button
				type="button"
				*ngIf="isCalendarRoute && canEditCycle"
				mat-button
				class="edit-offcycle-btn"
				(click)="editCycle(payCycle!)"
			>
				<app-icon class="icon-edit-offcycle" size="20" name="edit-2" color="#767676"></app-icon> EDIT CYCLE
			</button>
		</div>
	</div>

	<mat-divider class="mat-div-calendar-bottom"></mat-divider>

	<!-- Year and month selectors -->
	<div class="month-row">
		<!-- Month selector -->

		<ng-container *ngIf="payGroupFrequency === 'MONTHLY' || payGroupFrequency === 'BI_MONTHLY'">
			<div
				class="month-container"
				fxLayout="column"
				fxLayoutAlign="space-evenly center"
				*ngFor="let row of organisedMonthNames; let i = index"
				(click)="onCycleClick(i, payCycle!, 'monthly')"
			>
				<span
					id="month-button"
					class="month"
					[ngClass]="{ 'selected-month-item': i === middle || overlappingCheck(i) }"
					>{{ row }}
					<app-hover-object
						*ngIf="milestones.length > 0"
						[type]="'dark-milestone-counter'"
						[milestone]="milestones"
						[month]="row"
					></app-hover-object>
				</span>

				<div *ngIf="i === middle" class="month-underline"></div>
			</div>
		</ng-container>

		<!-- Week selector -->

		<ng-container
			*ngIf="
				payGroupFrequency === 'WEEKLY' ||
				payGroupFrequency === 'BI_WEEKLY' ||
				(payGroupFrequency === 'FOUR_WEEKLY' && shownPayCycleWeeks.length > 0)
			"
		>
			<div
				class="month-container"
				fxLayout="column"
				fxLayoutAlign="center center"
				*ngFor="let payCycleWeek of shownPayCycleWeeks; let i = index"
				(click)="onCycleClick(i, payCycle!, 'weekly')"
			>
				<span id="week-button" class="week" [ngClass]="{ 'offcycle-week': payCycleWeek.type === 'OFF_CYCLE' }">
					{{ payCycleWeek.label }}

					<app-hover-object
						*ngIf="milestones.length > 0"
						[type]="'dark-milestone-counter'"
						[milestone]="milestones"
						[cycleWeekStart]="payCycleWeek.startDate"
						[cycleWeekEnd]="payCycleWeek.endDate"
					></app-hover-object>
				</span>

				<div *ngIf="selectedCycleWeekId === payCycleWeek.id" class="month-underline"></div>
			</div>
		</ng-container>
	</div>

	<mat-divider class="mat-div-calendar-bottom margin-mid" fxLayout="row"></mat-divider>

	<!-- Day selector -->
	<div class="calendar-days-row">
		<!-- Left arrow -->
		<!--<button type="button" class="arrow" mat-icon-button (click)="onCycleClick(3, payCycle, 'monthly')">
			<app-icon
				[name]="'arrow-left-1'"
				[color]="'var(--color-primary-1000)'"
				[size]="'25'"
				[lineWidth]="'3.5px'"
			></app-icon>
		</button>-->

		<!-- Days -->
		<div fxLayout="row" class="daysrow">
			<div class="days-container" *ngFor="let date of calendar; let i = index; first as isFirst; last as isLast">
				<!-- Day -->
				<div
					class="day-container"
					fxLayoutAlign="spece-evenly center"
					fxLayout="column"
					(click)="setSelectedDate(date.date, true)"
					[ngClass]="{
						disabled: date.date.getMonth() !== currentMonth,
						today: date.isToday,
						bold: date.date.getDay() !== 0 && date.date.getDay() !== 6
					}"
				>
					<!-- Day of week -->
					<div
						class="weekdays"
						fxLayout="row"
						[ngClass]="{
							today: date.isToday
						}"
					>
						{{ date.date.toLocaleDateString("US", { weekday: "short" }).split("", 1)[0] }}
					</div>

					<!-- Number of day -->
					<div
						class="day"
						[ngClass]="{
							disabled: date.date.getMonth() !== currentMonth,
							today: date.isToday,
							bold: date.date.getDay() !== 0 && date.date.getDay() !== 6
						}"
					>
						{{ date.date.getDate() }}
					</div>
				</div>

				<!-- Milestones -->
				<app-hover-object *ngIf="date.milestoneArray.length > 0" [date]="date"></app-hover-object>
			</div>
		</div>

		<!-- Right arrow -->
		<!--<button type="button" class="arrow" mat-icon-button (click)="onCycleClick(5, payCycle, 'monthly')">
			<app-icon
				[name]="'arrow-right-1'"
				[color]="'var(--color-primary-1000)'"
				[size]="'25'"
				[lineWidth]="'3.5px'"
			></app-icon>
		</button>-->
	</div>
	<!--<mat-divider class="mat-div-calendar-bottom margin-small" fxLayout="row"></mat-divider>-->

	<div class="bottom-calendar-container" fxLayout="row">
		<!--Today button-->
		<button type="button" mat-button class="today-btn" (click)="resetToToday()">Today</button>
	</div>
</div>

<!-- Run Navigation Menu -->
<mat-menu class="navigation-menu" #cycleNavigationMenu="matMenu">
	<ng-template matMenuContent>
		<div class="navigation-menu-years-container" fxFlex="30" fxLayout="column">
			<div (click)="$event.stopPropagation()" *ngFor="let item of navigationMenuYears">
				<button
					type="button"
					class="navigation-menu-years-button"
					(click)="getOrderedPayCycles(item)"
					[disableRipple]="true"
					mat-menu-item
				>
					<span class="navigation-year-button-text"> {{ item }}</span>
				</button>
			</div>
		</div>
		<div class="navigation-menu-months-container" fxFlex="70">
			<div *ngFor="let cycle of orderedPayCycles; index as i" flexLayoutAlign="start center">
				<button
					type="button"
					class="navigation-menu-cycles-button"
					(click)="generateCalendarDays(cycle)"
					mat-menu-item
					fxLayout="row"
					flexLayoutAlign="start center"
					fxLayoutGap="10px"
				>
					<span class="navigation-cycle-button-text">
						{{ cycle.name }}
					</span>

					<div
						*ngIf="!isCalendarRoute && cycle.errors.own > 0"
						class="milestone-errors-counter-menu"
						fxLayoutAlign="center center"
					>
						<span class="milestone-errors-counter-text">
							{{ cycle.errors.own }}
						</span>
					</div>
				</button>
			</div>
		</div>
	</ng-template>
</mat-menu>
