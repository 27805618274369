<ng-container *ngIf="payGroupOptions.length">
	<div class="dialog-header">
		<mat-icon>warning_amber</mat-icon>
		<h3>Warning</h3>
	</div>
	<div class="container" fxLayout="column">
		<div fxLayout="column" class="mat-dialog-content" mat-dialog-content>
			<div class="text-center">
				<span>Are you sure you want to change the Pay Group assignment?</span>
			</div>

			<div [formGroup]="form" class="selection-area">
				<input-wrapper class="input-wrapper-paygroup-dialog">
					<input-select label="Pay Groups" formControlName="payGroup" [options]="payGroupOptions">
					</input-select>
				</input-wrapper>
			</div>
		</div>
		<div
			fxLayout="row"
			fxLayoutGap="0px"
			fxLayoutAlign="space-evenly center"
			class="mat-dialog-actions"
			mat-dialog-actions
		>
			<button id="cancelBtn" action-buttons mat-stroked-button (click)="onCancel()">No, Thanks</button>
			<button
				id="saveBtn"
				[disabled]="!payGroupOptions.length || !form.get('payGroup')?.value"
				action-buttons
				color="dark"
				mat-flat-button
				(click)="onConfirm()"
			>
				Yes, Sure
			</button>
		</div>
	</div>
</ng-container>
