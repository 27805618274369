<app-breadcrumbs [headerTitle]="'LEGAL ENTITIES'" />
<div class="main-container">
	<div class="information-container">
		<ng-container *ngIf="legalEntityForm">
			<form [formGroup]="legalEntityForm">
				<!-- Crontacted Legal entity Setup Section -->
				<div class="container-section">
					<div class="title">
						<!--Legal Entity Setup -->
						Legal Entity Setup
					</div>
					<div class="o-grid-2">
						<!-- col 1 -->
						<div class="first-input-row">
							<!-- Legal Entity reference -->
							<input-wrapper label="Legal entity reference">
								<input
									id="externalId"
									type="text"
									placeholder="Legal entity reference"
									formControlName="externalId"
									(input)="isChanged = true"
								/>
								<div
									id="externalId"
									input-errors
									*ngIf="legalEntityForm.controls.externalId.errors?.duplicateId"
								>
									Legal entity reference can not be the same as customer reference
								</div>
							</input-wrapper>

							<!-- Legal Entity address -->
							<input-wrapper label="Address">
								<input
									id="address"
									type="text"
									placeholder="Address"
									formControlName="address"
									(input)="isChanged = true"
								/>
							</input-wrapper>

							<!-- Legal entity region -->
							<input-wrapper label="Legal entity region *">
								<input-select
									id="region"
									formControlName="region"
									label="Legal entity region"
									[required]="legalEntityForm.get('region')?.errors?.required"
									[options]="regions"
									[_disabled]="isDisabledForViewOnly"
								>
								</input-select>
								<div id="region" input-errors *ngIf="legalEntityForm.get('region')?.errors?.required">
									Legal entity region is required
								</div>
							</input-wrapper>

							<!-- Legal time zone -->
							<input-wrapper label="Timezone">
								<input-select
									id="timezone"
									formControlName="timeZone"
									label="Timezone"
									[options]="legalEntityForm.get('timeZonesOptions')?.value"
									[required]="legalEntityForm.get('timeZone')?.errors?.required"
									[_disabled]="isDisabledForViewOnly"
								>
								</input-select>
							</input-wrapper>
						</div>

						<!-- col 2 -->
						<div>
							<!-- Legal Entity name -->
							<input-wrapper label="Legal entity name *">
								<input
									id="name"
									type="text"
									placeholder="Legal entity name"
									formControlName="name"
									(input)="isChanged = true"
								/>
								<div id="name" input-errors *ngIf="legalEntityForm.get('name')?.errors?.required">
									Legal entity name is required
								</div>
							</input-wrapper>

							<!-- Legal Entity country -->
							<input-wrapper label="Legal entity country *">
								<input-autocomplete
									*ngIf="canEditLegalEntitySetup"
									id="country"
									formControlName="country"
									[options]="selectOptionsCountries"
									[label]="'Select country'"
									[required]="legalEntityForm.get('country')?.errors?.required"
									[disabled]="isDisabledForViewOnly"
								>
								</input-autocomplete>
								<!--
										The `input-autocomplete` can't be disabled using the reactive forms
										API, if a user can't edit a legal entity then display a blank control.
									-->
								<input-autocomplete *ngIf="!canEditLegalEntitySetup" [disabled]="true">
								</input-autocomplete>
								<div id="country" input-errors *ngIf="legalEntityForm.get('country')?.errors?.required">
									Legal entity country is required
								</div>
							</input-wrapper>

							<!-- WPay Legal Entity Reference -->
							<!-- <ng-container *ngIf="!legalEntityForm.get('isCopyForm')?.value">
									<input-wrapper label="WPay Legal Entity Reference">
										<input
											id="id"
											type="text"
											placeholder="WPay Legal entity Reference"
											formControlName="id"
											(input)="isChanged = true"
										/>
									</input-wrapper>
								</ng-container> -->
							<ng-container *ngIf="legalEntityForm.get('isCopyForm')?.value">
								<div></div>
							</ng-container>
						</div>
					</div>
				</div>

				<hr />
				<!-- Contact Person Edition -->
				<div class="container-section">
					<!-- Contact Person -->
					<div class="title">Contact person (Optional)</div>
					<div class="button-container">
						<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
							<button
								class="btn-new-contact"
								mat-button
								[disabled]="legalEntityForm.get('contactPersons')?.invalid || !canEditContactPersons"
								(click)="onEditContactClicked()"
							>
								<app-icon
									id="add-legal-edition"
									[name]="'add'"
									[color]="'var(--color-primary-1000)'"
									[size]="'20px'"
								></app-icon
								>Create new contact person
							</button>
						</ng-template>
					</div>
					<app-contacts
						*ngIf="existingContacts"
						(contactFormData)="editContactFormData($event)"
						[addIsClicked$]="editContactClicked$"
						[contacts]="existingContacts"
						[objectLevel]="'legal-entities'"
						[objectId]="legalEntity.id"
					></app-contacts>
				</div>
				<hr />

				<!-- AML -->
				<div class="container-section">
					<div fxLayout="row">
						<app-full-aml-form
							(onFormChange)="onUpdateAmlStatus($event)"
							[selectedValue]="legalEntity.status"
							[disabled]="cantEditAmlStatus"
						>
						</app-full-aml-form>
						<div class="view-details">
							<app-icon
								class="more-dots"
								[name]="'more'"
								(click)="showAMLHistory()"
								[lineWidth]="'1'"
							></app-icon>
						</div>
					</div>

					<!-- Buttons -->
					<div class="btn-container">
						<button
							id="cancel"
							class="cancel-btn"
							(click)="cancelEditLegalEntity()"
							action-buttons
							mat-stroked-button
						>
							Cancel
						</button>
						<ng-template [appUserRolePermissions] [action]="'hide'" [roles]="['TS_VO', 'CLIENT']">
							<button
								id="submit"
								class="submit-btn"
								(click)="updateLegalEntity()"
								action-buttons
								mat-flat-button
								[disabled]="legalEntityForm.invalid || !contactFormisValid || legalEntityForm.pristine"
							>
								Save
							</button>
						</ng-template>
					</div>
				</div>
			</form>
		</ng-container>
	</div>
</div>
