import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GeoFeature, GeoMap } from "@modules/client-landing/_models/client_landing-interface";
import { Subject } from "rxjs";
import { Feature, GeoJsonObject, Geometry } from "geojson";
import * as L from "leaflet";

@Component({
	selector: "app-country",
	templateUrl: "./country.component.html",
	styleUrls: ["./country.component.scss"]
})
export class CountryComponent implements OnDestroy {
	destroy$: Subject<void> = new Subject();
	layers!: L.Layer[];
	southWest = [-90, -180];
	northEast = [90, 180];

	options: L.MapOptions = {
		zoomControl: false,
		maxBounds: new L.LatLngBounds(
			new L.LatLng(-70, -180), // Adjusted to include the South Pole and the International Date Line
			new L.LatLng(90, 180) // Adjusted to include the North Pole and the International Date Line
		),
		maxBoundsViscosity: 1.0,
		attributionControl: false,
		dragging: false,
		scrollWheelZoom: false,
		doubleClickZoom: false,
		center: [0, 0]
	};

	bounds!: L.LatLngBounds;

	countryGeo!: GeoMap;
	countryName!: string;

	@Input() set country(country: GeoMap) {
		this.countryGeo = country;
		this.layers = [this.createGeoLayer(country as GeoJsonObject)];
		this.setInitialZoom(this.countryGeo);
		this.countryName = country.features[0]?.properties.name;
	}

	private createGeoLayer(geoMap: GeoJsonObject): L.GeoJSON<GeoJsonObject> {
		return L.geoJSON(geoMap, {
			style: () => ({
				weight: 0.67,
				fillColor: "var(--input-toggle-background)",
				fillOpacity: 1,
				color: "var(--color-service-details-background)",
				opacity: 1
			})
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setInitialZoom(geoMap: GeoMap): void {
		geoMap.features.forEach((f: GeoFeature) => {
			f.geometry.coordinates.forEach((countryAreasArray: any) => {
				countryAreasArray.forEach((countryAreaCoords: any) => {
					// Check if area coords are nested in arrays to iterate again
					if (isNaN(countryAreaCoords[0])) {
						countryAreaCoords.forEach((lngLatTuple: any) => {
							this.setBounds(lngLatTuple);
						});
					} else {
						this.setBounds(countryAreaCoords);
					}
				});
			});
		});

		this.bounds = L.latLngBounds([this.southWest[0], this.southWest[1]], [this.northEast[0], this.northEast[1]]);
	}

	private setBounds(lngLatTuple: any): void {
		if (this.southWest[0] < lngLatTuple[1]) {
			this.southWest[0] = lngLatTuple[1];
		}
		if (this.southWest[1] < lngLatTuple[0]) {
			this.southWest[1] = lngLatTuple[0];
		}
		if (this.northEast[0] > lngLatTuple[1]) {
			this.northEast[0] = lngLatTuple[1];
		}
		if (this.northEast[1] > lngLatTuple[0]) {
			this.northEast[1] = lngLatTuple[0];
		}
	}
}
