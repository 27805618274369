<div [formGroup]="searchForm" class="search-section" fxLayoutAlign="space-between center">
	<mat-form-field class="search-field" id="master-search-field" appearance="outline">
		<app-icon
			id="master-search-icon"
			[name]="'search-normal-1'"
			[size]="'25'"
			[lineWidth]="'2px'"
			[color]="'var(--color-search-icon)'"
			matPrefix
		></app-icon>
		<input id="search" matInput placeholder="Search by..." formControlName="search" />
	</mat-form-field>
</div>

<div fxLayout="row" fxLayoutAlign="start center" class="history-header-container">
	<div fxFlex="5"></div>
	<div id="user" fxFlex="30">USER</div>
	<div id="created_at" fxFlex="45">CREATED AT</div>
	<div id="stauts" fxFlex="20">STATUS</div>
</div>
<div class="scroll-container">
	<div *ngIf="amlHistoryList$ | async as amlHistoryList">
		<ng-container *ngIf="!amlHistoryList.length; else errorTemplate">
			<div fxLayout="row" fxLayoutAlign="start center" class="history-item-container">
				<p>There is no data to display</p>
			</div>
		</ng-container>
		<ng-template #errorTemplate>
			<ng-container *ngFor="let data of amlHistoryList$ | async">
				<div fxLayout="row" class="history-item-container" fxLayoutAlign="start center">
					<div class="data-row" *ngIf="data">
						<div fxFlex="5"></div>
						<div id="user" fxFlex="30">{{ data.firstName }} {{ data.lastName }}</div>
						<div id="created_at" fxFlex="45">
							{{ data.createdAt.toDateString() }}
						</div>
						<div id="stauts" fxFlex="20">
							<div
								*ngIf="data.status"
								[ngClass]="{
									'text-success': data.status === 'APPROVED',
									'text-rejected': data.status === 'REJECTED',
									'text-on-review': data.status === 'ON REVIEW'
								}"
							>
								<span
									><b>{{ data.status }}</b></span
								>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-template>
	</div>
</div>
