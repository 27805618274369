<div class="header">
	<h3 class="dialog-text" fxFlex="95">{{ isEditing ? "EDIT CYCLE" : "CREATE CYCLE" }}</h3>
	<app-icon [name]="'close'" [size]="'55px'" [color]="'white'" fxFlex="5" (click)="onCancel()"></app-icon>
</div>

<div class="content">
	<mat-dialog-content [formGroup]="createOffCycleForm">
		<div fxLayout="row">
			<input-wrapper class="space" label="PAYROLL CYCLE ID">
				<input id="payrollCycleId" type="text" formControlName="payrollCycleId" />
			</input-wrapper>
			<input-wrapper label="PAYROLL CYCLE NAME">
				<input id="payrollCycleId" type="text" formControlName="payrollCycleName" />
			</input-wrapper>
		</div>
		<div fxLayout="row">
			<input-wrapper class="space" label="CYCLE ID *">
				<input id="cycleId" type="text" formControlName="cycleId" />
			</input-wrapper>
			<input-wrapper label="CYCLE NAME *">
				<input id="cycleName" type="text" formControlName="cycleName" />
			</input-wrapper>
		</div>
		<div fxLayout="row">
			<input-wrapper class="space" readonly label="CYCLE START DATE *">
				<mat-form-field appearance="outline" (click)="startDate.open()">
					<mat-datepicker-toggle matPrefix [for]="startDate">
						<app-icon
							matDatepickerToggleIcon
							class="icon-offcycle"
							[name]="'calendar_month'"
							[color]="'var(--color-primary-1000)'"
						></app-icon>
					</mat-datepicker-toggle>
					<input
						id="date"
						formControlName="cycleStartDate"
						matInput
						[matDatepicker]="startDate"
						(focus)="startDate.open()"
					/>
					<mat-datepicker #startDate></mat-datepicker>
				</mat-form-field>
				<div
					input-errors
					*ngIf="
						createOffCycleForm.get('cycleStartDate')?.touched &&
						createOffCycleForm.controls.cycleStartDate.errors?.required
					"
				>
					Cycle start date is required.
				</div>

				<div input-errors *ngIf="createOffCycleForm.hasError('startDateNotValid')">
					Date cannot be after end date.
				</div>
			</input-wrapper>

			<input-wrapper label="CYCLE END DATE *" readonly>
				<mat-form-field appearance="outline" (click)="endDate.open()">
					<mat-datepicker-toggle matPrefix [for]="endDate">
						<app-icon
							matDatepickerToggleIcon
							class="icon-offcycle"
							[name]="'calendar_month'"
							[color]="'var(--color-primary-1000)'"
						></app-icon>
					</mat-datepicker-toggle>
					<input
						id="date"
						formControlName="cycleEndDate"
						matInput
						[matDatepicker]="endDate"
						(focus)="endDate.open()"
					/>
					<mat-datepicker #endDate></mat-datepicker>
				</mat-form-field>

				<div
					input-errors
					*ngIf="
						createOffCycleForm.get('cycleEndDate')?.touched &&
						createOffCycleForm.controls.cycleEndDate.errors?.required
					"
				>
					Cycle end date is required.
				</div>

				<div input-errors *ngIf="createOffCycleForm.hasError('endDateNotValid')">
					Date cannot be before start date.
				</div>
			</input-wrapper>
		</div>

		<div fxLayout="row">
			<input-wrapper label="REASON ">
				<textarea id="reason" class="reason-text-area" formControlName="offCycleReason"> </textarea>
			</input-wrapper>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions fxLayout="row" mat-dialog-actions fxLayoutAlign="end none">
		<button class="dialog-action-button" action-buttons mat-stroked-button (click)="onCancel()">Cancel</button>
		<button
			class="dialog-action-button"
			action-buttons
			color="dark"
			mat-flat-button
			(click)="onConfirm()"
			cdkFocusInitial
			[disabled]="!createOffCycleForm.valid"
		>
			Save
		</button>
	</mat-dialog-actions>
</div>
