import { AbstractControl, FormGroup } from "@angular/forms";
import { SelectOption } from "./select-option.interface";
import { SubService, TppService, TppServiceBeneficiaryDTO } from "./tpp-service.interface";

export interface PayCyclePagination {
	totalCount: number;
	totalPages: number;
	pageNumber: number;
	items: PayCycle[];
}

export interface PayCycle {
	createdAt: Date;
	customerId: string;
	end: string; // yyyy-mm-dd
	externalId: string;
	id: string;
	legalEntityId: string;
	milestones: Milestone[];
	payGroupId: string;
	type: string;
	start: string; // yyyy-mm-dd
	status: "INACTIVE" | "ACTIVE";
	updatedAt: Date;
	version: number;
	name: string;
	payrollCycleId: string;
	payrollCycleName: string;
	resetHistory: HistoryReset[];
	errors: {
		before: number;
		after: number;
		own: number;
	};
	attributes: {
		G2N_APPROVED: boolean;
	};
}

export interface HistoryReset {
	resetAt: Date;
	userId: string;
}

export interface MilestoneListWithPagination {
	items: Milestone[];
	pageNumber: number;
	totalCount: number;
	totalPages: number;
}
export interface Milestone {
	id?: string;
	version?: number;
	payCycleId?: string;
	payGroupId?: string;
	type: MilestoneTypeName;
	group: MilestoneGroupName;
	date: Date | string;
	status?: string;
	resetHistory: HistoryReset[];
	params: {
		status: string;
	};
	statusHistory?: MilestoneStatusHistory[];
}

export interface MilestoneStatusHistory {
	statusFrom: string;
	statusTo: string;
	userId: string;
	createdAt: string;
}

export interface MilestoneGroup {
	group: MilestoneGroupName;
	type: MilestoneGroupType;
	text: string;
	milestone?: Milestone[];
}

export interface TppPayCycleSubServices {
	serviceId: string;
	service?: TppService;
	subServiceId: string;
	subService?: SubService;
	externalId: string;
	group: MilestoneGroupName;
	beneficiaryId: string;
	beneficiary: TppServiceBeneficiaryDTO;
	frequency: string;
	managedIn: string;
}

export interface MilestoneDay {
	group: MilestoneGroupName;
	type: MilestoneTypeName;
}

export interface MilestoneTypeCount {
	type: MilestoneTypeName;
	count: number;
	text?: string;
}

export interface MilestoneUpdate {
	id?: string;
	version?: number;
	payCycleId?: string;
	payGroupId?: string;
	type: MilestoneTypeName;
	group: MilestoneGroupName;
	date: Date | string;
	status?: string;
	params: [{ key: string; value: string }?];
}

export type MilestoneTypeName = "NETS" | "FUNDING_DATE" | "G2N_FILE" | "PAYMENT" | "DATA_INPUT";
export type MilestoneGroupName = "NETS" | "TPP1" | "TPP2" | "TPP3" | "TPP4";
export type MilestoneGroupType = "NETS" | "TPP";
export type MilestoneStatusOptions = "PENDING" | "COMPLETE" | "ERROR" | "UNDEFINED" | "IN_PROGRESS";

export interface OffCycleCreateDTO extends Partial<PayCycle> {
	comment: string;
}

export interface MilestoneUpdateDTO {
	milestones: MilestoneUpdate[];
}

export interface status {
	status?: MilestoneStatusOptions;
}

export interface IStatusForm extends FormGroup {
	value: status;
	controls: {
		status: AbstractControl;
	};
}

export const MilestoneStatusOptions: SelectOption[] = [
	{ text: "Completed", value: "COMPLETE" },
	{ text: "Has errors", value: "ERROR" },
	{ text: "Pending", value: "PENDING" }
];

export const FundingDateMilestoneStatusOptions: SelectOption[] = [
	{ text: "Completed", value: "COMPLETE" },
	{ text: "In progress", value: "IN_PROGRESS" },
	{ text: "Has errors", value: "ERROR" },
	{ text: "Pending", value: "PENDING" }
];

export enum MilestoneStatusesValue {
	COMPLETE = "COMPLETE",
	IN_PROGRESS = "IN_PROGRESS",
	PENDING = "PENDING",
	ERROR = "ERROR",
	HAS_ERRORS = "HAS_ERRORS",
	UNDEFINED = "UNDEFINED"
}

export enum MilestoneStatusesText {
	COMPLETED = "Completed",
	IN_PROGRESS = "In progress",
	PENDING = "Pending",
	HAS_ERRORS = "Has errors"
}
