import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import {
	CardDetails,
	DashboardFilters,
	IDashboardFiltersFormGroup
} from "@modules/global-dashboard/models/global-dashboard-interface";
import { DataFormattingService } from "@modules/global-dashboard/services/data-fomatting.service";
import { GlobalDashboardApiService } from "@modules/global-dashboard/services/global-dashboard-api.service";
import { ExternalCrumb } from "@shared/models/breadcrumbs.interface";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ApiService } from "@modules/pay-groups/services/api.service";
import { MilestoneGroupsService } from "@modules/payment-calendar/_services/milestone-groups.service";
import { CalendarService } from "@shared/services/calendar/calendar.service";
import { MilestonesService } from "@shared/services/milestones/milestones.service";
import { PayCycleService } from "@shared/services/pay-cycle/pay-cycle.service";
import { getCustomerEntityGroupState, getGlobalDashboardFilterState } from "src/app/store";
import { AppState } from "src/app/store/models/state.model";
import { Milestone, MilestoneGroup, MilestoneGroupName, PayCycle } from "../../../../shared/models/pay-cycle.interface";
import { PayGroup } from "../../../../shared/models/pay-groups";
import { ResetPayrollDialogComponent } from "../file-history/_components/reset-payroll-dialog/reset-payroll-dialog.component";

@Component({
	selector: "app-dashboard-calendar",
	templateUrl: "./dashboard-calendar.component.html",
	styleUrls: ["./dashboard-calendar.component.scss"]
})
export class DashboardCalendarComponent implements OnInit, OnDestroy {
	@Output() resetTotalPayments: EventEmitter<any> = new EventEmitter<any>();

	payGroup: PayGroup | undefined;
	payCycle: PayCycle | undefined = undefined;
	milestones: Milestone[] = [];
	milestoneGroups: MilestoneGroup[] = [];
	isCalendarMilestonesLoading: boolean = true;
	todaysDate: Date = new Date();
	expandedMilestoneGroupNameFromHistory: string | undefined;
	kpiCards: CardDetails[] = new Array(5).fill({ kpiType: {} });
	destroy$: Subject<void> = new Subject<void>();
	triggerResetPayouts$: Subject<void> = new Subject<void>();
	resetMilestones$!: Subscription;
	isFromGlobalDashboard = false;
	externalCrumbs: ExternalCrumb[] = [];
	headerCrumbs!: string[];
	filters!: FormGroup;
	fetchingDataForFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	kpiSelected: string = "";

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private payCycleService: PayCycleService,
		private store: Store<AppState>,
		private payGroupService: ApiService,
		private milestonesService: MilestonesService,
		private milestoneGroupsService: MilestoneGroupsService,
		private calendarService: CalendarService,
		private globalDashboardApiService: GlobalDashboardApiService,
		private dataFormattingService: DataFormattingService,
		private formBuilder: FormBuilder
	) {}

	ngOnInit(): void {
		this.onCustomerStateChangeUpdatePayGroup();
		this.onSelectedMilestoneGroupInRouterHistoryExpandGroup();
		this.listenForResetDialogEvent();
	}

	getCardDetails(filters: DashboardFilters, kpiSelected: string): void {
		this.globalDashboardApiService
			.getCardDetails(filters, this.dataFormattingService.getAvailableFiltersLayers(kpiSelected))
			.pipe(
				map(cards => {
					this.fetchingDataForFilters.next(false);
					this.kpiCards = cards;
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.cleanupSubscriptions();
	}

	onCustomerStateChangeUpdatePayGroup() {
		this.store.pipe(takeUntil(this.destroy$), select(getCustomerEntityGroupState)).subscribe(state => {
			if (state && state.payGroupId) {
				this.payGroupService
					.getPayGroup(state.payGroupId)
					.pipe(takeUntil(this.destroy$))
					.subscribe((payGroup: PayGroup) => {
						this.payGroup = payGroup;
						this.resetVaribles();

						if (this.router.url === "/global-dashboard/dashboard") {
							this.isFromGlobalDashboard = true;
							this.getSelectedKPI(payGroup);
						}
					});
			}
		});
	}

	getSelectedKPI(payGroup: PayGroup): void {
		this.store.pipe(takeUntil(this.destroy$), select(getGlobalDashboardFilterState)).subscribe(state => {
			if (state?.globalDashboard?.kpiSelected !== undefined) {
				this.headerCrumbs = [payGroup.customer.name, payGroup.legalEntity.data.country, payGroup.data.name];
				this.externalCrumbs = [
					{
						crumb: payGroup.customer.name,
						url: "/global-dashboard/paygroups",
						externalKey: "customerName"
					},
					{
						crumb: payGroup.data.name,
						flag: payGroup.legalEntity.data.country,
						externalKey: "payGroupId",
						externalId: `${payGroup.legalEntity.data.externalId} / ${payGroup.data.name} / ${payGroup.externalId}`
					}
				];
				this.kpiSelected = state.globalDashboard.kpiSelected;
			} else {
				this.router.navigate(["/global-dashboard"]);
			}
		});
	}

	resetVaribles(): void {
		this.milestoneGroups = [];
		this.payCycle = undefined;
		this.milestones = [];
	}

	navigateToLastRoute() {
		history.back();
	}

	handlePayCycleChanged(payCycle: PayCycle): void {
		this.isCalendarMilestonesLoading = true;
		if (payCycle) {
			this.payCycle = payCycle;
			this.milestoneGroupsService.onResetMilestonesStatus.emit(true);
			this.getFilteredTPPGroup(payCycle.id);
			if (this.isFromGlobalDashboard) {
				this.getCardDetails(this.setupGBFilters(this.payGroup!, payCycle), this.kpiSelected);
			}
		} else {
			this.payGroupHasNoPayCycles(true);
			this.isCalendarMilestonesLoading = false;
		}
	}

	getFilteredTPPGroup(payCycleId: string) {
		this.payCycleService
			.getOrganisedTPPGroupsForPaycycle(payCycleId)
			.pipe(
				takeUntil(this.destroy$),
				map(milestoneGroups => {
					this.milestoneGroups = milestoneGroups;
				})
			)
			.subscribe(milestoneGroups => {
				this.getMilestones(payCycleId);
			});
	}

	setSelectedMilestoneGroupName(milestoneGroup: MilestoneGroup) {
		if (milestoneGroup) this.expandedMilestoneGroupNameFromHistory = milestoneGroup.group;
	}

	payGroupHasNoPayCycles(hasNoPayCycles: boolean) {
		if (hasNoPayCycles) {
			this.payCycle = undefined;
			this.milestones = [];
			this.milestoneGroups = [];
			this.isCalendarMilestonesLoading = false;
		}
	}

	updatePayCycle(payCycle: PayCycle) {
		this.isCalendarMilestonesLoading = true;
		this.payCycle = payCycle;
		this.getFilteredTPPGroup(payCycle.id);
		this.milestoneGroupsService.onResetMilestonesStatus.emit(true);
	}

	setupGBFilters(payGroup: PayGroup, payCycle: PayCycle): DashboardFilters {
		let filters: FormGroup = this.formBuilder.group({
			customers: new FormControl([payGroup.customerId]),
			legalEntities: new FormControl([payGroup.legalEntityId]),
			paygroups: new FormControl([payGroup.id]),
			paycycles: new FormControl([payCycle.id]),
			groups: new FormControl([]),
			deadline: new FormControl(""),
			statuses: new FormControl([]),
			milestoneTypes: new FormControl([])
		}) as IDashboardFiltersFormGroup;

		return filters.getRawValue();
	}

	updateMilestones(milestones: Milestone[]) {
		this.milestones = milestones;
		this.calendarService.setMilestones(this.milestones);
	}

	getMilestones(payCycleId: string) {
		this.milestonesService
			.getMilestones(payCycleId)
			.pipe(take(1))
			.subscribe((milestones: Milestone[]) => {
				this.milestones = milestones;

				let milestoneGroupsNames: MilestoneGroupName[] = [];

				this.milestoneGroups.forEach(milestoneGroup => {
					milestoneGroupsNames.push(milestoneGroup.group);
				});

				//Filter out Groups that are not part of the TPP Service def
				this.milestones = this.milestones.filter(milestone => milestoneGroupsNames.includes(milestone.group));

				//Add milestone data to milestoneGroups object
				this.milestoneGroups.forEach(group => {
					let milestone = milestones.filter(m => m.group === group.group);

					if (milestone) {
						group.milestone = milestone;
						this.calendarService.setMilestones(this.milestones);
					}
				});

				this.isCalendarMilestonesLoading = false;
			});
	}

	private listenForResetDialogEvent() {
		// TODO: `event-details-container` can be responsible for handling this for a more linear flow.
		this.resetMilestones$ = this.milestoneGroupsService.onResetMilestones
			.pipe(
				takeUntil(this.destroy$),
				map(type => {
					this.showResetPayrollDialog(type);
				})
			)
			.subscribe();
	}

	private showResetPayrollDialog(milestoneType: string): void {
		const DIALOG_REF = this.dialog.open(ResetPayrollDialogComponent, {
			panelClass: "select-modalbox",
			width: "660px",
			height: "398px",
			data: {
				payCycle: this.payCycle,
				milestoneType: milestoneType
			}
		});

		DIALOG_REF.afterClosed().subscribe(milestonesWhereReset => {
			this.milestoneGroupsService.onResetMilestonesResult.emit(milestonesWhereReset);
			if (milestonesWhereReset) this.emitMilestoneResetEvent();
		});
	}

	private emitMilestoneResetEvent() {
		this.onCustomerStateChangeUpdatePayGroup();
		this.triggerResetPayouts$.next();
	}

	private cleanupSubscriptions() {
		if (this.resetMilestones$) {
			this.resetMilestones$.unsubscribe();
		}

		this.destroy$.next();
		this.destroy$.complete();
	}

	private onSelectedMilestoneGroupInRouterHistoryExpandGroup() {
		if (history.state && history.state.expandMilestoneGroupWithName)
			this.expandedMilestoneGroupNameFromHistory = history.state.expandMilestoneGroupWithName;
	}
}
