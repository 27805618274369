import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GeoFeature, GeoMap } from "@modules/client-landing/_models/client_landing-interface";
import { MenuService } from "@modules/config/_services/menu/menu.service";
import { IDashboardFiltersFormGroup } from "@modules/global-dashboard/models/global-dashboard-interface";
import { LegalEntityService } from "@shared/services/legal-entity/legal-entity.service";
import { TabStateService } from "@shared/services/tab-state/tab-state.service";
import { WorldMapService } from "@shared/services/world-map/world-map.service";
import { Subject, take, takeUntil } from "rxjs";

@Component({
	selector: "app-client-summary",
	templateUrl: "./client-summary.component.html",
	styleUrls: ["./client-summary.component.scss"]
})
export class ClientSummaryComponent implements OnInit, OnDestroy {
	filters!: FormGroup;
	destroy$: Subject<void> = new Subject();
	country!: GeoMap;

	constructor(
		public menuService: MenuService,
		private tabStateService: TabStateService,
		private legalEntityService: LegalEntityService,
		private formBuilder: FormBuilder,
		private worldMapService: WorldMapService
	) {}

	ngOnInit(): void {
		this.setupFiltersForm();
	}

	private setupFiltersForm(): void {
		this.filters = this.formBuilder.group({
			customers: new FormControl([]),
			legalEntities: new FormControl([]),
			paygroups: new FormControl([]),
			statuses: new FormControl([]),
			groups: new FormControl([]),
			deadline: new FormControl(""),
			milestoneTypes: new FormControl([])
		}) as IDashboardFiltersFormGroup;

		this.filters.patchValue(this.tabStateService.getGlobalDashboardStateSelect().globalDashboard.filters);

		if (this.filters.get("legalEntities")?.getRawValue().length > 0) {
			this.getLegalEntityFromId(this.filters.get("legalEntities")?.getRawValue()[0]);
		}
	}

	getLegalEntityFromId(id: string): void {
		this.legalEntityService
			.getLegalEntity(id)
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe({
				next: legal => {
					this.getCountryById(legal.data.country);
				},
				error: () => {
					console.log("oh no");
				}
			});
	}
	getCountryById(countryId: string): void {
		this.worldMapService
			.getGeoMap()
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe(geoMap => {
				geoMap.features = geoMap.features.filter((feature: GeoFeature) => feature.id === countryId);

				this.country = geoMap;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
