<!-- Items -->
<div
	class="parent-container"
	fxLayout="column"
	*ngFor="let transaction of payoutTransactions; let i = index; last as isLast"
>
	<div class="header">
		<div class="header-content" fxLayout="row">
			<div fxFlex="4" fxLayoutAlign="start center">
				<div
					*ngIf="
						canReprocessPayment &&
						(transaction.status.name.toString() === 'FAILED' ||
							transaction.status.name.toString() === 'ATTEMPTS_LIMIT_EXCEEDED' ||
							transaction.status.name.toString() === 'FAILED_TO_BOOK' ||
							transaction.status.name.toString() === 'ERROR')
					"
				>
					<mat-checkbox
						color="primary"
						class="checkbox"
						[checked]="isCheckBoxSelected && reprocessIds.includes(transaction.id)"
						(change)="setIds($event, payout.id, transaction.id)"
						[disabled]="!activeToggle"
					></mat-checkbox>
				</div>
			</div>

			<div class="employee-id table-row-item" fxFlex="15" fxLayoutAlign="start center">
				{{ employeeExternalId }}
			</div>
			<div fxFlex="10" fxLayoutAlign="start center" class="name table-row-item">
				{{ employeefirstName }}
			</div>

			<div fxFlex="12.8" fxLayoutAlign="start center" class="name table-row-item">
				{{ employeelastName }}
			</div>

			<div id="paymentMethod" fxFlex="19.2" fxLayoutAlign="start center">
				<div fxLayout="row">
					<div *ngIf="transaction.accountType === 'BANK'" class="badge-item badge-bank">
						<app-icon
							class="bank-icon"
							[name]="'bank-figma'"
							[size]="'20'"
							[color]="'var(--no-items-poker-face-color)'"
							[lineWidth]="'2'"
						></app-icon>
					</div>

					<div *ngIf="transaction.accountType === 'CARD'" class="badge-item badge-card">
						<app-icon
							class="bank-card-icon"
							[name]="'card'"
							[size]="'20'"
							[color]="'var(--no-items-poker-face-color)'"
							[lineWidth]="'2'"
						></app-icon>
					</div>
					<span class="bank-account">
						{{ payoutBankNames[i] }}
						<br />

						<span
							[ngClass]="{
								'bank-account-types': transaction.type !== 'NETS',
								'bank-account-types-net': transaction.type === 'NETS'
							}"
							>{{ transaction.type === "NETS" ? "Net payment" : transaction.type }}</span
						>
					</span>
				</div>
			</div>

			<div class="table-status" *ngIf="!isCancelledRun" fxFlex="13.2" fxLayoutAlign="start center">
				<span
					[ngClass]="{
						'table-status status-green':
							(transaction.status.name | toString) === 'SUCCESS' ||
							(transaction.status.name | toString) === 'PROCESSED',
						'table-status status-red':
							(transaction.status.name | toString) === 'FAILED' ||
							(transaction.status.name | toString) === 'ATTEMPTS_LIMIT_EXCEEDED' ||
							(transaction.status.name | toString) === 'FAILED_TO_BOOK' ||
							(transaction.status.name | toString) === 'ERROR',
						'table-status status-pending':
							(transaction.status.name | toString) === 'PENDING' ||
							(transaction.status.name | toString) === 'PROCESSING',
						'table-status status-booked': (transaction.status.name | toString) === 'BOOKED',
						'table-status status-cancelled': (transaction.status.name | toString) === 'CANCELLED',
						'table-status status-created': (transaction.status.name | toString) === 'CREATED'
					}"
					>{{ transaction.status.name | transactionStatuses }}</span
				>
			</div>

			<span class="table-status" *ngIf="isCancelledRun" fxFlex="13.2" fxLayoutAlign="start center">
				<span class="status-cancelled">CANCELLED</span>
			</span>

			<div class="table-total-net" fxFlex="22.85" fxLayoutAlign="start center">
				<span class="total-net-text">
					{{ transaction.amount.value | number : "1.2-2" }}{{ transaction.amount.currency | currencySymbol }}
				</span>
			</div>

			<mat-form-field class="view-details" fxFlex="2" fxLayoutAlign="start center">
				<app-icon class="more-dots" [name]="'more'" #trigger [lineWidth]="'1'"></app-icon>
				<mat-select class="options-dropdown" trigger="trigger" #alarmSelect>
					<mat-option
						*ngIf="canViewTransactionHistory"
						(click)="openHistoryDialog(transaction.payoutId, transaction.payMethodId)"
					>
						History log
					</mat-option>
					<mat-option (click)="goToEmployeeHistory()"> Employee History </mat-option>
				</mat-select>
			</mat-form-field>

			<!--<div
				class="view-details"
				fxLayoutAlign="end"
				fxFlex="2"
				(click)="showHistory(payout, transaction.accountId)"
			>
				
			</div>-->
		</div>
	</div>
	<mat-divider *ngIf="!isLast"></mat-divider>
</div>
