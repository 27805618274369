<app-breadcrumbs [addOverviewTitle]="true" [showSideNav]="false"></app-breadcrumbs>
<div class="client-summary-header">
	<div class="btn-container">
		<button class="btn-client-summary action-buttons mat-button mat-flat-button" id="btnCriticalAlerts">
			CRITICAL ALERTS
		</button>
		<button class="btn-client-summary action-buttons mat-button mat-flat-button mat-dark" id="btnGlobalDashboard">
			GLOBAL DASHBOARD
		</button>
	</div>
</div>
<app-country *ngIf="country" [country]="country"></app-country>
