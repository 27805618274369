<!-- If there are items -->
<ng-container *ngIf="payGroups && payGroups.length > 0; else noItems">
	<!-- List of paygroups -->
	<div class="list-container">
		<div class="paygroup-items-container">
			<!-- Header -->
			<div class="paygroup-items-header" fxLayout="row" fxLayoutAlign="start center">
				<h3>PAYGROUPS LIST</h3>
			</div>

			<!-- Content -->
			<ng-container *ngFor="let payGroup of payGroups; last as last">
				<div
					class="information-container"
					[matTooltip]="
						payGroup.legalEntity.data.externalId + '/' + payGroup.data.name + '/' + payGroup.externalId
					"
					[matTooltipPosition]="'above'"
					[matTooltipClass]="'generic-paygroup-list-item'"
					(click)="paygroupSelected(payGroup)"
				>
					<!-- Left side -->

					<span class="information-title legal-entity-flag">
						<img
							src="assets/svg-country-flags/svg/{{ payGroup.legalEntity.data.country.toLowerCase() }}.svg"
						/>
					</span>

					<span class="information-title legal-entity-id">
						{{ payGroup.legalEntity.data.externalId }}
					</span>
					<span class="information-title">/</span>
					<span class="information-title paygroup-name">{{ payGroup.data.name }}</span>
					<span class="information-title">/</span>
					<span class="information-title paygroup-id"> {{ payGroup.externalId }}</span>

					<!-- Right side -->
					<div class="detail-container">
						<!-- Dot -->
						<div
							class="dot"
							[ngClass]="{
								warning: payGroup.status === 'IMPLEMENTATION',
								success: payGroup.status === 'LIVE',
								danger: payGroup.status === 'INACTIVE'
							}"
						></div>
						<div class="text-status">{{ payGroup.status }}</div>
					</div>
				</div>
				<mat-divider class="divider" *ngIf="!last"></mat-divider>
			</ng-container>
		</div>
	</div>
</ng-container>

<!-- If there are NOT items -->
<ng-template #noItems>
	<no-items-layout *ngIf="!loading" message="No items created yet"></no-items-layout>

	<div class="pulsate" *ngIf="loading">Fetching paygroups...</div>
</ng-template>
