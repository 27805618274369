import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CdkMenuModule } from "@angular/cdk/menu";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";

import { IconModule } from "@shared/components/icon/icon.module";
import { NetPaymentOrdersComponent } from "./net-payment-orders.component";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { NetPaymentHeaderComponent } from "./_components/net-payment-header/net-payment-header.component";
import { NetPaymentActionsComponent } from "./_components/net-payment-actions/net-payment-actions.component";
import { NetPaymentDataComponent } from "./_components/net-payment-data/net-payment-data.component";

@NgModule({
	declarations: [
		NetPaymentOrdersComponent,
		NetPaymentHeaderComponent,
		NetPaymentActionsComponent,
		NetPaymentDataComponent
	],
	imports: [
		CommonModule,
		CdkMenuModule,
		IconModule,
		InputWrapperModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule
	],
	exports: [NetPaymentOrdersComponent]
})
export class NetPaymentOrdersModule {}
