<div class="calendar-paygroup-list-container">
	<app-breadcrumbs [headerTitle]="'CALENDAR'" />
	<div class="main-container">
		<div fxLayout="column">
			<div class="reference-search" [formGroup]="entitySelectForm">
				<input-wrapper class="select" label="LEGAL ENTITY">
					<input-autocomplete
						formControlName="legal"
						[options]="legalEntitiesSelectOptions"
						[label]="'All Legal Entities'"
					>
					</input-autocomplete>
				</input-wrapper>

				<input-wrapper class="select" label="SEARCH BY">
					<mat-form-field id="payGroupSearch" appearance="outline">
						<app-icon [name]="'search-normal-1'" matPrefix style="padding-right: 10px"></app-icon>
						<input formControlName="paygroup" matInput placeholder="Search by pay group name/ID" />
					</mat-form-field>
				</input-wrapper>
			</div>

			<app-generic-pay-group-list-container
				[paygroupList]="payGroupsList"
				[isLoading]="busy"
				(payGroupSelected)="paygroupSelected($event)"
			></app-generic-pay-group-list-container>

			<app-pagination
				*ngIf="totalPageCount"
				[length]="totalPageCount"
				[pageSizeOptions]="[5, 10, 25, 100]"
				[pageSize]="pageSize"
				[pageIndex]="pageIndex"
				[paginationType]="paginationType"
				aria-label="Select page"
			>
			</app-pagination>
		</div>
	</div>
</div>
