//External
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { ModalWrapperModule } from "@shared/components/modal-wrapper/modal-wrapper.module";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { GlobalDashboardComponent } from "./_containers/global-dashboard.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { KpiCardsModule } from "@shared/components/wpay-ui/kpi-cards/kpi-cards.module";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { InputWrapperModule } from "@shared/components/input-wrapper/input-wrapper.module";
import { InputSelectModule } from "@shared/components/input-select/input-select.module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { FormsSharedModule } from "@shared/modules/forms-shared.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { ToggleButtonModule } from "@shared/components/wpay-ui/toggle-button/toggle-button.module";
import { GlobalDashboardRoutingModule } from "./global-dashboard-routing.module";
import { WpayMultiSelectAutoCompleteModule } from "@shared/components/wpay-ui/base-components/wpay-multi-select-auto-complete/wpay-multi-select-auto-complete.module";

//Internal
import { FiltersContainerComponent } from "./_components/filters-container/filters-container.component";
import { LandingPageEventsTableComponent } from "./_components/tables/landing-page-events-table/landing-page-table.component";
import { MissingCardsTableComponent } from "./_components/tables/missing-cards-table/missing-cards-table.component";
import { FailedBeneficiariesTableComponent } from "./_components/tables/failed-beneficiaries-table/failed-beneficiaries-table.component";
import { MissingBankAccountsTableComponent } from "./_components/tables/missing-bank-accounts-table/missing-bank-accounts-table.component";
import { FailedTransactionsTableComponent } from "./_components/tables/failed-transactions-table/failed-transactions-table.component";
import { FailedPaymentOrdersTableComponent } from "./_components/tables/failed-payment-orders-table/failed-payment-orders-table.component";
import { LandingPageCustomerTableComponent } from "./_components/tables/landing-page-customer-table/landing-page-customer-table.component";
import { TableTopSectionComponent } from "./_components/table-top-section/table-top-section.component";
import { BreadcrumbsModule } from "@shared/components/breadcrumbs/breadcrumbs.module";
import { NoItemsLayoutModule } from "@shared/components/no-items-layout/no-items-layout.module";
import { WpayTextTruncateModule } from "@shared/components/wpay-ui/wpay-text-truncate/wpay-text-truncate.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TooltipModule } from "@shared/components/tooltip/tooltip.module";
import { MilestoneHistoryLogComponent } from "./_components/side-panels/milestone-events-history-log/milestone-history-log/milestone-history-log.component";
import { SlidingPanelModule } from "@shared/components/sliding-panel/sliding-panel.module";
import { MatDividerModule } from "@angular/material/divider";
import { LandingPagePaygroupTableComponent } from "./_components/tables/landing-page-paygroup-table/landing-page-paygroup-table.component";
import { HistoryLogSidePanelModule } from "@shared/components/history-log-side-panel/history-log-side-panel.module";
import { MilestoneStatusDropdownComponent } from "src/app/standalone/milestone-status-dropdown/milestone-status-dropdown.component";

@NgModule({
	declarations: [
		GlobalDashboardComponent,
		FiltersContainerComponent,
		LandingPageEventsTableComponent,
		MissingCardsTableComponent,
		FailedBeneficiariesTableComponent,
		MissingBankAccountsTableComponent,
		FailedTransactionsTableComponent,
		FailedPaymentOrdersTableComponent,
		LandingPageCustomerTableComponent,
		TableTopSectionComponent,
		MilestoneHistoryLogComponent,
		LandingPagePaygroupTableComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		ModalWrapperModule,
		PipesModule,
		KpiCardsModule,
		MatButtonModule,
		MatInputModule,
		InputWrapperModule,
		InputSelectModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatFormFieldModule,
		FormsSharedModule,
		MatPaginatorModule,
		MatTableModule,
		ToggleButtonModule,
		GlobalDashboardRoutingModule,
		WpayMultiSelectAutoCompleteModule,
		BreadcrumbsModule,
		NoItemsLayoutModule,
		WpayTextTruncateModule,
		MatTooltipModule,
		TooltipModule,
		BreadcrumbsModule,
		SlidingPanelModule,
		MatDividerModule,
		HistoryLogSidePanelModule,
		MilestoneStatusDropdownComponent
	]
})
export class GlobalDashboardModule {}
