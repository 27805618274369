<div class="header">
	<h3 class="dialog-text">STATUS HISTORY LOG</h3>
	<app-icon
		[name]="'close'"
		[size]="'55px'"
		[color]="'white'"
		fxFlex="5"
		class="close"
		(click)="closeDialog()"
	></app-icon>
</div>

<div class="scroll-container">
	<div class="search-section" fxLayoutAlign="space-between center">
		<div [formGroup]="searchForm" class="searchbar-wrapper">
			<mat-form-field appearance="outline" fxLayoutAlign="start center" class="search-bar">
				<app-icon
					matPrefix
					[name]="'search-normal-1'"
					[color]="'#231F20'"
					class="search-icon-history"
				></app-icon>
				<input matInput placeholder="Search by..." formControlName="search" />
			</mat-form-field>
		</div>
	</div>

	<table mat-table [dataSource]="dataSource" class="payout-history-table" *ngIf="dataSource.length > 0; else noItems">
		<!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

		<!-- email Column -->
		<ng-container matColumnDef="EMPLOYEE ID">
			<th mat-header-cell *matHeaderCellDef class="first-collumn header">
				<span class="text">EMPLOYEE ID</span>
			</th>
			<td mat-cell *matCellDef="let element" class="first-collumn">
				<span class="text data">{{ element.employeeExternalId }}</span>
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="FULL NAME">
			<th mat-header-cell *matHeaderCellDef class="second-collumn"><span class="text">FULL NAME</span></th>
			<td mat-cell *matCellDef="let element" class="second-collumn">
				<span class="text data" *ngIf="element.employeeExternalId">{{
					element.employeeFirstName + " " + element.employeeLastName
				}}</span>
			</td>
		</ng-container>

		<!-- Weight Column -->
		<ng-container matColumnDef="TRANSACTION ID">
			<th mat-header-cell *matHeaderCellDef class="third-collumn"><span class="text">TRANSACTION ID</span></th>
			<td mat-cell *matCellDef="let element" class="third-collumn">
				<span class="text data">{{ element.transactionId }}</span>
			</td>
		</ng-container>

		<!-- Role Column -->
		<ng-container matColumnDef="STATUS">
			<th mat-header-cell *matHeaderCellDef class="fourth-collumn"><span class="text">STATUS</span></th>
			<td mat-cell *matCellDef="let element" class="fourth-collumn status-collumn">
				<span
					class="text status-container"
					[matTooltip]="element.status.name.toString() | transactionStatuses"
					[matTooltipPosition]="'after'"
					[matTooltipClass]="'paygroup-status-tooltip'"
				>
					<span
						class="dot"
						[ngClass]="{
							'text-success':
								element.status.name === 'ACTIVE' ||
								element.status.name === 'SUCCESS' ||
								element.status.name === 'PROCESSED',
							'text-proccessing':
								element.status.name === 'PENDING' || element.status.name === 'PROCESSING',
							'text-booked':
								element.status.name === 'BOOKED' ||
								element.status.name === 'REPROCESSED' ||
								element.status.name === 'PAID_TO_FALLBACK',
							'text-rejected':
								element.status.name === 'INACTIVE' ||
								element.status.name === 'FAILED' ||
								element.status.name === 'FAILED_TO_BOOK' ||
								element.status.name === 'ATTEMPTS_LIMIT_EXCEEDED' ||
								element.status.name === 'ERROR',

							'text-created': element.status.name === 'CREATED',
							'text-cancelled': element.status.name === 'CANCELLED'
						}"
					>
					</span>

					<span class="status-name data text"
						>{{ element.status.name.toString() | transactionStatuses }}
					</span>
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="REASON">
			<th mat-header-cell *matHeaderCellDef class="fifth-collumn"><span class="text">REASON</span></th>
			<td mat-cell *matCellDef="let element" class="fifth-collumn reason-cell">
				<p
					class="reason data"
					*ngIf="
						!element.status.details ||
						(element.status.details && isEmptyObject(element.status.details.attributes))
					"
				>
					{{ element.status.message }}
				</p>

				<p
					class="reason data details"
					*ngIf="
						element.status.details &&
						element.status.details?.attributes &&
						!isEmptyObject(element.status.details.attributes)
					"
				>
					<span *ngFor="let attribute of element.status.details?.attributes | keyvalue">
						<span class="details-items" *ngIf="!isArray(attribute.value!.toString())">
							<strong>{{ attribute.key!.toString() | formatString }}</strong>
							: {{ attribute.value }}
						</span>

						<span *ngIf="isArray(attribute.value!.toString())">
							<span *ngFor="let tag of getArrayValue(attribute.value!.toString())" class="details-items">
								<strong> {{ attribute.key!.toString() | formatString }}: </strong>
								{{ tag | formatString }}
							</span>
						</span>
					</span>
				</p>
			</td>
		</ng-container>

		<ng-container matColumnDef="CREATED DATE">
			<th mat-header-cell *matHeaderCellDef class="sixth-collumn header"><span class="text">DATE</span></th>
			<td mat-cell *matCellDef="let element" class="sixth-collumn">
				<span class="text data">{{ element.createdAt | date : "MMM d, y" | uppercase }}</span>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
	</table>

	<ng-template #noItems>
		<no-items-layout message="No items found"></no-items-layout>
	</ng-template>
</div>
